@import 'styles/variables';

.container {
  background-color: $color-white;
  border-radius: $border-radius;
  box-shadow: 0 2px 4px 0 rgba($color-black, 0.1);
  display: grid;
  grid-template-columns: 145px 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  min-height: 0;
  position: relative;

  &.sidePanelHidden {
    grid-template-columns: 1fr;
  }

  .joinButton {
    width: 99px;
    margin: 12px auto;
  }
}

.chatMessagesContainer {
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}

.spinner {
  display: grid;
  grid-template-rows: 1fr auto;
  padding-top: 10px;
}
