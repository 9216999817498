@import 'styles/variables';

$full-dialog-header-height: $search-bar-height + 1;
$nav-header-combined-height: calc(#{$full-dialog-header-height} + var(--system-navigation-height-full));

.container {
  position: fixed;
  top: var(--system-navigation-height-full);
  right: 0;
  z-index: $zIndex2;

  .content {
    display: flex;
    justify-items: flex-start;
    align-content: flex-start;
    flex-flow: column;
    position: fixed;
    right: -$slideOut-width; // TODO: Take dynamic width into consideration
    width: $slideOut-width;
    box-shadow: 1px 0 5px 0 rgba($color-black, 0.15);
    background: $color-white;
    transition: right 300ms cubic-bezier(0.4, 0, 0.2, 1); // Chrome's 'Fast out, slow in' bezier
    height: 100%;
  }

  .contentInner {
    height: calc(100vh - #{$nav-header-combined-height});
    overflow-y: auto;
  }

  .spinner {
    margin: 30px auto 0;
  }

  &.isVisible {
    pointer-events: all;

    .content {
      right: 0;
    }
  }

  &.isDialogMode {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;

    .content {
      position: absolute;
      top: 0;
      height: 100%;
      box-shadow: none;
    }
  }

  .noResultsList h2 {
    max-width: 75%;
    text-align: center;
  }
}

.header {
  align-items: center;
  background-color: $color-surface-lightest;
  border-bottom: 1px solid $color-gray-lighter;
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  padding: 0 $spacing-2x;
  width: 100%;

  h3 {
    font-size: $font-size-15;
  }
}

.titleContainer {
  align-items: center;
  display: flex;
}

.badge {
  align-items: center;
  display: flex;
  color: $color-blue;
  margin-left: 10px;
  padding-bottom: 2px;

  svg {
    height: 12px;
    padding: 0;
    width: 12px;
  }
}

.headerActions {
  margin-left: 10px;
}

.closeButton {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: flex-end;
  width: 24px;

  .sprite {
    height: 15px;
    transform: rotate(45deg);
    width: 15px;
  }
}
