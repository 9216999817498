@import 'styles/variables';

$base-padding: 12px;

.textArea {
  margin-top: $base-padding;
  border: 1px solid $color-gray-lighter;
  border-radius: $border-radius;
  font-size: $font-size-13;
  background-color: $color-white;
  padding: $input-padding;
  resize: none;
  box-sizing: border-box;
  line-height: 12px;
  color: $color-black;
  width: 100%;
  height: 100px;

  &.theme-error {
    color: $color-red;
    border-color: $color-red;
    background-color: $color-red-lighter;

    &::placeholder {
      color: $color-red;
    }
  }

  &.theme-small {
    font-size: $font-size-11;

    &::placeholder {
      font-size: $font-size-11;
      color: $color-black-50-percent;
    }
  }
}
