@import 'styles/variables';

.offerExpiryTimer {
  position: relative;
}

.timerBarContainer {
  background-color: $color-red-dark;
  height: 18px;
  overflow: hidden;
  width: 100%;
}

.timerBar {
  background-color: $color-red;
}

.timerText {
  align-items: center;
  color: $color-white;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  height: 100%;
  left: 0;
  line-height: 18px;
  padding: 0 15px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 100%;

  > div {
    display: flex;
  }
}

.clockGlyph {
  fill: $color-white;
  height: 10px;
  margin-right: $spacing;
  min-width: 10px;
  width: 10px;
}

.label {
  padding-right: 5px;
}
