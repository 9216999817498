@import 'styles/variables';

.container {
  align-items: center;
  color: $color-text;
  display: flex;
  font-size: $font-size-12;
  margin-bottom: 2px;
}

.tooltip {
  max-width: 400px;
}

.tooltipGlyph {
  fill: $color-gray-darkest;
  height: 14px;
  width: 14px;
}

.notificationTextGlyph {
  display: none;
}
