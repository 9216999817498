@import 'styles/variables';

.buttonLabel {
  padding-top: 2px;
  display: block;
}

.confirmTitleClassName[data-dialog-title] {
  color: $color-red;
}
