@import 'styles/variables';

.blackBookCopyright {
  align-items: start;
  display: grid;
  font-size: $font-size-8;
  grid-gap: 10px;
  grid-template-columns: 32px 1fr;
}

.blackBookCopyrightLogo {
  margin: 0;
  width: 100%;
}
