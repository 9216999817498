@import 'styles/variables';

.container {
  --rt-opacity: 1;
  align-items: center;
  background: $color-white;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  color: $color-gray-dark;
  display: flex;
  flex-flow: column;
  font-size: $font-size-13;
  font-weight: $font-weight-regular;
  line-height: 1.4;
  max-width: 300px;
  padding: 15px 20px;
  text-align: center;
  z-index: $zIndex5;
}

.title {
  font-weight: $font-weight-semibold;
}

.description {
  font-weight: $font-weight-regular;
  margin-top: $spacing-half;
  white-space: pre-wrap;
  text-align: left;
}
