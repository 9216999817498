@import 'styles/variables';
@import 'styles/typography';

$base-padding: 1.1rem;
$card-border-radius: 4px;

.consigner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  height: 25px;
  padding: 0 $base-padding;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  background-color: rgba($color-black, 0.6);
  color: rgba($color-white, 0.7);
  letter-spacing: $letter-spacing-small;
  line-height: $line-height-norm;
  overflow: hidden;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;

  svg {
    fill: $color-white;
    width: 8px;
  }

  .name {
    flex: 1;
    padding-right: 10px;

    @extend %ellipsis;
  }

  .location {
    display: inline-flex;
    align-items: center;
  }

  .sprite {
    display: flex;
    align-items: center;
    margin-right: $spacing;
  }
}

.status {
  position: relative;
  width: 100%;
  height: 25px;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  color: $color-white;
  display: flex;
  align-items: center;
  padding: 0 $base-padding;
}

.timerBarContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $color-green-dark;
  overflow: hidden;

  &.isLosing,
  &.awaitingMyResponse {
    background-color: $color-red-dark;
  }

  &.awaitingCheckout {
    background-color: $color-blue;
  }

  .timerBar {
    background-color: rgba($color-green, 0.8);

    &.isLosing,
    &.awaitingMyResponse {
      background-color: $color-red;
    }

    &.awaitingCheckout {
      background-color: $color-blue;
    }
  }
}

.green {
  background-color: $color-green-dark;
}

.red {
  background-color: $color-red-dark;
}

.yellow {
  background-color: $color-yellow-darkest;
}

.timerText {
  padding-top: 1px;

  @extend %ellipsis;
}

.bid {
  z-index: $zIndex1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.liveItemINIT {
  .timerBarContainer {
    background-color: $color-gray-dark;
  }

  .timerBar {
    background-color: $color-gray-light;
  }
}

.liveItemEND {
  .timerBar,
  .countdown {
    display: none;
  }
}

.preDot {
  @extend %ellipsis;

  &::before {
    content: '•';
    padding: 0 5px;
  }
}

.clockGlyph {
  fill: $color-white;
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-right: $spacing;
}
