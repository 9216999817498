@import 'styles/variables';

.circle {
  background: $color-surface-lighter;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  flex: none;
}

.circleOn {
  background: $color-green;
}

.serverButton {
  align-items: center;
  border-radius: 4px;
  border: 1px solid $color-gray-dark;
  display: flex;
  font-size: $font-size-13;
  justify-content: space-between;
  padding: $spacing 12px;
  min-height: 30px;
  width: 100%;

  &:hover {
    background-color: $color-white;
    border-color: $color-gray-dark;
  }

  > span {
    color: $color-gray-dark;
    flex: auto;
    font-weight: $font-weight-bold;
    text-align: left;
    text-transform: none;
  }
}

.buttons {
  margin-top: 20px;

  button:nth-of-type(2),
  button:nth-of-type(3),
  button:nth-of-type(4) {
    margin: $spacing 0;
  }
}

.unselectedButton {
  opacity: 0.5;

  &:hover {
    opacity: 0.9;
  }
}

.privateButton {
  border: none;
}

.hostContainer {
  border: 1px solid $color-gray-dark;
  border-radius: 4px;
  padding-bottom: 10px;
}

.hostInputContainer {
  display: flex;
  align-items: center;
  padding: 0 12px;

  > span {
    flex: none;
  }
}

.hostInput {
  border-bottom: 1px solid $color-gray-dark;
  border-radius: 0;
  flex: auto;
  margin-left: 20px;
  padding: 0;

  input {
    text-align: right;
    font-size: $font-size-13;
  }
}
