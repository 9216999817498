@import 'styles/variables';

:global {
  #modal-root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:empty {
      display: none;
    }
  }

  .global-error + #modal-root {
    display: none;
  }
}

.dialog {
  position: relative;
  min-height: 100%;
  opacity: 0;
  transition: opacity 150ms ease-in-out;

  &.isOpen {
    opacity: 1;
  }
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba($color-white, 0.9);
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentInner {
  width: 300px;
  background-color: $color-white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  position: relative;
}

.dialogContent {
  justify-content: flex-start;
  padding: 100px 0 20px;
}

.isFixed {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $zIndex4;

  .overlay {
    top: 0;
    left: 0;
  }

  //.contentInner {
  //  box-shadow: 0 0 30px 0 $color-surface-lightest;
  //}
}
