@import 'styles/variables';

.flex {
  display: flex;
  width: 100%;
}

.flexRow {
  flex-flow: row nowrap;
}

.flexRowSpaceBetween {
  align-items: center;
  justify-content: space-between;

  :nth-child(2) {
    text-align: right;
  }
}

.flexColumn {
  flex-flow: column nowrap;
}
