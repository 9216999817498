@import 'styles/variables';

.workflowProcess {
  &.black {
    color: $color-gray-darkest;
  }

  &.green {
    color: $color-green;
  }

  &.red {
    color: $color-red;
  }

  &.yellow {
    color: $color-yellow;
  }
}
