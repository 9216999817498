@import 'styles/variables';

.coreLayout {
  position: relative;
  min-width: 1200px;
  height: 100vh;
  display: flex;
  flex-flow: column;

  &.isMobileEnabledPath {
    min-width: 0;

    form {
      /* Only used for mobile enabled paths (refer to Routes) */
      @media screen and (max-width: $tablet) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

.main {
  flex: 1;
  overflow: auto;
}
