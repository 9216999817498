@import 'styles/variables';
@import './submitForm';

.radioButtons {
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-3x;
}

.infoContainer {
  display: grid;
  gap: $spacing;
  grid-template-columns: 14px 1fr;
  margin-top: $spacing-2x;
}

.infoIcon {
  margin-top: 3px;
  color: $color-gray-light;
  height: 14px;
  width: 14px;
}

.infoContent {
  margin: 0;
  color: $color-gray-dark;

  > * {
    display: block;
  }
}
