@import 'styles/variables';

.selectLocation {
  margin-bottom: 35px;
}

.headerText {
  color: $color-gray-darker;
  margin-bottom: 25px;
  text-align: center;
}

.reserveNotification {
  margin-top: 15px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  height: 46px;
  border: 1px solid $color-gray-lighter;
  border-radius: $block-border-radius;
}

.dollarSign {
  padding-left: 20px;
}

.startingPriceContainer {
  display: flex;

  p {
    margin: 0;
  }
}

.dropdown {
  text-align: left;
  margin-left: 10px;
  width: calc(50% - 5px); // subtract left margin/2
}

.placeholderDropdown {
  color: $color-gray-lighter;
}

.subTitle {
  margin-top: 30px;
}

.radioButtonsContainer {
  width: 100%;
}

.radioButtons {
  width: 100%;

  label {
    min-height: 48px;
  }
}

.radioButtonsAuction {
  margin-bottom: 30px;
}

.fetchingAuctionTimeSlotSpinner {
  margin-top: 30px;
}

.footer {
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowSprite {
  width: 12px;
  height: 12px;
  margin-right: 15px;
}

.spinnerStyle {
  &::before {
    background: white;
  }
}

.asIsContainer {
  background: $color-red;
  border-radius: $block-border-radius;
  margin: 0 0 25px;
  width: 100%;

  form {
    margin-top: 0;
  }
}

.asIsCause {
  // due to the specificity in modalContainer.scss, the important is required
  margin: 0 !important; // stylelint-disable-line
  padding: $spacing-3x;
  color: $color-white;
  line-height: $line-height-large;
}

.asIsRadioLabel {
  color: $color-red;
  background: $color-white;
  border-color: $color-red;
}
