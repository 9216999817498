@import 'styles/variables';

/* Match specificity of auth.scss */
.authLink {
  .partnerLoginButtonLink {
    background: transparent;
    color: $color-white;
    font-family: 'Proxima Nova', sans-serif;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    height: 16px;
    margin: 0;
    padding: 0;

    &:hover,
    &:active {
      background: transparent;
      text-decoration: underline;
    }
  }
}
