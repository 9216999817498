@import 'styles/variables';

.slideOut {
  z-index: $zIndex2;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.infoMessage {
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: $font-size-14;
}

.saveButton {
  margin: $spacing-2x;
}
