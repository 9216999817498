@import 'styles/variables';
@import 'styles/typography';

.channelButton {
  display: flex;
  margin: $spacing-half;
  padding: 2px $spacing-half;
  text-align: left;
  width: 90%;

  .channelName {
    color: $color-white;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: $line-height-atom;
    padding-left: $spacing-half;
    @extend %ellipsis;

    &.hasUnreadMessage {
      color: $color-white;
    }

    &.isSelected {
      color: $color-white;
      font-weight: $font-weight-bold;

      &.isBroadcast {
        color: $color-gray-darker;
      }
    }
  }

  &.isSelected {
    background-color: $color-green;
    border-radius: $border-radius;
    height: 18px;

    &.isBroadcast {
      background-color: $color-blue-light;
    }
  }
}

.unreadMessageCount {
  background: $color-blue;
  border-radius: $block-border-radius;
  color: $color-white;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  margin-left: $spacing;
  min-width: 15px;
  padding: 1px;
  text-align: center;
}
