@import 'styles/variables';

.button {
  align-items: center;
  background-color: $color-gray-lightest;
  border-radius: 50%;
  border: none;
  color: $color-gray-darker;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 15px;
  outline: none;
  position: relative;
  width: 24px;
}

.buttonBadge {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 9px;
  height: 9px;
  border: 2px solid $color-top-nav;
  background-color: $color-red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sprite {
  width: 12px;
  height: 10px;
}
