@import 'styles/variables';

.imgContainer {
  position: relative;
  justify-content: center;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  background-color: $color-gray-lightest;

  > svg {
    fill: $color-gray-light;
    width: 50%;
  }
}

.img {
  position: relative;
  display: block;
  height: 100%;
}
