@import 'styles/variables';

.miniButton {
  align-items: center;
  border-radius: $border-radius-small;
  color: $color-white;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  height: 16px;
  justify-content: center;
  letter-spacing: $letter-spacing-small;
  padding: $spacing-half $spacing;
  white-space: nowrap;

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    color: $color-gray-dark;
    background: $color-gray-lighter;
  }
}

.theme-black {
  background: $color-gray-darkest;

  &:hover {
    background: $color-gray-darker;
  }

  &:active {
    background: $color-black;
  }
}

.theme-blue {
  background: $color-blue;

  &:hover {
    background: $color-blue-dark;
  }

  &:active {
    background: $color-blue-darker;
  }
}

.theme-red {
  background: $color-red;

  &:hover {
    background: $color-red-dark;
  }

  &:active {
    background: $color-red-darker;
  }
}
