@import 'styles/variables';

.edit {
  display: flex;
  align-items: center;
  align-self: center;
  justify-self: center;
  border: none;

  .editWithText {
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.text {
  color: $color-blue;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  margin-left: 5px;
}

.theme-default {
  height: $edit-btn-size;
  width: $edit-btn-size;
  color: $color-blue;
  border-radius: $edit-btn-size / 2;

  svg {
    padding: $spacing-half;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: $color-blue-dark;
    fill: $color-white;
  }
}

.theme-blue-pencil {
  background: none;

  svg {
    padding: 0;
    width: 12px;
    height: 12px;
    fill: $color-blue;
  }
}
