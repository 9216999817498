@import 'styles/variables';

.inputContainer {
  position: relative;
}

.input {
  width: auto;
}

.units {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
}

.unit {
  border-radius: 3px;
  min-width: 28px;
  min-height: 24px;
  padding: 0;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  text-transform: lowercase;

  &:not(:last-child) {
    margin-right: $spacing-half;
  }

  &.hasLabel {
    top: 29px;
  }
}
