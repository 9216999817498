@import 'styles/variables';

.container {
  display: flex;
  position: relative;
  background: transparent;

  .input {
    > input {
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
      text-align: right;
    }
  }
}

.buttons {
  margin-left: 5px;
  transform: translateY(2px); // align vertically with input
  width: auto;

  svg {
    height: 18px;
    width: 18px;
  }
}

.cancel {
  color: $color-gray-darker;
  margin-left: 3px;

  &:hover {
    color: $color-gray-dark;
  }
}

.submit {
  color: $color-green;

  &:hover {
    color: $color-green-dark;
  }
}

.submittingOverlay {
  background: rgba($color-surface-lighter, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: $spacing-half;
}
