@import 'styles/variables';
@import 'styles/typography';

$card-border-radius: 4px;

.headerContainer {
  display: flex;
  height: 100%;
  max-height: 440px;
  max-width: 798px;
  min-width: 740px;
}

.headerBar {
  align-items: center;
  background-color: $color-surface-lightest;
  border-bottom: 1px solid $color-gray-lighter;
  display: flex;
  height: $header-live-lanes-height;
  padding: $spacing $spacing-2x;
  position: sticky;
  top: 0;
  z-index: $zIndex2;
}

.liveLanesLogo {
  color: $color-gray-darkest;
  height: 40px;
  width: 82px;
}

.vehicleDetails {
  min-width: 50%;
}

.details {
  display: flex;
  flex-direction: column;
  padding: $spacing;
  width: 100%;

  .info {
    width: 100%;

    h2 {
      color: $color-gray-darkest;
      font-weight: $font-weight-semibold;
      font-size: $font-size-12;
      line-height: 20px;
      margin: 0;
      @extend %ellipsis;
    }

    .title {
      line-height: 20px;
    }

    .badgesContainer {
      align-items: center;
      display: flex;
      margin: $spacing-half 0;
      white-space: wrap;
    }

    .mileage {
      color: $color-gray-dark;
      font-weight: $font-weight-semibold;
      font-size: $font-size-14;
      padding-right: 5px;
      white-space: nowrap;
    }
  }

  .actions {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: space-between;
    margin-bottom: $spacing;
    padding: $spacing 0;

    .runNumberBadge {
      --badge-size: 24px;
      height: 32px;
      margin-right: $spacing;
    }

    .saleLights {
      padding: $spacing $spacing $spacing 0;
    }
  }
}

.photoContainer {
  display: flex;
  height: 303px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.photo {
  align-self: center;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-timing-function: ease;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: opacity;
}

.streamVideo {
  left: 0;
  position: absolute;
  top: 0;
}

.photosButton {
  background: $color-black-50-percent;
  border-radius: $border-radius;
  bottom: 15px;
  color: $color-white;
  display: flex;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  left: 15px;
  padding: 5px;
  position: absolute;

  .sprite {
    height: 12px;
    margin-right: 5px;
    width: 12px;
  }
}

.defaultVehicleIcon {
  height: 100px;
  margin: auto;
  width: 100px;
}

.noEvent {
  width: 50%;
  height: 50%;
  margin: auto;
}

.events {
  display: flex;
  flex-direction: column;
  gap: $spacing;
}
