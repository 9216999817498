@import 'styles/variables';

.dialog {
  z-index: $zIndexDialog;
}

.container {
  border-radius: 0;
  font-size: $font-size-14;
  height: 100%;
  margin: auto;
  top: 0;
  transform: none;
  width: 910px;
}

.containerInner {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.content {
  height: calc(100% - var(--system-navigation-height-single));
  overflow-y: auto;
}

.sectionInner {
  max-width: none;
}

.tagList {
  position: relative;

  &:not(:empty) {
    margin-top: -7px;
  }
}

.carfaxContainer {
  display: flex;
  flex-direction: row;
}

.carfaxImage {
  width: 83px;
}

.carfaxClaimAmount {
  color: $color-black;
  display: block;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  margin-bottom: 5px;
  margin-left: 7px;
}
