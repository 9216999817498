@import 'styles/variables';

.container {
  background-color: $color-surface-lighter;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 75%;
  height: 75%;
  max-width: 25px;
  max-height: 25px;
}

.img {
  background: white;
}
