@import 'styles/variables';

.banner {
  align-items: flex-start;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  font-size: $font-size-12;
  min-height: 32px;
  padding: $spacing-2x;
  width: 100%;
}

.title {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  margin-bottom: 5px;
}

.theme-black {
  border: 1px solid $color-black;
  color: $color-black;
}

.theme-green {
  background: $color-green-lightest;
  border: 1px solid $color-green;
}

.theme-blue {
  background: $color-blue-lighter;
  border: 1px solid $color-blue;
}

.theme-red {
  background: $color-red-lightest;
  border: 1px solid $color-red;
}

.theme-yellow {
  background: $color-yellow-lightest;
  border: 1px solid $color-yellow;
}
