@import 'styles/variables';
@import '../conditionReport';

.button {
  text-align: left;
  font-weight: $font-weight-regular;
  font-size: $font-size-12;
  width: 50%;
  margin-right: 10px;
}

.green {
  color: $color-green;
}

.red {
  color: $color-red;
}

.yellow {
  color: $color-yellow;
}
