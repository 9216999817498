@import 'styles/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.listItem:not(:last-child) {
  margin-bottom: 1.5rem;
}

.photoContainer {
  position: relative;
  height: 0;
  padding-bottom: 64%; // height ratio
  margin: 3px 0 15px;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.isDamagePhoto {
    border: 3px solid $color-red;
    border-radius: 4px;
    padding-bottom: calc(64% - 6px);

    .photo {
      border-radius: 0;
    }
  }
}

.photoButton {
  display: block;
}

.photo {
  display: block;
  border-radius: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  will-change: opacity;
}

.defaultVehicleIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}

.info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  color: $color-text;
}

.type {
  font-size: 0.9rem;
  font-weight: $font-weight-bold;
}

.location {
  font-size: $font-size-12;
}
