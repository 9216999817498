@import 'styles/variables';

.container {
  padding: $spacing $spacing-3x;
}

.header {
  border-bottom: 1px solid $color-surface-lighter;
  display: flex;
  justify-content: space-between;
  padding: $spacing 0;

  .scanResult {
    font-weight: $font-weight-semibold;
  }
}

.list {
  li:not(:last-child) {
    margin-bottom: $spacing * 1.5;
  }
}

.listItemHeader {
  color: $color-gray-dark;
  padding: $spacing * 1.5 $spacing 0 0;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.status-initial {
  color: $color-gray-dark;
}

.status-fail {
  color: $color-red;
}

.status-pass {
  color: $color-green;
}

.status-warning {
  color: $color-yellow;
}

.message {
  font-size: $font-size-14;
  text-align: center;
}

.refreshButton {
  width: 100%;
}
