@import 'styles/variables';

.container {
  display: flex;
}

.saleLight {
  align-items: center;
  background-color: $color-gray-lighter;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
  height: 20px;
  justify-content: center;
  margin: 2px;
  padding-top: 1px;
  user-select: none;
  width: 20px;

  &.red {
    background-color: $color-red;
  }

  &.green {
    background-color: $color-green;
  }

  &.yellow {
    background-color: $color-yellow;
  }
}
