@import 'styles/variables';

.container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.img {
  color: $color-gray-darkest;
}
