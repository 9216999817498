@import 'styles/variables';

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  width: 100%;
}

.operations {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $spacing-2x;
  justify-content: center;
  padding: 0 $spacing-3x;
  width: 100%;
}

.button {
  display: flex;
  letter-spacing: $letter-spacing-big;
  max-width: 160px;
  min-height: 32px;
  min-width: 100px;
  justify-content: center;
  width: 100%;
}

.date {
  color: $color-gray-darker;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
}

.green {
  color: $color-green;
}

.red {
  color: $color-red;
}
