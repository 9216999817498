@import 'styles/variables';

.header {
  margin-bottom: $spacing-2x;
}

.title {
  color: $color-gray-darker;
  font-weight: $font-weight-bold;
  line-height: 1;
}
