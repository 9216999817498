@import 'styles/variables';

.container {
  align-items: center;
  color: $color-text;
  display: flex;
  font-size: $font-size-12;
  margin-bottom: $spacing;
}

.tooltip {
  max-width: 400px;
}

.blackBookLogo {
  margin-right: 10px;
}

.tooltipGlyph {
  fill: $color-gray-darkest;
  height: 14px;
  width: 14px;
}
