@import 'styles/variables';

.watch {
  background-color: $color-gray-light;
  border: 1px solid $color-gray-light;
  color: $color-white;

  &:hover {
    background-color: darken($color-gray-light, 2%);
  }

  &.remove {
    border-color: $color-yellow;
    background-color: $color-yellow;

    &:hover {
      background-color: $color-yellow-dark;
      color: $color-white;
    }
  }
}

.icon {
  border-radius: 50%;
  fill: $color-gray-dark;
  height: 14px;
  padding: 2px;
  width: 14px;

  &.remove {
    background-color: transparent;
    fill: $color-yellow;
  }
}
