@import 'styles/variables';

.dialog {
  z-index: $zIndexDialog;
}

.formContainer {
  top: 0;
  margin: auto;
  width: 910px;
  height: 100%;
  transform: none;
  border-radius: 0;
  font-size: $font-size-14;
}

.formContainerInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 0;
}

.formContent {
  height: calc(100% - var(--system-navigation-height-single));
}
