@import 'styles/variables';
@import 'styles/typography';

$confirm-button-height: 40px;

.confirmDialog {
  width: 300px;
  text-align: left;
}

.header {
  align-items: center;
  border-bottom: 1px solid $color-gray-lighter;
  display: flex;
  gap: $spacing;
  justify-content: space-between;
  padding: $spacing-3x;
  width: 100%;
}

.body {
  color: $color-gray-darkest;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: $font-size-14;
  letter-spacing: $letter-spacing-small;
  line-height: $line-height;
  padding: $spacing-3x;
}

.title {
  color: $color-gray-darkest;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-medium;
  line-height: initial;
  text-align: left;
  width: 100%;

  @extend %ellipsis;
}

.actionButton:disabled {
  color: $color-gray-light;
}

.button {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0;
}

.closeButton {
  background-color: transparent;
  border: 0 none;
  display: flex;
  margin: -$spacing;
  padding: $spacing;
}

.sprite {
  fill: $color-gray-darkest;
  height: 13px;
  width: 13px;
}

.spinnerContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  height: $confirm-button-height;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;

  button {
    &:last-child {
      border-right: 0 none;
    }
  }
}

.bringToFront {
  z-index: $zIndexOverDialog;
}
