@import 'styles/variables';

.listItem {
  position: relative;
}

.removeButton {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 8px;
}

.tierRows {
  padding: 0;
}

.tierRow {
  background: $color-surface-lightest;
  display: flex;
  position: relative;
  margin: 0 -15px;
  padding: 3px 15px;

  > div:first-child {
    flex: 1;
  }

  &:not(:last-child) {
    &::after {
      border-bottom: 1px solid $color-surface-lighter;
      bottom: 0;
      content: '';
      display: block;
      left: 15px;
      position: absolute;
      width: calc(100% - 30px);
    }
  }

  &:last-child {
    margin-bottom: -15px; // Absorb padding
  }
}

.assuranceBadges {
  display: flex;
  align-items: center;

  svg + span {
    margin-left: 10px;
  }
}

.buyerShield {
  margin: 0 $spacing;
}
