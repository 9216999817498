@import 'styles/variables';
@import 'styles/typography';

.container {
  background-color: $color-surface-lightest;
}

.list {
  border-bottom: 1px solid $color-gray-lighter;
  background-color: $color-white;
  border-top: 1px solid $color-gray-lighter;
  margin-bottom: $spacing-3x;
  padding: 0 $spacing-2x;

  &:first-child {
    border-top: none;
  }
}

.listItem {
  border-bottom: 1px solid $color-gray-lighter;
  position: relative;

  &:last-child {
    border-bottom: none;
  }
}

.timer {
  margin: 0 -#{$spacing-2x};
}

.offerContainer {
  display: flex;
  flex-flow: column;
  font-size: $font-size-14;
  line-height: $font-size-14;
  padding: $spacing-2x 0;
  row-gap: $spacing-2x;
}

.offer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.actions {
  align-items: center;
  display: flex;
  flex-flow: column;
  row-gap: $spacing;

  & > button {
    min-height: $spacing-4x;
    min-width: 160px;
  }
}

.left {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  min-width: 0;
  row-gap: $spacing;
}

.heading {
  color: $color-gray-darker;
  font-weight: $font-weight-semibold;
}

.awaitingResponse {
  color: $color-gray-dark;
  font-weight: $font-weight-regular;
}

.user {
  @extend %ellipsis;
  color: $color-gray-dark;
  font-weight: $font-weight-regular;
  width: 100%;
}

.created {
  color: $color-gray-darker;
  font-weight: $font-weight-regular;
}

.comment {
  color: $color-gray-darkest;
  font-weight: $font-weight-regular;
  font-size: $font-size-14;
  line-height: $font-size-14;

  & > .prefix {
    font-weight: $font-weight-semibold;
  }
}

.right {
  align-items: flex-end;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.price {
  font-weight: $font-weight-semibold;
}

.strike {
  text-decoration: line-through;
}
