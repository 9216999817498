@import 'styles/variables';
@import 'styles/typography';

.multiFacetGroup {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 6px 0 0;
  padding-left: 15px;
  padding-right: 25px;
  background-color: $color-white;
  border: 1px solid #efefef;
  border-radius: 3px;
  color: $color-black;
  font-weight: $font-weight-regular;
  font-size: 12px;
  text-align: left;
  text-transform: capitalize;
  @extend %ellipsis;

  &::after {
    position: absolute;
    right: 15px;
    top: calc(50% - 2px);
    display: block;
    content: '';
    border-top: 4px solid $color-gray-light;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  &:disabled {
    color: $color-gray-light;

    &::after {
      color: $color-surface-lighter;
    }
  }

  &.selected {
    color: $color-blue;
    border-color: $color-blue;

    &::after {
      color: $color-blue;
    }
  }
}
