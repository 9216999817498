@import 'styles/variables';
@import './statusRows/statusRows';

.liveAuction {
  @extend %status;
}

.autobidAmount {
  color: $color-blue;
}

.isWinning {
  color: $color-green;
}

.isLosing {
  color: $color-red;
}
