@import 'styles/variables';
@import 'styles/typography';

.locations {
  position: relative;
}

.inputContainer {
  display: flex;
}

.input {
  position: relative;
  background: $color-white;
  flex: 1;
}

.inputError,
.inputError::placeholder {
  color: $color-black-50-percent !important; // stylelint-disable-line
}

// Reduced size for vehicleDetails form
.inputSmall {
  width: 100%;
  margin: 0;
  height: $input-height;
  background: $color-white;
}

.label {
  display: flex;
  justify-content: left;
  font-size: $font-size-12;
  color: $color-black-50-percent;
  padding: 15px 0 10px;
  line-height: $line-height-xsmall;
}

.checkbox {
  flex-shrink: 0;
  margin-top: 0;

  &:not(.transportToCompoundCheckbox):hover {
    background-color: $color-blue-lighter;
    color: $color-gray-darkest;
  }

  span {
    font-weight: $font-weight-semibold;

    &::after {
      content: ' - ';
    }
  }
}

.checkboxLabel {
  color: $color-black;
  text-align: left;
}

.transportToCompoundCheckbox {
  padding-left: 20px;
}

.actions {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.chevron {
  width: 10px;
  height: 10px;
  pointer-events: none;

  &::before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: inline-block;
    color: $color-gray-light;
    left: -2px;
    position: relative;
    top: 1px;
    transform: rotate(135deg);
    vertical-align: top;
    height: 4px;
    width: 4px;
  }

  &.isActive::before {
    transform: rotate(-45deg);
  }
}

.addButton {
  background: $color-blue;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  svg {
    width: 14px;
    height: 14px;
    fill: $color-white;
  }
}

.locationsList {
  background: $color-white;
  border: 1px solid $color-gray-lighter;
  border-radius: $block-border-radius;
  width: 100%;
  z-index: $zIndex1;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  position: absolute;
  top: 60px;
  overflow-y: auto;

  .checkbox {
    height: 50px;
    padding-left: 20px;
  }

  &.isSmallTheme {
    top: 42px;
    width: 287px;

    .checkbox {
      height: 40px;

      span {
        display: block;
        margin-bottom: $spacing-half;

        &::after {
          content: none;
        }
      }
    }

    .header {
      height: 40px;
    }
  }

  li:not(:last-child) .checkbox {
    border-bottom: 1px solid $color-gray-lighter;
  }
}

.header {
  height: 50px;
  background: $color-surface-lightest;
  border-bottom: 1px solid $color-gray-lighter;
  color: $color-black-50-percent;
  font-size: $font-size-14;
  padding-left: 20px;
  display: flex;
  align-items: center;
  text-align: left;
  user-select: none;

  &:not(:first-child) {
    border-top: 1px solid $color-gray-lighter;
  }
}

.isSmallTheme {
  .input {
    flex: none;
    width: 287px;
  }

  .checkboxLabel {
    @extend %ellipsis;
    width: 240px;
  }

  .transportToCompoundCheckbox {
    padding-left: 10px;
  }
}

.isDialogTheme {
  .inputContainer {
    display: block;
  }

  .transportToCompoundCheckbox {
    padding-left: 0;
    margin-top: 20px;
  }
}
