@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  padding: $spacing $spacing 0;
}

.labelBadge {
  align-self: end;
  position: relative;
}

.currentBidStatus {
  margin-bottom: 6px;
}
