@import 'styles/variables';
@import 'styles/typography';

.sectionInner {
  display: flex;
  overflow-x: scroll;

  .rep {
    padding: $spacing-2x $spacing-3x;

    &:first-child {
      padding-left: 0;
    }
  }
}

.rep,
.linkedRep {
  display: flex;
  flex-shrink: 0;
}

.name {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  color: $color-text;
}

.detail {
  @extend .type-body-3;
  color: $color-gray-dark;
  margin-top: $spacing-half;

  > span {
    color: $color-text;
  }

  &:empty {
    display: none;
  }
}

.imgContainer {
  width: 32px;
  height: 32px;
  margin-right: $spacing-2x;
}

.userImage {
  color: $color-gray-darkest;
}
