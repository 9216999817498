@import 'styles/variables';

.photoDetails {
  padding: 42px 0 115px;
}

.segment {
  position: relative;
  padding: 25px 15px 15px 25px;
  border-bottom: 1px solid $color-surface-lighter;
  background: $color-white;

  &:last-child {
    border-bottom: none;
  }
}

.section {
  width: 100%;
  margin: 0;
}

.title {
  color: $color-black-50-percent;
}

.sectionContent {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.addPhotoContainer,
.imgContainer {
  width: 207.5px;
}

.addPhotoContainer {
  display: flex;
  height: 155px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  background: $color-gray-light;
  color: $color-white;

  span {
    display: block;
    font-weight: $font-weight-semibold;
  }
}

.imgContainer {
  position: relative;
}

.imgWrapper {
  position: relative;
  height: 155px;
  border-radius: $border-radius;
  overflow: hidden;
  background: $color-gray-lighter;
}

.imgWrapperContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-gray-lighter;
  background-size: cover;
  background-position: center;
}

.plusSymbol {
  font-size: 2.625rem; // 42px
  line-height: $line-height-large;
  padding-bottom: 15px;
}

.addPhototext {
  font-size: $font-size-12;
  letter-spacing: $letter-spacing-medium;
}

.trashButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 5px 0 0;
}

.sprite {
  fill: $color-white;
  filter: drop-shadow(0 0 3px rgba($color-black, 0.3));
  height: 15px;
}

.placeholderSprite {
  width: 100%;
  height: 100%;
  padding: 25%;
  background: $color-gray-lighter;
}

.imgSpinner {
  fill: $color-surface-lighter;
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}

.dropdownContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border: none;
}

.fileInput {
  display: none;
}

.highlighted {
  background: $color-blue-lighter;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
}

.listItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 207.5px;
  height: 156px;
  margin: 0 10px 10px 0;

  &:hover {
    cursor: move;
  }
}

.listContent {
  width: 100%;
  height: 100%;
}

.listPointer {
  pointer-events: none;
}

.cursorDefault {
  cursor: default !important; //stylelint-disable-line
}

.cursorNotAllowed {
  &:hover {
    cursor: not-allowed;
  }
}

.sortableHelper {
  z-index: $zIndexDialog;
  font-size: $font-size-11;
}

.placeholder {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  background: none;
}

.errorMessageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 0 10px 10px 0;
  background: $color-red-lightest;
  border: 1px solid $color-red;
  color: $color-red;
  border-radius: $border-radius;
  text-align: center;
  font-size: $font-size-12;
  line-height: $line-height;
}

.closeErrors {
  height: 30px;
  position: absolute;
  right: $spacing;
  top: calc(50% - 16px);
  width: 30px;

  .sprite {
    fill: $color-red;
    height: 8px;
    width: 8px;
  }
}

.bullets {
  list-style-type: initial;
  align-self: center;
}
