@import 'styles/variables';

.codeRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;
}

.systemRow {
  align-items: center;
  color: $color-gray-dark;
  display: flex;
  margin-bottom: $spacing;

  .dot {
    background-color: $color-gray-lighter;
    margin: 0 5px;
  }
}

.description {
  color: $color-gray-dark;
  margin-bottom: $spacing;
}

.eventType {
  font-weight: $font-weight-semibold;
  border-radius: $block-border-radius;
  padding: 2px 10px;
}

.eventType-confirmed {
  background-color: $color-green-lightest;
  color: $color-green;
}

.eventType-pending {
  background-color: $color-yellow-lightest;
  color: $color-yellow;
}

.eventType-permanent {
  background-color: $color-blue-lighter;
  color: $color-blue;
}

.eventType-enhanced {
  background-color: $color-swatch-purple-light;
  color: $color-swatch-purple;
}
