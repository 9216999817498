@import 'styles/variables';

.subNavigation {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: var(--system-navigation-height-single);
  padding: 0 $container-padding;
  background-color: $color-white;
}

.links,
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.buttons {
  gap: $spacing;
  margin-left: auto;

  &:empty {
    display: none;
  }
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: calc(2 * #{$container-padding});

  &:last-child {
    margin-right: 0;
  }
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  color: $color-gray-dark;
  user-select: none;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;

  &:hover {
    text-decoration: none;
    color: $color-blue;
  }
}

.active {
  color: $color-blue;
  border-bottom: $border-active solid $color-blue;
  padding-top: 3px;
  pointer-events: none;
}
