@import 'styles/variables';

.container {
  position: relative;
}

.sprite {
  fill: $color-blue;
  height: 12px;
  width: 12px;
}

.successMessage {
  background: $color-black;
  border-radius: $block-border-radius;
  color: $color-white;
  font-size: $font-size-12;
  left: 100%;
  margin-left: 9px;
  padding: $spacing-half;
  pointer-events: none;
  position: absolute;
  top: -5px;
}

.chevron {
  &::after {
    transform: rotate(180deg);
    right: 4px;
  }
}
