@import 'styles/variables';

.options {
  display: flex;

  > button {
    margin-right: $spacing;
  }
}

.buttonContainer {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -10px;

  > button {
    margin-right: $spacing;
  }
}
