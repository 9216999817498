/*
 * Extends the .visually-hidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &--focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: relative;
      width: auto;
    }
  }
}
