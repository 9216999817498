@import 'styles/variables';

.selectInput {
  height: $input-height;
  font-size: $font-size-11;

  &.hasBottomMargin {
    margin-bottom: 10px;
  }
}

.option {
  font-size: $font-size-11;
  line-height: 1;
}

.name {
  font-weight: $font-weight-bold;
}
