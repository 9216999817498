@import 'styles/variables';

.slideOutHeader {
  padding: 0 15px;
}

.rep {
  border-bottom: 1px solid $color-surface-lighter;
  margin: 0;

  > a:last-child {
    padding-bottom: 0;
  }
}

.repInner {
  position: relative;
  padding: 15px 0;
}

.primaryTitle {
  font-size: $font-size-14;
}

.secondaryTitle {
  span {
    color: $color-gray-dark;
  }
}

.chevron {
  position: absolute;
  top: 25px;
  right: 5px;
}
