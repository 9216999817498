@import 'styles/variables';

.contentInner {
  background-color: $color-surface-lightest;
}

.listItem {
  border-bottom: 1px solid $color-surface-lighter;
  color: $color-gray-dark;
  font-size: $font-size-12;
  margin-bottom: 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}

.body {
  column-gap: $spacing-2x;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: $spacing-2x $spacing-2x;
}
