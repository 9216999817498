@import 'styles/variables';

.container {
  display: flex;
  flex-flow: column;

  h3 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $spacing;
    font-size: $font-size-11;
    color: $color-gray-light;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-big;
  }
}

.marginBottom {
  margin-bottom: $spacing-2x;
}

.facetGroupContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

.facetGroupName {
  color: $color-text;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  line-height: 16px;
}

.facets {
  display: flex;
  justify-content: space-between;
  width: 42px;
}

.checkbox {
  margin: 0;

  label {
    padding: 0;
  }
}

.disabled {
  color: $color-gray-light;
  cursor: none;
}
