@import 'styles/variables';

.container {
  width: 100%;
}

.declarations {
  display: flex;
  flex-flow: row wrap;
}

.lastKnownMileageRowButton {
  align-items: center;
  border-top: 1px solid $color-surface-lighter;
  display: flex;
  margin: 10px -25px -15px;
  padding: 10px 0;
  width: calc(100% + 50px);

  &:hover {
    background: $color-surface-lightest;
  }

  > div {
    flex-shrink: 1;
    margin-right: 10px;
  }
}
