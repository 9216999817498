@import 'styles/variables';

.bidOverlay {
  align-items: center;
  display: grid;
  grid-gap: $spacing;
  grid-template-columns: minmax(200px, max-content) 1fr;
  position: relative;
  width: 100%;

  & ~ * {
    display: none !important; // stylelint-disable-line
    visibility: hidden;
  }
}

.isBidding {
  > * {
    visibility: hidden;
  }
}

.bidSpinner {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  visibility: visible;
}

.button {
  border-radius: $border-radius-small;
  font-size: $font-size-14;
  min-height: 40px;
  min-width: 200px;
  text-transform: none;
}

.cancel {
  width: 200px;
}

@keyframes bulge {
  0% {
    transform: scale(1, 1);
  }

  50% {
    color: $color-green;
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.bulge {
  animation-name: bulge;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  transform-origin: center;
  animation-fill-mode: forwards;
  will-change: transform;
}
