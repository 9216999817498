@import 'styles/variables';

.container {
  display: flex;
  flex-flow: column;
  row-gap: $spacing;
}

.list {
  color: $color-gray-darker;
  display: flex;
  flex-flow: column;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  line-height: 1;
  row-gap: $spacing;
}

.item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.button {
  align-items: center;
  background: 0;
  border: 0;
  column-gap: 8px;
  display: flex;
  flex-flow: row;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  text-align: left;
}

.chevron {
  height: 8px;
  transform: rotate(90deg);
  width: auto;

  &.less {
    transform: rotate(270deg);
  }
}
