@import 'styles/variables';

.swatchContainer {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 10px;

  &.black {
    background: $color-black;
  }

  &.blue {
    background: $color-blue;
  }

  &.brown {
    background: $color-swatch-brown;
  }

  &.gold {
    background: $color-yellow;
  }

  &.gray {
    background: $color-gray-light;
  }

  &.green {
    background: $color-swatch-green;
  }

  &.orange {
    background: $color-yellow;
  }

  &.purple {
    background: $color-swatch-purple;
  }

  &.red {
    background: $color-swatch-red;
  }

  &.silver {
    background: $color-gray-light;
  }

  &.tan {
    background: $color-swatch-tan;
  }

  &.teal {
    background: $color-swatch-teal;
  }

  &.white {
    background: $color-white;
    border: 1px solid rgba($color-line, 0.3);
  }

  &.yellow {
    background: $color-swatch-yellow;
  }
}
