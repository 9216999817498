@import 'styles/variables';
@import '../vehicleDetails';

.title {
  color: $color-gray-darker;
  font-size: $font-size-14;
}

.tireConditionInputsLayout {
  gap: $spacing-3x;
  justify-content: start;
}

.inputGroup {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: $spacing-half;
}

.number {
  max-width: 56px;
}

.tireSizeColumn,
.tireTreadColumn {
  width: auto;
}
