@import 'styles/variables';

.container {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  min-height: 100px;
}

.message {
  align-items: center;
  color: $color-gray-dark;
  display: flex;
  font-size: $font-size-12;
  line-height: $line-height-atom;
  white-space: pre-line;
}

.linkButton {
  color: $color-blue;
  height: 32px;
  text-align: left;
  width: fit-content;
}
