@import 'styles/variables';
@import 'styles/typography';

$base-padding: 12px;

.crButtonGroup {
  display: flex;
  flex-flow: row nowrap;
}

.crButtonContainer {
  flex: 1;
  position: relative;

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.crButtonSmContainer {
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .crEditButton {
    right: 6px;
    top: 6px;
  }
}

.crButton {
  align-items: center;
  border-radius: 4px;
  border: 1px solid $color-gray-lighter;
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 9px 15px;
  position: relative;
  text-align: left;
  width: 100%;

  .chevron {
    margin-right: -5px;
  }

  &.crButtonUpdatable .chevron {
    display: none;
  }

  &.noArrow .chevron {
    display: none;
  }

  &.crButtonSm {
    height: 30px;
    padding: 6px 10px;

    .chevron {
      margin-right: 0;
    }
  }

  &.crButtonRed {
    background-color: $color-red-lightest;
    border-color: $color-red;

    .crButtonText {
      color: $color-red;
    }

    .chevron {
      fill: $color-red;
    }
  }

  .crButtonText {
    color: $color-green;
    display: block;
    font-size: 1.125rem; // 18px
    font-weight: $font-weight-bold;
    margin-bottom: 5px;

    &.green {
      color: $color-green;
    }

    &.yellow {
      color: $color-yellow;
    }

    &.red {
      color: $color-red;
    }

    &.black {
      color: $color-gray-darkest;
    }

    &.text {
      color: $color-text;
    }

    &.tireCondition {
      text-transform: capitalize;
    }

    &.mmg {
      color: $color-gray-darker;
      display: inline;
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
      line-height: 1;
      margin: 0;
      padding: 0;
    }
  }

  .crButtonSubText {
    @extend %ellipsis;
    color: $color-text;
    display: block;
    font-weight: $font-weight-semibold;
    line-height: 1;
  }

  &.disabled {
    pointer-events: none;

    .chevron {
      display: none;
    }
  }

  &.isRequired {
    background-color: $color-red-lightest;
    border-color: $color-red;
  }
}

.crRow {
  border-bottom: solid 1px rgba($color-line, 0.3);
  padding: 15px 0 10px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.crRowTitle {
  color: $color-gray-dark;
}

.crRowContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.crRowContentIcon {
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  width: 16px;
}

.crRowList {
  display: flex;
  flex-flow: row wrap;
  line-height: 1.6;

  > li {
    font-weight: $font-weight-semibold;
  }

  > li:not(:last-child) {
    margin-right: $spacing;
    position: relative;

    &::after {
      color: $color-gray-lightest;
      content: '•';
      height: 10px;
      margin-left: $spacing;
      width: 10px;
    }
  }
}

.crEditButton {
  position: absolute;
  right: 10px;
  top: 10px;

  svg {
    fill: $color-blue;
    height: 12px;
    width: 12px;
  }
}

.crEditGlyph {
  color: $color-blue;
  height: 12px;
  position: absolute;
  top: 16px;
  right: 10px;
  width: 12px;

  &.crButtonSm {
    right: 10px;
    top: 8px;
  }
}

.isRequired {
  > button,
  > div {
    color: $color-red;
  }

  + .editButton,
  .editButton {
    background: $color-red;
    color: $color-white;

    &:hover {
      background: $color-red-dark;
    }
  }
}
