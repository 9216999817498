@import 'styles/variables';

// ======================================================
// NOTE: Overwrites React Component's Slider ------------

$slider-track-height: 8px;
$slider-handle-width: 30px;
$slider-handle-half-width: calc(#{$slider-handle-width} / 2);

.container {
  position: relative;
}

.green {
  background: $color-green;
}

.orange {
  background: $color-yellow;
}

.red {
  background: $color-red;
}

:global {
  .rc-slider {
    width: 87%;
    margin: 0 auto 30px;
    height: $slider-track-height;
    z-index: $zIndex0;

    &-track,
    &-handle {
      background-color: $color-blue;
      opacity: 1;
    }

    &-track {
      height: 10px;
      z-index: $zIndex1;
    }

    &-handle {
      width: $slider-handle-width;
      height: 25px;
      margin-top: -$slider-track-height;
      border-radius: $slider-handle-half-width;
      border-color: $color-surface-lightest;
      margin-left: -14px;
      z-index: $zIndex2;
      transform: translateX(0%) !important; // stylelint-disable-line

      &:hover,
      &:focus {
        border-color: $color-surface-lightest;
      }

      &-dragging {
        border-color: $color-surface-lightest !important; // stylelint-disable-line
        box-shadow: 0 0 5px $color-blue !important; // stylelint-disable-line
      }
    }

    &-step {
      background-color: $color-gray-lighter;
      width: 115%;
      height: 100%;
      border-radius: 5px;
      transform: translateX(-6%);
    }
  }

  .customSlider {
    position: relative;
    margin: 0 14px;
    max-width: 140px;

    .rc-slider-track,
    .rc-slider-handle {
      background-color: $color-gray-darkest;
    }
  }

  .customSlider::after {
    content: '';
    position: absolute;
    top: 5px;
    left: -14px;
    width: 25px;
    height: 10px;
    background-color: $color-gray-darkest;
    border-radius: 5px;
  }

  .green {
    &::after,
    .rc-slider-track,
    .rc-slider-handle {
      background-color: $color-green;
    }
  }

  .orange {
    &::after,
    .rc-slider-track,
    .rc-slider-handle {
      background-color: $color-yellow;
    }
  }

  .red {
    &::after,
    .rc-slider-track,
    .rc-slider-handle {
      background-color: $color-red;
    }
  }
}
