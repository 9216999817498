@import 'styles/variables';
@import 'styles/typography';

.headerBar {
  color: $color-gray-dark;
  font-size: $font-size-12;
  display: flex;
  margin-bottom: $spacing;
  max-width: 775px;
  padding: $spacing $spacing $spacing $spacing-3x;
  border-bottom: 1px solid $color-gray-lighter;

  & > *:last-child {
    @extend %ellipsis;
    flex: 1;
    text-align: right;
    padding-left: 15px;
  }

  .labelComp {
    &:not(:first-child)::before {
      content: '•';
      padding: 0 5px;
    }
  }
}

.header {
  display: grid;
  grid-template-columns: minmax(0, 80%) auto;
  max-width: 775px;
  min-height: $search-bar-height + 1;
  min-width: calc(275px - (6 * #{$spacing}));
  padding: 0 0 $spacing-half-3x $spacing-3x;
}

.top {
  display: flex;
  height: 32px;
  margin-bottom: $spacing-half;

  .runNumberBadge {
    --badge-size: 32px;
    font-size: $font-size-12;
    padding: $spacing;
    margin-right: $spacing;
  }

  .saleLights {
    padding: 0;
  }
}

.title {
  color: $color-gray-darkest;
  font-size: $font-size-18;
  font-weight: $font-weight-bold;
  margin-right: $spacing;

  @extend %ellipsis;
}

.subtitle {
  color: $color-gray-darkest;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  margin-right: $spacing;
  margin-top: $spacing-half;

  @extend %ellipsis;
}

.badgesContainer {
  align-items: center;
  display: flex;
  margin-top: $spacing-quarter;
}

.mileage {
  color: $color-gray-dark;
  font-weight: $font-weight-semibold;
  font-size: $font-size-14;
  padding-right: 5px;
  white-space: nowrap;
}

.badges {
  --badge-size: 24px;
  display: flex;
  margin-top: 2px;
}
