@import 'styles/variables';

.list {
  margin-top: 15px;
}

.listItem {
  background: $color-white;
  border: 1px solid $color-surface-lighter;
  border-radius: 5px;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 12px 15px 10px;
  display: flex;
  justify-content: space-between;

  &.selected {
    border: 1px solid $color-green;
  }

  &.yellow {
    border: 1px solid $color-yellow;
  }

  &.red {
    border: 1px solid $color-red;
  }
}

.scoreRating {
  color: $color-text;

  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background: $color-green;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -2px;
  }

  &.yellow {
    &::before {
      background: $color-yellow;
    }
  }

  &.red {
    &::before {
      background: $color-red;
    }
  }
}

.scoreRatingInfo {
  color: $color-gray-dark;
  letter-spacing: $letter-spacing-medium;
}

.listItemExtensive {
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  padding: 15px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-surface-lighter;
  }
}

.scoreExtensive {
  display: flex;
  justify-content: space-between;

  // The score value
  > span:nth-child(2) {
    font-weight: $font-weight-extrabold;
  }
}

.noteExtensive {
  font-weight: $font-weight-regular;
  color: $color-text;
  margin-top: 10px;
}

.buyerShieldBadge {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  transform: translateY(1px);
}
