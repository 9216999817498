@import 'styles/variables';

$base-padding: 12px;

.instructions {
  p {
    font-size: $font-size-12;
    line-height: 1.4;
    margin-bottom: $base-padding;
  }
}

.select {
  margin: 0;
  font-size: $font-size-11;
}
