@import 'styles/variables';

.container {
  overflow: hidden;
  overflow-y: scroll;
  justify-content: center;
  min-width: 1024px;
  height: calc(100vh - var(--system-navigation-height-full));
}

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1000px;
  width: 100%;
  margin: 20px auto;
}

.wrapperInner {
  background: $color-white;
  border-radius: $border-radius;
}

.backButtonContainer {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.backButtonLink {
  display: flex;
  align-items: center;
  padding: 0 10px 17px 0;
  color: $color-gray-darkest;
}

.sprite {
  width: 13px;
  height: 13px;
}

.header {
  text-transform: uppercase;
  font-size: $font-size-11;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing;
  padding-left: 21px;
}

.block {
  background: $color-white;
  border-radius: $border-radius;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 38px;

  &:last-child {
    margin-bottom: 0;
  }
}

.sectionTitle {
  display: flex;

  .labelTooltip {
    margin-left: $spacing;
  }

  .tooltipGlyph {
    margin-top: 0;
  }
}

.sectionMultiLine > .sectionChildren {
  display: flex;
  justify-content: space-between;
  row-gap: $spacing-2x;
}

.title {
  font-weight: $font-weight-semibold;
  color: $color-gray-darkest;
}

.sectionChildren {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.content {
  display: flex;
  align-items: center;
}

.icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $color-gray-lightest;
  width: 52px;
  height: 52px;
  margin-right: 20px;
  overflow: hidden;

  svg {
    width: 50%;
    height: 50%;
    fill: $color-gray-light;
  }
}

.spriteImg {
  position: absolute;
}

.img {
  position: relative;
  z-index: $zIndex1;
  display: block;
  width: 100%;
  height: 100%;
}

.contentText {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-right: 20px;
  font-size: $font-size-11;
}

.contentTitle {
  text-transform: uppercase;
  letter-spacing: $letter-spacing-medium;
  font-weight: $font-weight-semibold;
  color: $color-gray-darkest;
}

.contentDetails {
  color: $color-black-50-percent;
  padding-top: 5px;
  letter-spacing: $letter-spacing;
  line-height: $line-height-xsmall;
  text-align: left;
}

.submitFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 85px;
  width: 100%;
  background: $color-white;
  border-top: 1px solid $color-gray-lighter;
  padding: 25px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.button {
  font-size: $font-size-10;
  border-radius: $block-border-radius;
  min-height: 0;
  height: 35px;
  width: 185px;
  padding: 0 12px;
  background-color: $color-blue;
}

.spinner {
  margin: 0 auto;
}

.spinnerStyle {
  &::before {
    background: white;
  }
}
