@import 'styles/variables';

.timerBar {
  width: 100%;
  height: 100%;
}

@keyframes animation {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.animatedTimerBar {
  animation-name: animation;
  transform-origin: left;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  /* use GPU to get smooth animation on decent browsers http://caniuse.com/#search=will-change */
  will-change: transform;
}
