@import 'styles/variables';

.reserveAmounts {
  display: grid;
  grid-column-gap: 6px;
  grid-template-columns: repeat(2, 1fr);
  height: 46px;
}

.valueContainer {
  align-items: center;
  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-gray-lighter;
  color: $color-gray-darker;
  display: flex;
  flex-direction: column;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.value {
  --badge-size: 18px;
  align-items: center;
  border-radius: $border-radius;
  color: $color-white;
  display: flex;
  font-size: $font-size-12;
  height: 18px;
  margin-bottom: 5px;
  padding: 0 $spacing-2x;

  &.reserve {
    background-color: $color-gray-dark;
  }

  &.gap {
    background-color: $color-red;
  }
}
