@import 'styles/variables';

.container {
  background-color: $color-gray-lightest;
  height: 100%;
  position: relative;
}

.filters {
  border-right: 1px solid $color-surface-lighter;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: $facets-width;
}

.search {
  display: flex;
  padding: $spacing-2x $spacing * 1.5 10px;
  height: 60px;

  > div {
    flex: 1;
  }
}

.facetGroups {
  flex: 1;
  height: calc(100% - #{$search-bar-height});

  > div {
    height: 100%;
    overflow-y: auto;
  }
}

.listContainer {
  background: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.listContainerInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-2x $spacing-3x $spacing-2x $spacing;
  row-gap: $spacing-2x;
}

.auctionItem {
  height: 100%;
  position: fixed;
  width: 100%;
}

.auctionItemInner {
  background: $color-white;
  box-shadow: 1px 0 5px 0 rgba($color-black, 0.15);
  height: 100%;
  max-width: 750px;
  min-width: 385px;
  position: absolute;
  right: 0;
}
