@import 'styles/variables';

.formInputs {
  margin-top: 12px;
}

.lastKnownMileageDialog {
  z-index: $zIndexDialog;
}

.lastKnownMileageDialogInner {
  > div {
    width: 100%;
  }
}

.mileageInput {
  margin-top: 12px;
}
