@import 'styles/variables';

.about {
  margin-bottom: $spacing;
}

.autoGradeContent {
  display: flex;
  margin-top: $spacing-2x;
}

.autoGradeImage {
  align-self: center;
  margin-right: $spacing;
  width: 120px;
}

.link {
  color: $color-blue;
  fill: $color-blue;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;

  .linkGlyph {
    height: 7px;
    margin-left: 6px;
    width: 7px;
  }
}
