@import 'styles/variables';

.container {
  display: flex;
  flex-flow: row nowrap;
  gap: $spacing;
  padding: 10px;
}

.controlButton {
  --icon-button-size: 20px;

  .sprite {
    --icon-size: 12px;
  }
}

.volumeControl {
  background: $color-white;
  color: $color-gray-lighter;

  svg:not(.isMuted) {
    margin-right: 1px;
  }
}

.fullScreenControl {
  background: transparent;

  &:hover {
    background: transparent;
  }

  .sprite {
    --icon-size: 15px;
  }
}
