@import 'styles/variables';

.content {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.editButton {
  margin-left: 10px;
}
