@import 'styles/variables';
@import 'styles/typography';

.dialog {
  width: 320px;
}

.title {
  margin-bottom: 25px;
  font-size: $font-size-12;
  line-height: initial;

  @extend %ellipsis;
}

.inputContainer {
  display: flex;
  border: 1px solid $color-gray-lighter;
  border-radius: $block-border-radius;
}

.input {
  width: 100%;
  height: 40px;
  border: none;

  @extend %ellipsis;
}

.button {
  min-width: 60px;
  min-height: 30px;
  margin: 5px 5px 5px 0;
  font-weight: $font-weight-extrabold;
}
