@import 'styles/variables';

.soundIndicator {
  align-items: center;
  color: $color-white;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
}
