@import 'styles/variables';

.container {
  display: block;
  width: 100%;
  font-size: $font-size-12;

  &.isOpen {
    .chevron {
      transform: rotate(-90deg);
    }

    .content {
      padding-top: 10px;
      display: block;
      line-height: $line-height-large;
    }
  }
}

.toggle {
  display: block;
  text-align: left;
  width: 100%;
  position: relative;
}

.chevron {
  width: 5px;
  height: 9px;
  position: absolute;
  top: calc(50% - 4px);
  right: 5px;
  fill: #c0c1c3;
  transform: rotate(90deg);
}

.content {
  display: none;
  padding: 5px 0;
}
