@import 'styles/variables';

.container {
  color: $color-blue;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
}

.text {
  color: $color-text;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
}
