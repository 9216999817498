@import 'styles/variables';

.container {
  position: relative;
  margin: 0 $spacing $spacing;

  .input {
    border-radius: $border-radius;

    > input {
      font-size: $font-size-12;
      padding-right: 35px;
    }
  }
}

.sendButton {
  color: $color-blue;
  height: 100%;
  padding: 3px $spacing 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;

  svg {
    height: 20px;
    width: 20px;
  }
}

.messageLimitSend {
  top: 11px;
}

.submittingOverlay {
  background: rgba($color-white, 0.9);
  height: 100%;
  padding-top: $spacing-half;
  position: absolute;
  top: 0;
  width: 100%;
}

.charMessageLimit {
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: $color-gray-light;
  padding-bottom: $spacing-half;
  margin-left: $spacing-half;
}
