@import 'styles/variables';

.noResults {
  text-align: center;
  height: calc(100vh - var(--system-navigation-height-single));
  padding-top: var(--system-navigation-height-single);
}

.noResultsDetails {
  margin-bottom: $spacing;
}
