@import 'styles/variables';

.container {
  background: $color-white;
  border-radius: $block-border-radius;
  display: flex;
  font-size: $font-size-12;
  gap: $spacing-2x;
  padding: $spacing-2x $spacing-2x $spacing;
  position: relative;
}

.iconContainer {
  display: flex;
  height: 100%;
}

.icon {
  align-items: center;
  background: $color-green;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  svg {
    width: 21px;
    height: 16px;
  }
}

.info {
  display: flex;
  flex-basis: 364px;
  flex-direction: column;
  row-gap: 8px;
}

.title {
  color: $color-gray-darker;
  font-size: $font-size-32;
  line-height: 1;
}

.subTitle {
  color: $color-gray-dark;
  font-size: $font-size-10;
  font-weight: $font-weight-regular;
  line-height: 14px;
  white-space: normal;
}

.stats {
  flex-shrink: 0;
  height: 100%;
  margin-left: auto;
}

.statsInner {
  align-items: center;
  column-gap: $spacing-5x;
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: $spacing;
}

.checkboxContainer {
  grid-column-start: 2;
}

.checkbox {
  display: inline-flex;
  margin: 0;
}

.checkboxLabel {
  color: $color-gray-darker;
}
