@import 'styles/variables';

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: -3px 15px 0;
  padding: 3px 0;
}

.rightText {
  flex-shrink: 0;
  text-align: right;
  width: auto;
}

.liveLanesLogo {
  color: $color-black;
  height: 10px;
  margin-top: 5px;
  width: 76px;
}
