@import 'styles/variables';

.wrapper {
  background: $color-surface-lightest;
}

.container {
  align-items: center;
  display: flex;
  font-size: $font-size-12;
  margin: 0 $spacing-3x;
  max-width: 750px;
  padding: 20px 0;

  > img {
    margin-right: -$spacing;
  }
}

.titleContainer {
  flex-grow: 1;
}

.title + p {
  overflow: visible;
}

.vinNumberContainer {
  align-items: center;
  display: flex;
  gap: 5px;
}

.vinNumber {
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
}

.editButton {
  margin-left: 12px;

  svg {
    fill: $color-blue;
    height: 12px;
    width: 12px;
  }
}
