@import 'styles/variables';
@import 'forms/shared/select';
@import '../../userNotificationsSlideOut';

.selects {
  display: flex;
  flex-direction: column;
  margin-top: $spacing;
}

.select {
  @extend .selectInput;
  margin: $spacing-half-3x 0;
}

.mileage {
  margin: $spacing 0;

  input {
    font-size: $font-size-11;
  }
}

.rangeContainer {
  margin-top: $spacing-2x;

  :global {
    .rc-slider-handle {
      margin-left: -27px;
    }

    .rc-slider-handle-2 {
      margin-left: 1px;
    }

    .rc-slider-track {
      transform: scaleX(1.1);
    }
  }
}

.rangeTitles {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;

  &:first-of-type {
    margin-top: 0;
  }

  .sectionSubtitle {
    margin-top: 0;
    text-align: right;
  }
}
