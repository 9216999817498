@import 'styles/variables';
@import './facetGroups';

.savedSearches {
  margin-bottom: 20px;

  .heading {
    margin-top: 0;
  }

  .savedSearch {
    margin: 7px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 18px;

    .name {
      color: $color-black;
      font-size: $font-size-12;
      text-decoration: underline;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .trashButton {
      height: 12px;
    }

    .trash {
      fill: $color-black;
      height: 12px;
      width: 15px;
    }
  }
}
