@import 'styles/variables';

.errorTitle {
  color: $color-red;
}

.archiveButton {
  align-items: center;
  background-color: $color-gray-light;
  border: 1px solid $color-gray-light;
  border-radius: $border-radius;
  color: $color-white;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 0;
  min-height: 32px;
  padding: 0 $spacing;

  &.archived {
    background-color: $color-red;
    border-color: $color-red;
  }

  svg {
    margin-right: $spacing;
  }
}
