@import 'styles/variables';

$dollar-sign-width: 40px;

.inputText {
  display: flex;
  position: relative;
  height: $input-height;
  border: 1px solid $color-input-border;
  border-radius: $block-border-radius;

  // everything nested below is to override modalDialog styles
  // TODO: move all of these to theme-${theme}-input when we move away from the old modalDialog
  &.theme-minimalSmall,
  &.theme-minimalSmallCurrency {
    > input {
      border: none;
      padding: 0 12px;
      font-size: $font-size-11;

      &::placeholder {
        font-size: $font-size-11;
        color: $color-black-50-percent;
      }
    }
  }

  &.theme-minimalSmallCurrency {
    > input {
      padding: 0 12px 0 0;
    }

    &::before {
      background: none;
      border: none;
      height: 100%;
      margin-top: 1px;
      font-size: $font-size-13;
    }
  }

  &.theme-minimal {
    > input {
      padding: 0 20px 0 0;
      font-size: $font-size-14;

      &::placeholder {
        font-size: $font-size-14;
        color: $color-gray-lighter;
      }
    }
  }

  &.text,
  &.email,
  &.number,
  &.password {
    font-size: $font-size-13;
    border-color: $color-gray-lighter;

    input {
      color: $color-gray-darkest;
      font-size: $font-size-11;
      font-weight: $font-weight-regular;
      padding: 9px 12px;

      &::placeholder {
        color: $color-black-50-percent;
        font-size: $font-size-11;
        font-weight: $font-weight-regular;
      }
    }
  }

  &.disabled {
    border-color: $color-surface-lighter;

    > input {
      border-color: $color-surface-lighter;

      &::placeholder {
        color: $color-surface-lighter;
      }
    }

    &::before {
      color: $color-surface-lighter;
    }
  }
}

.input {
  border: none;
  font-size: $font-size-13;
  background-color: transparent;
  padding: $input-padding;
  resize: none;
  box-sizing: border-box;
  line-height: 12px;
  color: $color-black;
  width: 100%;
  height: 100%;

  &.fontLarge {
    font-size: $font-size-14;
    padding: $spacing-2x 36px $spacing-2x 20px;

    &::placeholder {
      font-size: $font-size-14;
    }
  }

  &.rightPadding {
    padding: 0 $spacing-4x 0 12px;
  }
}

.labelContainer {
  display: flex;
}

.label {
  display: block;
  color: $color-black-50-percent;
  font-size: $font-size-12;
  text-align: left;
  padding: 15px 0 10px;
}

.labelTooltip {
  margin-left: 5px;
  transform: translateY(-3px);
}

.error {
  border-color: $color-red;
}

.hidden {
  display: none;
}

.currency {
  align-items: stretch;

  &::before {
    content: '$';
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: $dollar-sign-width;
    height: 100%;
    background-color: $color-surface-lightest;
    border-right: 1px solid $color-input-border;
    border-top-left-radius: $block-border-radius;
    border-bottom-left-radius: $block-border-radius;
    box-sizing: border-box;
    color: $color-gray-light;
  }
}

.currency-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-gray-input {
  background: $color-white;

  &::placeholder {
    font-size: $font-size-11;
    color: $color-black-50-percent;
  }
}

.theme-minimal {
  align-items: center;
  border: none;
  flex: 1;

  &.currency {
    &::before {
      font-weight: $font-weight-regular;
      background-color: $color-white;
      color: $color-gray-darkest;
      border: 0;
      width: 8px;
      margin: 0 14px 0 18px;
    }
  }
}

.theme-minimalSmall {
  width: 100%;
}

.theme-bold {
  border-color: $color-gray-lighter;

  &::before {
    background: none;
    border: none;
    width: $dollar-sign-width / 2;
    justify-content: flex-end;
  }
}

.fontLargeContainer {
  height: 50px;
}

.currencyLarge {
  &::before {
    font-size: $font-size-14;
  }
}

.theme-error,
.text.theme-error {
  background-color: $color-red-lightest;
  border-color: $color-red;
  color: $color-red;

  ::before {
    color: $color-red;
    border-color: $color-red;
    background-color: $color-red-lightest;
  }
}

.theme-error-input {
  color: $color-red;

  &::placeholder {
    color: $color-red;
  }
}
