@import 'styles/variables';

.formErrors {
  margin-bottom: 25px;
}

.splitSection {
  display: flex;
  height: 100%;
}

.splitSectionWithErrors {
  height: calc(100% - 103px);
}

.splitSectionLeft {
  width: 557px;
  height: 100%;
  overflow-y: auto;
}

.splitSectionRight {
  flex: 1;
  height: 100%;
  border-left: 1px solid $color-gray-lighter;
}

.formSectionNoPadding {
  padding: 0;
}

.formSectionSinglePreview {
  padding: 15px 25px;
  width: 100%;
}

.paymentMethods {
  grid-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;

  .multiSelectRadio {
    &:not(:last-child) {
      /* Specificity to overwrite MultiSelectCard */
      margin: 0;
    }
  }
}

.title {
  display: flex;
  align-items: center;
}

.pin {
  background: $color-gray-dark;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 10px;
    height: 10px;
  }
}

.pinSelected {
  background: $color-green;
}

.addOnTitle {
  display: flex;
  align-items: center;
}

.tooltip {
  margin-left: 6px;
}

.tooltipDescriptions {
  text-align: left;
  list-style: disc;
  margin-left: 10px;

  & :only-child {
    list-style: none;
    margin-left: 0;
  }
}

.addOnDescription,
.addOnPrice {
  color: $color-gray-dark;
  font-weight: $font-weight-regular;
  margin-top: 10px;
  line-height: $line-height;

  span {
    color: $color-text;
    font-weight: $font-weight-semibold;
  }
}

.pickupLocation {
  font-weight: $font-weight-regular;
  margin-top: 10px;
  width: 236px;
}

.confirmPickupLocation {
  p {
    margin-bottom: $spacing-2x;
  }
}
