@import 'styles/variables';
@import './statusRows/statusRows';

.noSale {
  @extend %status;
}

.highestOffer {
  color: $color-red;
}
