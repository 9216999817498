@import 'styles/variables';
@import 'styles/typography';

.facetGroups {
  height: 100%;
  padding: 6px 12px 15px;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}

.titleContainer {
  display: flex;
  height: 15px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  color: $color-black;
  letter-spacing: $letter-spacing-big;
  text-transform: uppercase;
}

.heading {
  display: flex;
  justify-content: space-between;
  color: $color-gray-light;
  font-size: $font-size-11;
  letter-spacing: 1px;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  margin: 25px 0 0;

  &:first-of-type {
    margin-top: 0;
  }
}

.spacer {
  height: $spacing-2x;
}

.showMyUnitsSwitch {
  margin-bottom: $spacing-2x;

  &::after {
    border-bottom: 1px solid $color-gray-lighter;
    content: '';
    display: block;
    margin-top: 10px;
  }
}

.radio {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border: 1px solid $color-gray-dark;
  border-radius: 50%;
  margin-right: $spacing;
  position: relative;

  &.selected {
    border-color: $color-blue;

    &::after {
      position: absolute;
      top: 1px;
      left: 1px;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-blue;
    }
  }
}

@keyframes bulge {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}
