@import 'styles/variables';

.row {
  color: $color-gray-darker;
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  padding: 6px $spacing;

  &.bold {
    color: $color-black;
    font-weight: $font-weight-semibold;
  }

  &:nth-child(even) {
    background-color: $color-blue-lighter;
  }

  &:nth-child(odd) {
    background-color: $color-blue-light;
  }
}
