@import 'styles/variables';

.container {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  padding: $spacing $spacing-2x;
  max-width: 175px;
  text-align: left;
}

.title {
  font-size: $font-size-14;
  width: 100%;
}

.description {
  margin-top: 0;
}
