@import 'styles/variables';

.blackBookValues {
  width: 242px;
  min-width: 242px;

  & > div {
    border-bottom: 1px solid $color-gray-lighter;
  }
}

.row {
  align-items: center;
  display: flex;
  height: 33px;
  justify-content: space-between;
  padding: 0 $spacing-2x;
}

.tooltipDescription {
  color: $color-gray-dark;
  font-size: $font-size-10;
  padding-right: $spacing;
}

.tooltipRow {
  padding: $spacing 0 0;
}

.logo {
  height: 17px;
  width: 33px;
}

.label {
  color: $color-gray-dark;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
}

.value {
  color: $color-gray-darker;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
}
