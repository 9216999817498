@import 'styles/a11y';
@import 'styles/variables';

$knobSize: 18px;

.input {
  composes: visually-hidden;
}

.label {
  align-items: center;
  background-color: $color-gray-lighter;
  border-radius: 13px;
  cursor: pointer;
  display: flex;
  height: 22px;
  position: relative;
  transition: 0.1s;
  width: 44px;

  &.checked {
    background-color: $color-green;
  }
}

.switchDot {
  align-items: center;
  background-color: $color-white;
  border-radius: 13px;
  content: '';
  display: flex;
  height: $knobSize;
  justify-content: center;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: 0.1s;
  width: $knobSize;

  &.checked {
    left: calc(100% - (#{$knobSize} + 2px));
  }
}
