@import 'styles/variables';
@import 'styles/typography';
@import './feeValueItem';

.listItemInner {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;

  & > div {
    margin-bottom: 0;
    padding-bottom: $spacing-half;
  }

  & > div:last-child {
    padding-bottom: 0;
  }
}

.listItemHeader {
  border-bottom: 1px solid $color-surface-lighter;
  color: $color-gray-darkest;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;

  .tooltipGlyph {
    background: transparent;
    border-radius: 0;
    fill: $color-gray-darkest;
    height: 20px;
    margin: -4px 0 0 4px;
    padding: 2px;
    width: 20px;
  }

  .linkButton {
    color: $color-gray-darkest;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    svg {
      height: 14px;
      width: 14px;
    }
  }
}

.listItemValues {
  .listItemValue {
    border-bottom: 1px solid $color-surface-lighter;
    display: flex;
    flex-direction: row;
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    justify-content: space-between;
    padding: $spacing 0;

    .valueName {
      flex: 1;
      margin-right: $spacing-half;
      color: $color-gray-dark;
    }

    .valueAmount {
      white-space: nowrap;
      flex-shrink: 0;
      color: $color-gray-darker;
    }
  }
}

.valueList {
  row-gap: 0;
}

.valueItem {
  white-space: pre-wrap;
}

.listButton {
  margin-top: $spacing-half;
  justify-content: right;
}

.emptyValuePlaceholder {
  height: 100%;
}
