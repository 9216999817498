.flexFlowNoWrap {
  flex-flow: row;

  > div {
    flex-flow: row;
  }
}

.removeTier {
  margin-top: -40px;

  .sprite {
    height: 15px;
    width: 15px;
  }
}
