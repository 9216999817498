@import 'styles/variables';

$spacing: 30px;

.noResults {
  align-items: center;
  background-color: $color-surface-lightest;
  color: $color-gray-dark;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: $spacing-2x;

  h2 {
    font-size: $font-size-25;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing;
  }

  p {
    font-size: $font-size-14;
  }

  button {
    border-radius: $border-radius;
    font-size: $font-size-14;
    height: 40px;
    margin-top: 40px;
    min-width: 180px;
    text-transform: capitalize;
  }
}

.noResultsGlyph {
  margin: 65px 0 $spacing;
  width: 250px;
  height: 200px;
  min-height: 200px;
}

.transparentBg {
  background-color: transparent;
}
