@import 'styles/variables';

.section {
  min-height: 0;
}

.scroll {
  height: auto;
  max-height: unset;
}
