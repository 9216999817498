@import 'styles/variables';

.authLayout {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 120px;
  background: linear-gradient($color-auth-light 50%, $color-auth-dark);
  overflow: auto;
}

.logoContainer {
  margin-bottom: 80px;
}

.logo {
  width: 80px;
  height: 66px;
}

.block {
  width: 450px;
}

.authContent {
  background: $color-auth-container-bg;
  border-radius: 8px;
  margin: 0 auto;
  padding: $spacing-3x;
}
