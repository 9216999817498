@import 'styles/variables';

.container {
  align-items: center;
  color: $color-white;
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  height: $spacing-4x;
  justify-content: space-between;
  padding: 0 $spacing;
  width: 100%;
}

.buttons {
  display: flex;
  align-items: center;
}

.dismissButton {
  height: 14px;
  margin-left: $spacing-2x;
  width: 14px;

  svg {
    fill: $color-white;
    height: 100%;
    width: 100%;
  }
}

.theme-blue {
  background: $color-blue;

  .button {
    background: $color-blue-dark;

    &:hover,
    &:active {
      background: $color-blue-darkest;
    }
  }
}

.theme-red {
  background: $color-red;

  .button {
    background: $color-red-dark;

    &:hover,
    &:active {
      background: $color-red-darkest;
    }
  }
}
