@import 'styles/variables';

.detailsLoading {
  margin: 10px auto 0;
}

.location {
  align-items: flex-start;
  display: flex;
  font-size: $font-size-12;
  line-height: 1.6;
  margin-bottom: 20px;
}

.sectionBanner {
  align-items: center;
  background: $color-surface-lighter;
  display: flex;
  height: 21px;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  justify-content: space-between;
  letter-spacing: $letter-spacing-big;
  margin-bottom: $spacing-3x;
  margin-left: -$spacing-3x;
  margin-right: -$spacing-3x;
  margin-top: -$spacing-3x;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: $spacing-2x;
  }

  .sectionName {
    margin-left: $spacing-3x;
  }

  .editButton {
    background: $color-surface-lighter;
    border-radius: 0;
    fill: $color-blue;
    margin-right: 22px;
    margin-top: -2px;
    height: 20px;
    width: 20px;
  }
}

.map {
  flex-shrink: 0;
  height: 100px;
  margin-right: 20px;
  width: 100px;
}

.estimate {
  font-size: $font-size-18;
  font-weight: $font-weight-bold;
  color: $color-green;
}

.companyName {
  font-size: $font-size-14;
  font-weight: $font-weight-bold;
}

.dropOffLocation {
  font-weight: $font-weight-regular;
}

.disclaimers {
  list-style: disc;
  margin-left: 10px;

  > li {
    line-height: $line-height;
    padding-bottom: $spacing-2x;
  }
}
