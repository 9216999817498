@import 'styles/variables';
@import 'styles/typography';

$base-padding: 12px;

.errorMessage {
  color: $color-red;
  padding-top: $base-padding;
}

.groupManagerList {
  max-height: 325px;
  overflow-y: auto;
}

.consignersSelect {
  margin: 25px 15px;
}

.consigners {
  margin: 0 0 10px;
}

.consigners,
.users {
  font-size: $font-size-11;
}

.consignerButton {
  @extend %ellipsis;
  line-height: 1.5;
  width: 100%;
  padding: 15px 25px;
  color: $color-gray-darker;
  font-size: 15px;
  font-weight: $font-weight-semibold;
  text-align: left;
  border-top: solid 1px $color-surface-lighter;
}
