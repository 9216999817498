@import 'styles/variables';

$card-border-radius: 4px;
$base-padding: 1.1rem;

.container {
  min-width: 385px;
  max-width: 750px;
}

.hero {
  background-color: $color-surface-lighter;
  position: relative;
}

.heroInner {
  position: relative;
  max-height: 400px;
}

.photoContainer {
  display: flex;
  overflow: hidden;
  height: 0;
  padding-bottom: 75%; // height ratio
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.photo {
  align-self: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  will-change: opacity;
}

.defaultVehicleIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}

.thumbnailsContainer {
  margin-top: $spacing;
}

.thumbnailList {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.justifyThumbnails {
    justify-content: space-between;
  }
}

.thumbnailListItem {
  width: calc(10% - 15px);
  height: 0;
  padding-top: calc(10% - 15px);
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: $spacing;
  }
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-surface-lighter;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $color-black-60-percent;
    color: white;
    font-size: $font-size-13;
  }

  &.addPhoto {
    > span {
      background: $color-white;
      border: 1px dashed $color-blue;
      border-radius: 4px;
    }

    svg {
      fill: $color-blue;
      width: 20px;
      height: 20px;
      border: 1px solid $color-blue;
      border-radius: 20px;
      padding: 5px;
    }
  }

  &.isDamagePhoto {
    border: 3px solid $color-red;
  }
}
