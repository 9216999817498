.container {
  height: 100%;
  position: relative;
  width: 100%;
}

.liveStreamBadge {
  left: 10px;
  position: absolute;
  top: 10px;
}
