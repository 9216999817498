@import 'styles/variables';

.amount {
  color: $color-green;
  font-size: $font-size-32;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-3x;
}

.instructions {
  color: $color-gray-dark;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  line-height: $line-height-atom;
  margin-bottom: $spacing-2x;
}

.message {
  margin-top: 0;
}
