@import 'styles/variables';

.listItem {
  display: grid;
  grid-template-rows: auto 1fr;
  margin-bottom: $spacing-2x;

  > *:last-child {
    padding: 0;
  }
}

.container {
  display: grid;
  grid-template-columns: minmax(0, 62%) minmax(450px, 38%);
  min-height: 159px;
}

.left {
  display: flex;
  justify-content: space-between;
  padding: 0 $spacing-3x 0 $spacing-2x;
}

.right {
  display: flex;
  width: 100%;
}
