@import 'styles/variables';

.sprite {
  width: 25px;
  height: 25px;
}

.transparent-white {
  // TODO: Remove
  fill: rgba($color-white, 0.75);
}

.solid-white {
  // TODO: Remove
  fill: $color-white;
}
