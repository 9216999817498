@import 'styles/variables';

$default-icon-button-size: 40px;
$default-icon-size: 24px;

.iconButton {
  background-color: $color-gray-light-50-percent;
  border-radius: 50%;
  border: none;
  color: $color-white;
  fill: $color-white;
  font-size: $font-size-24;
  font-weight: $font-weight-extrabold;
  height: var(--icon-button-size, $default-icon-button-size);
  line-height: 15px;
  min-width: var(--icon-button-size, $default-icon-button-size);
  padding: 0;
  width: var(--icon-button-size, $default-icon-button-size);

  &.active,
  &:hover {
    background-color: darken($color-gray-light-50-percent, 8%);
  }
}

.sprite {
  height: var(--icon-size, $default-icon-size);
  width: var(--icon-size, $default-icon-size);
}
