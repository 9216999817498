@import 'styles/variables';

.container {
  padding: 45px 25px;
  color: $color-gray-darkest;
  overflow: hidden;

  .headerText {
    color: $color-gray-darker;
  }

  p,
  h4 {
    margin-bottom: 25px;
  }

  h4 {
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing-big;
  }
}

.containerScroll {
  overflow-y: auto;
}
