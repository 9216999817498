@import 'styles/variables';

// ======================================================
// LOADING ----------------------------------------------

.spinnerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinnerText {
  color: $color-blue;
  margin-top: 30px;
  font-size: $font-size-13;
  font-weight: $font-weight-semibold;
}

.loaderModal {
  width: 200px;
  height: 160px;
  justify-content: center;
  align-items: center;
}

.loaderOverlay {
  background-color: rgba(255, 255, 255, 0.8) !important; // stylelint-disable-line
  z-index: $zIndex1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.loaderOverlayWithFullWidth {
  width: 100%;
}

.modalDialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 0 none;
  background-color: $color-white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: $block-border-radius;
  text-align: center;
}

.loadedModalTransparent {
  background-color: transparent !important; // stylelint-disable-line
  box-shadow: none;
}

// ======================================================
// SPINNER ----------------------------------------------

/* based on http://tobiasahlin.com/spinkit/ */
@keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    border-radius: 100%;
    animation: circleBounceDelay 1.2s infinite ease-in-out both;
  }
}

.loadingSpinner {
  width: 25px;
  height: 25px;
  position: relative;
  will-change: transform;

  &.blue .child::before {
    background-color: $color-blue;
  }

  &.red .child::before {
    background-color: $color-red;
  }

  &.green .child::before {
    background-color: $color-green;
  }

  &.black .child::before {
    background-color: $color-black-60-percent;
  }

  &.white .child::before {
    background-color: $color-white;
  }

  &.gray .child::before {
    background-color: $color-black-50-percent;
  }

  &.lightGray .child::before {
    background-color: $color-surface-lighter;
  }

  &2 {
    transform: rotate(30deg);

    &::before {
      animation-delay: -1.1s;
    }
  }

  &3 {
    transform: rotate(60deg);

    &::before {
      animation-delay: -1s;
    }
  }

  &4 {
    transform: rotate(90deg);

    &::before {
      animation-delay: -0.9s;
    }
  }

  &5 {
    transform: rotate(120deg);

    &::before {
      animation-delay: -0.8s;
    }
  }

  &6 {
    transform: rotate(150deg);

    &::before {
      animation-delay: -0.7s;
    }
  }

  &7 {
    transform: rotate(180deg);

    &::before {
      animation-delay: -0.6s;
    }
  }

  &8 {
    transform: rotate(210deg);

    &::before {
      animation-delay: -0.5s;
    }
  }

  &9 {
    transform: rotate(240deg);

    &::before {
      animation-delay: -0.4s;
    }
  }

  &10 {
    transform: rotate(270deg);

    &::before {
      animation-delay: -0.3s;
    }
  }

  &11 {
    transform: rotate(300deg);

    &::before {
      animation-delay: -0.2s;
    }
  }

  &12 {
    transform: rotate(330deg);

    &::before {
      animation-delay: -0.2s;
    }
  }
}

.spinnerCentered {
  width: 100%;

  .loadingSpinner {
    margin: 0 auto;
  }
}

.spinnerCenteredDetails {
  margin-top: 20px;
  min-width: 385px;
  max-width: 750px;
}
