@import 'styles/variables';

.chooseFieldHeader {
  font-size: $font-size-12;
  margin-bottom: 25px;
}

.chooseFieldRows {
  display: block;
  width: 100%;
}

.chooseFieldRow {
  font-size: $font-size-12;
}

.addTier {
  background: #f8f9fa;
  border-radius: 5px;
  font-size: 12px;
  color: $color-black;
  letter-spacing: 0;
  line-height: 11.21px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 43px;

  .sprite {
    margin-right: 14px;
    fill: #00bc9a;
    width: 15px;
    height: 15px;
  }
}
