@import 'styles/variables';

$custom-line-height: 12px;

.clearFilterSprite {
  width: 12px;
  height: 12px;
  color: $color-blue;
  fill: $color-blue;
  vertical-align: middle;
}

.datePicker {
  :global {
    .DateRangePickerInput_clearDates {
      margin: 0;
    }

    .DateRangePickerInput__showClearDates {
      padding-right: 0;
    }

    .DateRangePickerInput_calendarIcon {
      position: absolute;
      top: calc(50% - 6px);
      right: 12px;
      padding: 0;
      margin: 0;
      z-index: $zIndex1;
    }

    // Need this parent class to override global styles
    .DateRangePicker {
      z-index: $zIndex2;
      flex-grow: 1;
      background-color: transparent;

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .DateInput {
        width: 135px;
      }
    }

    .DateRangePickerInput {
      display: flex;
      position: relative;
      border-radius: $block-border-radius;
      border: 1px solid $color-gray-lighter;
    }

    .DateInput {
      background: transparent;
      width: 272px;
    }

    .DateInput_input {
      font-size: $font-size-11;
      font-weight: $font-weight-regular;
      line-height: $custom-line-height;
      color: $color-gray-darkest;
      border: none;
      border-radius: $block-border-radius;
      cursor: pointer;

      &::placeholder {
        color: $color-black-50-percent;
      }
    }

    .DateRangePicker_picker {
      top: 53px !important; // stylelint-disable-line
    }

    .DateInput__openDown {
      &::before {
        top: 47px;
      }

      &::after {
        top: 48px;
      }
    }

    .DateInput_fang {
      top: 42px !important; // stylelint-disable-line
    }

    .DateInput_input__focused {
      border: none;
    }

    .DayPicker__horizontal {
      position: absolute;
    }

    .DayPicker_weekHeader {
      top: 55px;
    }

    .DayPicker_weekHeader_ul {
      display: flex;
    }

    .DayPicker_weekHeader_li {
      justify-content: center;
    }

    .DayPickerNavigation_svg__horizontal {
      width: 13px;
      height: 13px;
    }

    .CalendarMonth_caption {
      padding-top: 27px;
    }

    .CalendarMonth_caption_1 {
      font-size: 0.7rem;
      color: $color-gray-light;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .CalendarDay__selected {
      background: $color-green;
      border-color: $color-green;
    }

    .DateRangePickerInput_arrow {
      display: none;
    }
  }
}
