@import 'styles/variables';
@import 'styles/typography';

.featureList {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.featureListItem {
  position: relative;
  width: 25%;
  border-bottom: 1px solid rgba($color-line, 0.3);
  user-select: text;

  &:nth-last-child(-n + 3) {
    border: none;
  }

  &.isRequired {
    p,
    .featureTitle {
      color: $color-red;
    }

    svg {
      fill: $color-red;
    }
  }
}

.featureListItemText {
  text-align: left;
  padding: 20px 15px;
  font-weight: normal;
  font-size: 12px;

  p {
    @extend %ellipsis;
    padding-right: 5px;
  }

  .featureTitle {
    color: $color-gray-dark;
    margin-bottom: $spacing;
  }

  .featureValue {
    color: $color-gray-darkest;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
  }

  .crEditButton {
    top: 20px;
  }

  &.disabled {
    cursor: default;
  }

  &.emptyFeatureValue {
    p {
      color: $color-blue;
    }
  }

  &.leftColumn {
    padding-left: 0;
  }

  &.rightColumn {
    &::after {
      display: none;
    }
  }

  &::after {
    background: rgba($color-line, 0.3);
    content: '';
    height: 35px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
}

.crEditButton {
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    width: 12px;
    height: 12px;
    fill: $color-blue;
  }
}

.isRequired {
  > button,
  > div {
    color: $color-red;
  }

  + .editButton,
  .editButton {
    background: $color-red;
    color: $color-white;

    &:hover {
      background: $color-red-dark;
    }
  }
}
