@import 'styles/variables';

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px $spacing;
  line-height: 17px;

  svg {
    width: 6.5px;
    padding: 0;
  }

  span {
    font-size: $font-size-11;
    text-align: center;
    flex-grow: 1;
  }
}

.link {
  visibility: hidden;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: $color-white;
  font-size: $font-size-11;
  padding: 0 10px;
  font-weight: $font-weight-semibold;
  color: $color-gray-darkest;
  border: 1px solid $color-input-border;

  &.previous {
    visibility: visible;

    svg {
      margin-right: $spacing;
      transform: rotate(180deg);
    }
  }

  &.next {
    visibility: visible;

    svg {
      margin-left: $spacing;
    }
  }
}
