@import 'styles/variables';

.content {
  padding: $spacing-3x;
}

.listItem {
  font-size: $font-size-11;
  font-weight: $font-weight-regular;
  padding: 12px 0;

  &:first-child {
    padding-top: 0;
  }

  border-bottom: 1px solid $color-surface-lighter;
}

.itemContainer {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 1fr 15px;
  grid-template-rows: auto;
}

.itemInfo {
  color: $color-gray-dark;
  padding-top: 5px;
}

.warning {
  color: $color-red;
  width: 15px;
}
