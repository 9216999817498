@import 'styles/variables';
@import 'styles/typography';

.badge {
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  padding: 7px 12px 6px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  min-width: auto;
  min-height: auto;
  text-transform: none;
  fill: $color-black;

  span {
    max-width: 225px;

    @extend %ellipsis;
  }

  &:hover {
    background: none;
    fill: $color-black;
  }

  &.static {
    cursor: default;
  }

  &.theme {
    &-default,
    &-link {
      color: $color-gray-light;
      border: 1px solid $color-gray-lighter;
    }

    &-green {
      border: 1px solid $color-green;

      & > span {
        color: $color-green;
      }
    }

    &-orange {
      border: 1px solid $color-yellow;

      & > span {
        color: $color-yellow;
      }
    }

    &-red {
      border: 1px solid $color-red;

      & > span {
        color: $color-red;
      }
    }

    &-red-solid {
      background: $color-red;
      border: 1px solid $color-red;
      font-weight: $font-weight-semibold;

      & > span {
        color: $color-white;
      }
    }

    &-square {
      border: 1px solid $color-black;
      border-radius: 5px;
      font-weight: $font-weight-semibold;

      & > span {
        color: $color-black;
      }
    }
  }

  &:disabled {
    border-color: $color-gray-lighter;
    background: none;
  }
}

.glyph {
  padding: 0;
  height: 20px;
  width: 20px;

  > svg {
    padding: 5px 0;
    width: 100%;
    height: 100%;
  }
}

.chevron {
  width: 7px;
  height: 8px;
  margin-left: 7px;
  fill: $color-gray-light;
}
