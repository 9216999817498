@import 'styles/variables';

.loading {
  margin: 10px auto 0;
}

.laneRunNumberInputs {
  display: grid;
  gap: $spacing;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  .laneRunNumberInput {
    height: 46px;
    width: 100%;
  }
}

.disclaimer {
  margin-bottom: 0;
}
