@import 'styles/variables';

.listContainer {
  height: calc(100vh - var(--system-navigation-height-full) - 61px - 45px);
  position: relative;
}

.list {
  height: calc(100vh - var(--system-navigation-height-full) - 61px - 45px - 85px);
  overflow: auto;
}

.footer {
  background: $color-white;
  border: none;
}

.addButton {
  margin: 0;
  width: 100%;
}
