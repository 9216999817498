@import 'styles/variables';

.container {
  padding: $spacing $spacing-3x;
}

.list {
  li:not(:last-child) {
    margin-bottom: $spacing * 1.5;
  }
}

.listItem {
  border-bottom: 1px solid $color-surface-lighter;
  margin-bottom: $spacing;
}

.message {
  font-size: $font-size-14;
  text-align: center;
}

.refreshButton {
  width: 100%;
}
