@import 'styles/variables';
@import './conditionReport';

.crButton {
  &.noFlyout .chevron {
    display: none;
  }

  &.isLegacy {
    cursor: default;
  }

  &.isLegacy .chevron {
    display: none;
  }

  &.crButtonStatic {
    cursor: default;

    .chevron {
      display: none;
    }
  }

  .crButtonText {
    color: $color-gray-darkest;
    display: block;
    font-size: 1.125rem; // 18px
    font-weight: $font-weight-bold;

    &.isLegacy {
      margin-bottom: 5px;
    }
  }
}

.crQuestionGlyph {
  background-color: $color-gray-lighter;
  border-radius: 50%;
  display: block;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;

  > svg {
    height: 8px;
    margin-top: 3px;
    pointer-events: none;
    width: 14px;
  }
}

.autoGradeText {
  margin-top: $spacing-half;
}

.autoGradeImage {
  height: auto;
  width: 80px;
}

.damageStatus {
  display: flex;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;

  .hasDamage {
    color: $color-red;
  }

  .noDamage {
    color: $color-green;
  }
}
