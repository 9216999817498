@import 'styles/variables';
@import './facetGroups';

.facetGroup {
  margin-bottom: 25px;

  > .heading {
    margin-top: 0;
  }

  .facet {
    margin: 7px 0;
    min-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      color: $color-black;
      font-size: $font-size-12;
      text-decoration: underline;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .count {
      font-size: $font-size-9;
      min-width: 18px;
      height: 18px;
      padding: $spacing-half;
      line-height: 10px;
      border-radius: 5px;
      color: $color-white;
      text-align: center;
      font-weight: $font-weight-semibold;
      background-color: $color-blue;

      &.themered {
        background-color: $color-red;
      }

      &.themegreen {
        background-color: $color-green;
      }

      &.count0 {
        background-color: $color-gray-lighter;
      }
    }

    &.changed {
      .count.themered,
      .count.themegreen {
        animation-name: bulge;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        transform-origin: center;
        animation-fill-mode: forwards;
        will-change: transform;
      }
    }
  }
}
