@import 'styles/variables';

.liveStreamBadge {
  align-items: center;
  background: $color-green-lightest;
  border-radius: $border-radius;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 3px 6px;

  .sprite {
    color: $color-green;
    height: 12px;
    width: 12px;
  }
}
