@import 'styles/variables';

.content {
  right: -$list-results-width;
  width: $list-results-width !important; // stylelint-disable-line
}

.itemsList {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: $color-surface-lighter;

  li:not(:last-child) {
    margin-bottom: 6px;
  }
}

.sortGlyph {
  height: 20px;
  width: 20px;
}
