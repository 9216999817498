@import 'styles/variables';

$base-padding: 1.1rem;
$details-height: calc(100vh - var(--system-navigation-height-full));

.details {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
  background-color: $color-white;
  width: 100%;
  min-width: 0;
  position: relative;
}

.header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: $search-bar-height + 1;
  width: 100%;
  background-color: $color-surface-lightest;
  border-bottom: 1px solid $color-input-border;
  padding: 0 $spacing-3x;

  .title {
    text-transform: uppercase;
    font-size: $font-size-15;
    letter-spacing: 1px;
  }
}

.contentWrapper {
  align-self: stretch;
  position: relative;
  height: $details-height;
  // always keep scroll to avoid resizing when loading a new car
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.content {
  min-width: 385px;
  max-width: 750px;
  padding: $spacing-3x;
}

.menu {
  top: 85px;
}

.menuDots {
  cursor: pointer;
  margin-left: auto;

  svg {
    width: 19px;
  }
}

.detailsLoader {
  margin: 0 auto;
}

.section {
  position: relative;
  display: block;
  border-top: solid 1px rgba($color-line, 0.3);
  padding: 18px $spacing-3x;
}
