@import 'styles/variables';

.legalSaleDate {
  align-items: center;
  display: flex;
  font-weight: $font-weight-semibold;
  justify-content: space-between;
  width: 50%;

  .value {
    align-items: center;
    display: flex;
  }
}
