@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  &.solid {
    background-color: $color-white;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba($color-black, 0.25);
    overflow: hidden;

    & > div[role='heading'] {
      background-color: $color-surface-lightest;
      border-bottom: 1px solid $color-gray-lighter;
      margin-bottom: 0;
      padding: $spacing-2x $spacing-2x $spacing;
    }
  }
}
