@import 'styles/variables';
@import 'styles/typography';

.container {
  > div:first-child {
    // vehicle image
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }
}

.title {
  width: auto;
  flex-shrink: 0;
}

.primaryTitle {
  font-size: 1rem; // 16px
}

.subTitles {
  @extend %ellipsis;
  align-items: baseline;
  margin-top: -3px;

  > div:not(:last-child) {
    @extend .title;
  }
}

.paymentMethod {
  margin-right: 1ch;
}

.expiryDate {
  &:not(:only-child) {
    margin-left: 15px;
  }
}

.columnMiddle {
  margin-right: 10px;
  min-width: 0;
}

.columnRight {
  text-align: right;
  flex-shrink: 0;
  margin-left: auto;
}

.editButton {
  margin-left: auto;
}
