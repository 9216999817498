@import 'styles/variables';

$col-spacing: 20px / 3;

.vehicleDetails {
  padding-top: 42px;
}

.segment {
  position: relative;
  padding: 25px;
  border-bottom: 1px solid $color-surface-lighter;

  &:last-child {
    border-bottom: none;
  }
}

.segmentLayout {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.segmentLayoutCustom {
  justify-content: flex-start;
}

.segmentDisabled {
  opacity: 0.4;
  pointer-events: none;
}

.disabled {
  color: $color-surface-lighter;
}

.title {
  color: $color-black-50-percent;
}

.topRow {
  margin-bottom: 13px;
}

.paddingBottom {
  padding-bottom: 25px;
}

.paddingBottomCustom {
  padding-bottom: 15px;
}

.paddingBottomNone {
  padding-bottom: 0;
}

.paddingCustom {
  padding: 25px 15px 15px 25px;
}

.column {
  position: relative;
  width: calc(100% / 3 - #{$col-spacing});
  margin: 0;
}

.vinSection {
  margin-right: 10px;
}

.columnCustom {
  margin-right: 10px;
}

.section {
  width: 100%;
  margin: 0;
}

.inputClass {
  width: 100%;
  margin: 0;
  height: $input-height;
}

.inputTextClass {
  border-radius: $block-border-radius;
}

.inputContainer {
  display: flex;
  position: relative;
  justify-content: space-between;
  border: 1px solid $color-gray-lighter;
  border-radius: $block-border-radius;
  background: $color-white;
  width: 100%;
  margin: 0;
}

.inputCustom {
  border: none;
  height: $input-height;
}

.inputTextCustom {
  height: $input-height;
}

.litreText {
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: $zIndex1;
  font-weight: $font-weight-extrabold;
  color: $color-gray-lighter;
}

.innerButtons {
  padding-right: 5px;

  > label {
    width: 28px;
  }
}

.innerButtonsText {
  width: 100%;
  align-items: center;
}

.textRadioButtons {
  font-size: $font-size-8;
  font-weight: $font-weight-extrabold;
  padding: 1px 0 0 1px;
  fill: $color-white;
}

.placeholder {
  color: $color-black-50-percent;
}

.menuClass {
  max-height: 170px;
}

.errorMessageContainer {
  display: flex;
  flex-direction: column;
  margin: 0 0 25px;
  padding: 15px;
  background: $color-red-lightest;
  border: 1px solid $color-red;
  color: $color-red;
  border-radius: $border-radius;
  text-align: center;
  font-size: $font-size-12;
  line-height: $line-height;
}

.highlighted {
  background: $color-blue-lighter;
}

.checkbox {
  margin-top: 0;
}

.labelClassName {
  color: $color-black;
}
