@import 'styles/variables';

.dialogHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--system-navigation-height-single);
  background: $color-gray-darkest;
  padding: 0 25px;
}

.closeButton {
  font-size: $font-size-34;
  line-height: initial;
  font-weight: $font-weight-regular;
  color: $color-white;
  margin: 0;
  background: none;
  border: none;
}

.headerTitle {
  color: $color-white;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-medium;
  text-transform: uppercase;
  line-height: initial;
}

.dialogBody {
  height: calc(100% - #{$form-footer-height});
  overflow: auto;
}

.dialogFooter {
  align-items: center;
  border-top: 1px solid $color-gray-lighter;
  bottom: 0;
  display: flex;
  gap: $spacing-half-3x;
  height: $form-footer-height;
  justify-content: flex-end;
  left: 0;
  padding: 0 20px;
  position: absolute;
  width: 100%;
}

.formErrors {
  display: flex;
  flex-direction: column;
  margin: 25px 20px 0;
  padding: 15px;
  background: $color-red-lightest;
  border: 1px solid $color-red;
  color: $color-red;
  border-radius: $border-radius;
  text-align: center;
  font-size: $font-size-12;
  line-height: $line-height;
  word-break: break-word;
}

.formErrorsLink {
  color: $color-red;

  &:active,
  &:hover {
    color: $color-red;
  }
}

.formErrorsSmallDialog {
  margin: 0 0 25px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  border-top: 1px solid $color-gray-lighter;
  color: $color-gray-darkest;
  padding: 25px;
  position: relative;

  h3 {
    display: flex;
    width: 100%;
    text-align: center;
    color: $color-gray-darkest;
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    opacity: 0.4;
    margin-bottom: 10px;
  }

  &:first-of-type {
    border-top: none;
  }

  & + .sectionUnified:not(.sectionUnifiedWithoutNegativeMargin) {
    margin-top: -25px;
  }
}

.sectionUnified {
  border-top: none;
  padding-top: 0;

  & + .sectionUnified:not(.sectionUnifiedWithoutNegativeMargin) {
    // Remove negative margin if 2 .sectionUnified elements are siblings
    margin-top: 0;
  }
}

.sectionMultiLine > .sectionChildren {
  justify-content: left;
  margin: 0 -20px -20px 0;

  > div {
    margin: 0 20px 20px 0;
  }
}

.sectionChildren {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  &.sectionChildrenColumn {
    flex-direction: column;
  }
}

.sectionNumber {
  position: absolute;
  top: $spacing-2x;
  right: $spacing;
  font-size: $font-size-11;
  font-weight: $font-weight-bold;
  color: $color-surface-lighter;
}
