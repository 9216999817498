@import 'styles/variables';

.button {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: $color-gray-lightest;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-gray-darker;
  border: none;
  outline: none;

  &:hover {
    opacity: 0.85;
  }
}

.sprite {
  width: 12px;
  height: 16px;
}
