@import 'styles/variables';
@import 'styles/typography';

.listItem {
  background-color: $color-white;
  list-style: none;
  margin-bottom: 7px;
  padding: 0;
  width: 100%;

  .listItemLink,
  .listItemContent {
    padding-bottom: 10px;

    & > *:last-child {
      padding-bottom: 0;
    }
  }

  .listItemSelected {
    background-color: $color-green-lightest;
    box-shadow: inset 0 0 0 2px $color-green;
  }
}

.listItemLink {
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: $color-surface-lightest;
  }

  &.listItemLinkActive {
    background-color: $color-green-lightest;
  }

  &.listItemSelectedActive {
    background-color: $color-green-lightest;
  }
}

.listItemBody {
  @extend %ellipsis;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  padding: 3px 0;
  position: relative;
}

.header {
  @extend .type-body-4;
  align-items: center;
  border-bottom: 1px solid $color-gray-lighter;
  color: $color-gray-dark;
  display: flex;
  font-size: $font-size-12;
  margin: 0 15px 7px;
  white-space: nowrap;
}

.headerLabel {
  margin-right: auto; // Fill entire available space
  padding-right: 15px;
}

.headerSubLabel {
  text-align: right;
}

.labelEllipsis {
  @extend %ellipsis;
  flex: 0 1 auto;
}

.headerButton {
  color: $color-red;
  font-weight: $font-weight-bold;

  &:hover {
    color: $color-red-dark;
  }
}

.title {
  @extend %ellipsis;
  width: 100%;

  &:empty {
    display: none;
  }
}

.primaryTitle {
  composes: title;
  color: $color-gray-darkest;
  font-weight: $font-weight-bold;
  font-size: $font-size-18;
  line-height: $line-height-medium;
  margin: 0;
}

.primaryTitleLight {
  composes: primaryTitle;
  color: $color-text;
  font-size: $font-size-15;
  font-weight: $font-weight-regular;
}

.primaryTitleWithStatus {
  display: flex;
  justify-content: space-between;
}

.primaryTitleStatus {
  composes: primaryTitle;
  color: $color-red;
  flex-shrink: 0;
  padding-left: 15px;
  width: auto;
  text-transform: uppercase;

  &.theme-green {
    color: $color-green;
  }
}

.secondaryTitle {
  composes: title;
  color: $color-gray-darkest;
  font-weight: $font-weight-semibold;
  font-size: $font-size-12;
  line-height: 14px;
  margin: 0;
}

.secondaryTitleLight {
  composes: secondaryTitle;
  font-weight: $font-weight-regular;
}

.tertiaryTitle {
  composes: title;
  color: $color-gray-dark;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: 1.7;
  width: 100%;
}

.tertiaryTitleLight {
  composes: tertiaryTitle;
  font-weight: $font-weight-regular;
}

.listItemStatusTag {
  opacity: 1;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: translateY(-50%);
  text-transform: uppercase;
}

.listItemFooter {
  border-top: 1px solid $color-gray-lighter;
  display: block;
  margin: 5px 15px 0;
  padding: $spacing 0 0;
  width: calc(100% - 30px);

  &:empty {
    display: none;
  }
}

.listItemWithUserImage {
  display: flex;

  > div:first-child {
    // user image
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }
}

.listItemWithUserImageBody {
  // TODO
}
