/**
 * The default theme can be overwritten with Private Label styles which are found in:
 * `src/privateLabels/<PRIVATE_LABEL_NAME>/variables.scss`
 *
 * These 'override' styles are implicitly imported via our Webpack config.
 * @see sass-loader configuration in scripts/webpack.dev.js
 */
@function get-from-theme($key, $default) {
  $theme: () !default;
  $value: map-get($theme, $key);

  @return if($value, $value, $default);
}

// TODO: Convert all units to follow these rules:
//        - Font size (rem: 0.567rem;)
//        - Line height (number: 0.4)
//        - use px for everything else

// ============================================================
// COLORS -----------------------------------------------------

$color-black: #1d2126;
$color-black-10-percent: rgba($color-black, 0.1);
$color-black-50-percent: rgba($color-black, 0.5);
$color-black-60-percent: rgba($color-black, 0.6);
$color-blue-dark: #0c65a7;
$color-blue-darker: #0a5186;
$color-blue-darkest: #094877;
$color-blue-light: #d7e8f5;
$color-blue-lighter: #ebf4fa;
$color-blue-lightest: #e7f0f6;
$color-blue: get-from-theme('color-blue', #378ccb);
$color-gray-dark: #5b656d;
$color-gray-darker: #313d48;
$color-gray-darkest: #24292f;
$color-gray-light: #adb2b6;
$color-gray-light-50-percent: rgba($color-gray-light, 0.5);
$color-gray-lighter: #d6d8da;
$color-gray-lightest: #ebeced;
$color-green-dark: #019682;
$color-green-darker: #017868;
$color-green-darkest: #016356;
$color-green-light: #beeae0;
$color-green-lighter: #d4f1eb;
$color-green-lightest: #e9f8f5;
$color-green: #26b899;
$color-red-dark: #b5594e;
$color-red-darker: #a35046;
$color-red-darkest: #93463d;
$color-red-light: #f8d4d1;
$color-red-lighter: #fae2e1;
$color-red-lightest: #fdf1f0;
$color-red: #e76f67;
$color-white: #fff;
$color-white-50-percent: rgba(#fff, 0.5);
$color-yellow-dark: #e2a950;
$color-yellow-darker: #c5974f;
$color-yellow-darkest: #c49145;
$color-yellow-light: #fbe1b9;
$color-yellow-lighter: #f9eedc;
$color-yellow-lightest: #fff9ef;
$color-yellow: #f6b551;

// Colors outside of our main color palette (Used for vehicle colors, status badges, etc)
$color-swatch-brown: #8b572a;
$color-swatch-green: #417505;
$color-swatch-purple-light: #f7e2ff;
$color-swatch-purple: #7311c9;
$color-swatch-red: #d0021b;
$color-swatch-tan: #aa917a;
$color-swatch-teal: #37ccaa;
$color-swatch-yellow: #ffe679;

// Common Elements / Sections
$color-surface-light: #e4e4e4;
$color-surface-lighter: #eaeaea;
$color-surface-lightest: #f8f8f8;
$color-auth-container-bg: get-from-theme('color-auth-container-bg', $color-green-dark);
$color-auth-dark: get-from-theme('color-auth-dark', #0f977b);
$color-auth-light: get-from-theme('color-auth-light', #1bbb9b);
$color-input-border: $color-gray-lighter;
$color-line-light: $color-gray-lightest;
$color-line: $color-gray-lighter;
$color-nav-text: get-from-theme('color-nav-text', #7c7f82);
$color-text: $color-gray-darkest;
$color-top-nav: get-from-theme('color-top-nav', $color-gray-darkest);

// ============================================================
// TYPOGRAPHY -------------------------------------------------

$font-family: 'Proxima Nova';
$font-weight-thin: 200;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

// --------------------
// NOTE: Please consult team before adding additional font-size's

$base-font-size: 100%; // Should be 16px unless user has set browser font-size
$font-size-8: 0.5rem; // 8px
$font-size-9: 0.5625rem; // 9px
$font-size-10: 0.625rem; // 10px
$font-size-11: 0.6875rem; // 11px
$font-size-12: 0.75rem; // 12px
$font-size-13: 0.8125rem; // 13px
$font-size-14: 0.875rem; // 14px
$font-size-15: 0.9375rem; // 15px
$font-size-18: 1.125rem; // 18px
$font-size-20: 1.25rem; // 20px
$font-size-22: 1.375rem; // 22px
$font-size-24: 1.5rem; // 24px
$font-size-25: 1.5625rem; // 25px
$font-size-32: 2rem; // 32px
$font-size-34: 2.125rem; // 34px
$font-size-36: 2.25rem; // 36px
$font-size-40: 2.5rem; // 40px
$font-size-48: 3rem; // 48px
$font-size-50: 3.125rem; // 50px
$font-size-66: 4.125rem; // 66px
$font-size-110: 6.875rem; // 110px
$font-size-128: 8rem; // 128px

$line-height-xsmall: 15px;
$line-height-small: 14.5pt;
$line-height-atom: 16px;
$line-height: 16pt;
$line-height-medium: 1.5em;
$line-height-large: 1.9em;

$letter-spacing-huge: 2px;
$letter-spacing-big: 1px;
$letter-spacing-medium: 0.6px;
$letter-spacing: 0.3px;
$letter-spacing-small: 0.1px;

$link-color: $color-blue;
$link-hover-color: $color-blue-dark;

// ============================================================
// Z-INDEX  ---------------------------------------------------

// Common z-index values
$zIndexNeg1: -1;
$zIndex0: 0;
$zIndex1: 1;
$zIndex2: 2;
$zIndex3: 3;
$zIndex4: 4;
$zIndex5: 5;

// Element specific z-index values
$zIndexDialog: $zIndex4;
$zIndexNavigationContainer: $zIndex4;
$zIndexOverDialog: $zIndex5;

// ============================================================
// SPACING ----------------------------------------------------

$spacing: 8px;
$spacing-2x: #{2 * $spacing};
$spacing-3x: #{3 * $spacing};
$spacing-4x: #{4 * $spacing};
$spacing-5x: #{5 * $spacing};
$spacing-6x: #{6 * $spacing};
$spacing-8x: #{8 * $spacing};
$spacing-half: #{$spacing / 2};
$spacing-half-3x: #{$spacing / 2 * 3};
$spacing-quarter: #{$spacing / 4};
$block-border-radius: 3px;
$border-radius: 5px;
$border-radius-small: 4px;
$box-shadow: rgba($color-black, 0.04);
$line-height-norm: 16px;
$container-padding: 20px;
$border-active: 2px;
$input-vertical-padding: 10px;
$input-horizontal-padding: 12px;
$input-padding: $input-vertical-padding $input-horizontal-padding;
$input-height: 34px;
$search-bar-height: 60px;
$sidebar-and-list-width: 730px;
$slideOut-width: 410px;
$facets-width: 215px;
$edit-btn-size: 16px;
$list-results-width: 440px;
$list-container-width: $list-results-width + $facets-width;
$details-min-width: 385px;
$details-max-width: 750px;
$form-footer-height: 85px;
$full-dialog-header-height: 45px;
$full-dialog-footer-height: 40px;
$full-dialog-large-footer-height: 85px;
$triangle-width: 5px;
$anchor-tabs-height: 42px;
$header-compact-height: 117px;
$header-full-height: 154px;
$header-live-lanes-height: 48px;

// Responsive breakpoints
$mobile: 320px;
$phablet: 550px;
$tablet: 768px;
$desktop: 1024px;
$wide: 1280px;
$wider: 1441px;
$widest: 1536px;

/* stylelint-disable property-case */
/* stylelint-disable property-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */

// ============================================================
// EXPORTED MODULES -------------------------------------------

// To style some of our 3rd party libraries without scss support (ReactSelect),
// some properties need to be exported as modules:

:export {
  // COLOR
  colorBlack: $color-black;
  colorBlackTransparent: $color-black-50-percent;
  colorBlue: $color-blue;
  colorBlueDark: $color-blue-dark;
  colorBlueLighter: $color-blue-lighter;
  colorGrayDark: $color-gray-dark;
  colorGrayDarker: $color-gray-darker;
  colorGrayDarkest: $color-gray-darkest;
  colorGrayLight: $color-gray-light;
  colorGrayLighter: $color-gray-lighter;
  colorGrayLightest: $color-gray-lightest;
  colorGreen: $color-green;
  colorGreenLightest: $color-green-lightest;
  colorRed: $color-red;
  colorRedLightest: $color-red-lightest;
  colorText: $color-text;
  colorWhite: $color-white;
  colorYellow: $color-yellow;
  // FONT WEIGHT
  fontWeightThin: $font-weight-thin;
  fontWeightRegular: $font-weight-regular;
  fontWeightSemiBold: $font-weight-semibold;
  fontWeightBold: $font-weight-bold;
  // FONT SIZE
  fontSize10: $font-size-10;
  // Z-INDEX
  zIndexNeg1: $zIndexNeg1;
  zIndex0: $zIndex0;
  zIndex1: $zIndex1;
  zIndex2: $zIndex2;
  zIndex3: $zIndex3;
  zIndex4: $zIndex4;
  zIndex5: $zIndex5;
}
