@import 'styles/variables';
@import './statusRows/statusRows';

.liveAppraisal {
  @extend %status;
}

.isWinning {
  color: $color-green;
}

.isLosing {
  color: $color-red;
}

.autobidAmount {
  color: $color-blue;
}
