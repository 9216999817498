@import 'styles/variables';

.progressBarContainer {
  background: $color-gray-lighter;
  width: 200px;
  border-radius: $border-radius;
  overflow: hidden;
}

.progressBar {
  background: $color-green;
  transition: width 200ms;
  height: 15px;
}
