@import 'styles/variables';

$base-padding: 12px;

.ratings {
  padding: $base-padding 0;
}

.errorMessage {
  color: $color-red;
  padding-top: $base-padding;
}

.dealershipSetting {
  em {
    font-style: normal;
    color: $color-green;
  }

  margin-bottom: $base-padding;
}

.checkboxContainer {
  margin-top: 0;
}

.inputContainer {
  margin-top: 20px;
  width: 100%;
  position: relative;

  .inputText {
    width: 100%;

    .input {
      border-color: $color-gray-lighter;

      &::placeholder {
        color: $color-gray-dark;
      }
    }
  }
}

.dollar,
.percent {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 3px;
  min-width: 28px;
  min-height: 24px;
  padding: 0;
  font-size: 13px;
  font-weight: $font-weight-extrabold;
}

.dollar {
  right: 38px;
}
