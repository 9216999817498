@import 'styles/variables';

.input,
.button {
  align-items: center;
  border-radius: $border-radius;
  display: flex;
  font-size: $font-size-14;
  justify-content: center;
  letter-spacing: $letter-spacing-small;
  width: 100%;
}

.errors {
  margin: 0 0 $spacing-3x;
  width: 100%;
}

.authForm {
  margin: 0 auto;
  padding-bottom: $spacing;

  .header {
    font-size: $font-size-22;
    font-weight: $font-weight-bold;
    line-height: 28px;
    margin: 0 0 $spacing-2x;
    color: $color-white;
  }

  .subHeader {
    font-size: 1rem;
    font-weight: $font-weight-regular;
    color: $color-white;
  }

  .successText,
  .altLink {
    letter-spacing: $letter-spacing-small;
    color: $color-white;
  }

  .textBody {
    color: $color-white;
    margin-bottom: $spacing-2x;
  }

  .altLink:hover {
    text-decoration: underline;
  }

  .input {
    border: 1px solid $color-gray-lighter;
    height: 40px;
    margin-bottom: $spacing;
    padding: 0 $spacing;

    &::placeholder {
      color: $color-gray-dark;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $color-gray-dark;
    }
  }

  .inputGroup {
    position: relative;
    margin-bottom: $spacing;
  }

  .inputSection {
    margin-bottom: $spacing * 3;
  }

  .passwordInputContainer {
    position: relative;
  }

  .showHidePass {
    color: $color-gray-dark;
    height: 40px;
    justify-content: flex-end;
    margin-right: $spacing-2x;
    position: absolute;
    right: 0;
    top: 3px;
    width: auto;
  }

  .showHideGlyph {
    color: $color-green;
    width: 20px;
  }
}

.loadingSpinner {
  margin: 0 auto;
}

.successIcon {
  width: 60px;
  height: 60px;
  fill: $color-white;
  margin-bottom: $spacing-2x;
}

.successText {
  display: flex;
  flex-direction: column;
  gap: $spacing;
  margin-bottom: $spacing-4x;
}

.altLinks {
  align-items: center;
  color: $color-white;
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  justify-content: center;

  > * {
    margin: 0 $spacing;
    white-space: nowrap;
  }
}

.poweredBy {
  width: 132px;
  height: 13px;
  margin: 75px 0;
  flex-shrink: 0;
}

.primaryAuthButton {
  @extend .button;
  margin-bottom: $spacing * 1.5;
}

.secondaryAuthButton {
  @extend .button;
}
