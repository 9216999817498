@import 'styles/variables';

.tag {
  background-color: $color-white;
  border-radius: 13px;
  color: $color-line;
  display: inline-block;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: $font-size-13;
  margin: 7px 5px 0 0;
  padding: 7px 12px 6px;
  text-align: center;
  vertical-align: middle;

  &.gray {
    color: $color-white;
    background-color: $color-gray-darker;
  }

  &.green {
    color: $color-white;
    background-color: $color-green;
  }

  &.red {
    color: $color-white;
    background-color: $color-red;
  }

  &.yellow {
    color: $color-white;
    background-color: $color-yellow;
  }
}
