@import 'styles/variables';

.container {
  background: rgba($color-white, 0.7);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
