@import 'styles/variables';
@import './conditionReport';

.crButton {
  &.static {
    cursor: default;

    .chevron {
      display: none;
    }
  }
}

.autoCheckImg {
  padding-top: $spacing;
  width: 130px;
}

.exceptionStatus {
  display: flex;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  padding-top: $spacing-quarter;
  text-transform: uppercase;

  svg {
    margin-right: 2px;
  }

  .notReceived {
    color: $color-gray-light;
  }

  .hasException {
    color: $color-red;
  }

  .noException {
    color: $color-green;
  }
}

.warning {
  height: 11px;
  width: 11px;
}

.check {
  background-color: $color-green;
  border-radius: 50%;
  fill: $color-white;
  padding: 3px;
  height: 11px;
  width: 11px;
}
