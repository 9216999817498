@import 'styles/variables';

.container {
  align-items: center;
  display: flex;
}

.title {
  color: $color-text;
  font-size: $font-size-10;
  letter-spacing: $letter-spacing-medium;
  line-height: 1;
  margin-right: 20px;
  text-transform: uppercase;
}

.inputSwitch {
  display: inline-block;
  // stylelint-disable-next-line selector-max-compound-selectors
  input[type='checkbox']:checked + label svg {
    color: $color-green;
  }
}

.inputSwitchUserIcon {
  background: transparent;
  height: 24px;
  width: 24px;
}
