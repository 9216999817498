@import 'styles/variables';

.radioPanel {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-green-dark;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;
  padding: $spacing $spacing-2x;
  width: 100%;

  &.selected {
    background-color: $color-green-lighter;
  }

  .radioButton {
    border-radius: 50%;
    border: 1px solid $color-gray-lighter;
    height: 15px;
    margin-left: $spacing-2x;
    width: 15px;

    &.selected {
      background-color: $color-green;
      border-color: $color-green;
      box-shadow:
        0 0 0 2px $color-green-lighter,
        0 0 0 3px $color-green;
      height: 10px;
      margin-right: 2px;
      width: 10px;
    }
  }
}

.banner {
  line-height: 1rem;
  padding: $spacing-half-3x $spacing;
  text-align: left;
}
