@import 'styles/variables';

.registrationInfo {
  color: $color-white;
  font-size: $font-size-14;
  margin-top: $spacing;

  .link {
    &,
    &:active,
    &:hover {
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.increasedTopMargin {
  margin-top: $spacing-4x;
}
