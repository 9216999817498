@import 'styles/variables';

.container {
  margin: 25px 0 -7px; // -7px margin from .input
}

.title {
  display: flex;
  justify-content: space-between;
  color: $color-gray-light;
  font-size: $font-size-11;
  letter-spacing: 1px;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  margin: 25px 0 0;
}

.inputs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-12;
  color: $color-gray-light;

  .input {
    background-color: $color-white;
    border-color: $color-gray-lightest;
    margin: 7px 0;

    .inputText {
      &::placeholder {
        color: $color-gray-dark;
      }
    }
  }

  > span {
    padding: 0 10px;
  }
}
