@import 'styles/variables';

.tabContainer {
  align-items: center;
  border-bottom: 2px solid transparent;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 15px 0 13px;
}

.tab {
  color: $color-gray-dark;
  font-size: $font-size-12;
  letter-spacing: $letter-spacing-medium;
  line-height: initial;
  text-transform: uppercase;
}

.active {
  border-bottom: 2px solid $color-gray-darkest;
}

.disabled {
  color: $color-surface-lighter;
}
