@import 'styles/variables';

$spacing: 30px;

.offline {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-gray-light;
  height: calc(100vh - var(--system-navigation-height-single));

  .noConnectionSprite {
    margin-top: 110px;
    margin-bottom: $spacing;
    width: 250px;
    height: 200px;
    min-height: 200px;
  }

  h2 {
    text-transform: uppercase;
    font-size: $font-size-25;
    font-weight: $font-weight-extrabold;
    letter-spacing: $letter-spacing-huge;
    margin-bottom: $spacing;
  }

  p {
    font-size: $font-size-13;

    &:first-of-type {
      padding-bottom: $spacing;
    }
  }

  .refresh {
    font-size: $base-font-size;
    width: 180px;
    height: 50px;
    border-radius: $border-radius;
    margin-top: $spacing;
  }
}
