@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}

.scrollableContainer {
  max-height: 150px;
  overflow: auto;
  width: 100%;
}

.fade::after {
  background: linear-gradient(transparent, $color-white);
  bottom: 0;
  content: '';
  height: 29px;
  left: 0;
  position: absolute;
  width: 100%;
}
