@import 'styles/variables';

$base-padding: 12px;

.currentOfferCounter {
  color: $color-blue;
  font-size: $font-size-32;
  padding: $base-padding 0;
}

.instructions {
  padding-bottom: $base-padding;
}

.errorMessage {
  color: $color-red;
  padding-top: 2 * $base-padding;
}

.consigners,
.users {
  margin: 0 0 10px;
  font-size: $font-size-11;
}

.amount {
  z-index: auto;
}
