@import 'styles/variables';

.clearFilterSprite {
  width: 12px;
  height: 12px;
  color: $color-blue;
  fill: $color-blue;
  vertical-align: middle;
}

.calendarIcon {
  width: 15px;
  height: 15px;
  color: $color-blue;
  fill: $color-blue;
  position: absolute;
  top: calc(50% - 8px);
  right: 15px;
  pointer-events: none;
  z-index: $zIndex1;
}

.datePicker {
  :global {
    .SingleDatePicker {
      flex-grow: 1;
      background-color: transparent;
    }

    .SingleDatePickerInput {
      background-color: transparent;
      border: none;
    }

    .SingleDatePickerInput_calendarIcon {
      margin: 0;
      padding: 0;
    }

    .SingleDatePickerInput__showClearDate {
      padding: 0;
    }

    .SingleDatePickerInput_clearDate {
      margin-right: 6px;
      margin-left: 0;
      padding-left: 6px;
    }

    .SingleDatePicker_picker {
      z-index: $zIndex2;
      top: 55px !important; // stylelint-disable-line
    }

    .SingleDatePicker_picker__openDown {
      top: 57px;
    }

    .DateInput {
      background: transparent;
      width: auto;
    }

    .DateInput__openDown {
      &::before {
        top: 47px;
      }

      &::after {
        top: 48px;
      }
    }

    .DateInput_input {
      height: 38px;
      background-color: $color-white;
      border: 1px solid $color-surface-lighter;
      border-radius: 5px;
      color: $color-text;
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      padding: 6px 12px;
      cursor: pointer;
    }

    .DateInput_input__focused {
      border: 1px solid $color-surface-lighter;
    }

    .DayPicker__horizontal {
      position: absolute;
    }

    .DayPicker_weekHeader {
      top: 55px;
    }

    .DayPicker_weekHeader_ul {
      display: flex;
    }

    .DayPicker_weekHeader_li {
      justify-content: center;
    }

    .DayPickerNavigation_svg__horizontal {
      width: 13px;
      height: 13px;
    }

    .CalendarMonth_caption {
      padding-top: 27px;
    }

    .CalendarMonth_caption_1 {
      font-size: 0.7rem;
      color: $color-gray-light;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .CalendarDay__selected {
      background: $color-green;
      border-color: $color-green;
    }

    .DateInput_fang {
      top: 45px !important; // stylelint-disable-line
    }
  }
}
