@import 'styles/variables';

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: auto;
  overflow-y: auto;
  padding: 5px;
}

.glyph {
  fill: $color-gray-light;
  height: 12px;
}

.errorMessage {
  color: $color-gray-light;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
