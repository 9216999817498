@import 'styles/variables';

.container {
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.containerInner {
  display: flex;
  flex-direction: column;
  height: calc(100% - 18px); // Minus progress bar height
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  color: $color-text;
  padding: 0 $spacing-2x $spacing-2x;
}

.label {
  color: $color-gray-darkest;
  font-size: $font-size-12;
  margin-bottom: 12px;
}

.biddingAs {
  margin-bottom: $spacing;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $spacing;
  margin-top: $spacing;
}

.button {
  border-radius: $border-radius-small;
  font-size: $font-size-14;
  min-height: 40px;
  text-transform: none;
}
