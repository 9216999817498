@import 'styles/variables';

$base-padding: 12px;

.instructions {
  padding: 0 0 $base-padding;
  line-height: 1.4;
}

.consigners,
.users,
.message {
  margin: 0 0 10px;
  font-size: $font-size-11;
}

.message {
  margin-bottom: 0;
}

.errorMessage {
  color: $color-red;
  padding-top: 2 * $base-padding;
}
