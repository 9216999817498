@import 'styles/variables';

.container {
  align-items: center;
  color: $color-text;
  display: flex;
  font-size: $font-size-12;
  margin-bottom: $spacing;
}

.tooltip {
  max-width: 400px;
}

.tooltipGlyph {
  background: $color-gray-light;
  border-radius: 20px;
  flex-shrink: 0;
  height: 14px;
  margin: -3px 0 0 5px;
  padding: 3px;
  width: 14px;
}

.notificationTextGlyph {
  display: none;
}
