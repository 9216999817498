@import 'styles/variables';

.envModeWarning {
  align-items: center;
  background: $color-red;
  border-top-left-radius: $border-radius;
  color: $color-white;
  display: flex;
  font-size: $font-size-12;
  padding: $spacing;
  pointer-events: none;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: $zIndexDialog;
}

.clearButton {
  color: $color-white;
  margin-left: $spacing;
  pointer-events: all;

  svg {
    height: 20px;
    width: 20px;
  }
}
