@import 'styles/variables';
@import 'styles/typography';

.linkRow {
  align-items: center;
  border-bottom: 1px solid rgba($color-line, 0.3);
  color: $color-text;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  gap: $spacing;
  justify-content: space-between;
  padding: $spacing-2x 0;
  width: 100%;

  &:hover {
    color: $color-text;
  }

  &:last-child {
    border-bottom: none;
  }

  &:disabled {
    cursor: not-allowed;

    .chevron {
      fill: $color-gray-light;
    }
  }
}

.linkRowLight {
  color: $color-gray-light;
}

.external {
  width: 8px;
  height: 8px;
  fill: $color-text;
}
