@import 'styles/variables';

.playerContainer {
  height: 100%;
  position: relative;
  width: 100%;
}

.soundIndicator {
  bottom: $spacing-2x;
  left: $spacing-2x;
  position: absolute;
  z-index: 1;
}

.videoStream {
  align-self: center;
  background-color: $color-black;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.videoControl {
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 1;

  &.top {
    bottom: auto;
    top: 0;
  }
}
