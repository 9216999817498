@import 'styles/variables';

.linkButton {
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  gap: $spacing;
  justify-content: center;
  padding: $spacing-half-3x;

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    color: $color-gray-light;
    cursor: not-allowed;
    fill: $color-gray-light;
  }
}

.theme-blue {
  color: $link-color;
  fill: $link-color;

  &:hover {
    color: $link-hover-color;
    fill: $link-hover-color;
  }

  &:active {
    color: $color-blue-darker;
    fill: $color-blue-darker;
  }
}

.theme-red {
  color: $color-red;
  fill: $color-red;

  &:hover {
    color: $color-red-dark;
    fill: $color-red-dark;
  }

  &:active {
    color: $color-red-darker;
    fill: $color-red-darker;
  }
}

.theme-white {
  color: $color-white;
  fill: $color-white;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}
