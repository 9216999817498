@import 'styles/variables';

.container {
  align-items: center;
  background: $color-surface-lightest;
  border-radius: $border-radius-small;
  border: 1px solid $color-gray-lightest;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: min-content;
  padding: 0 $spacing-3x;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;

  &:disabled {
    opacity: 0.3;
  }
}

.value {
  color: $color-text;
  font-size: $font-size-24;
  font-weight: $font-weight-bold;
  margin: 0 12px;
}

.decrement,
.increment {
  width: 12px;
  height: 12px;
}
