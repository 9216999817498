@import 'styles/variables';

$base-padding: 12px;
$button-spacing: 9px;

%status {
  color: $color-gray-darkest;
  font-size: $font-size-12;
  margin: 0 $spacing-3x;
  max-width: 750px;
  position: relative;

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 30%;
    white-space: nowrap;
  }

  dd {
    font-weight: $font-weight-semibold;
    position: relative;
    text-align: right;
    width: 70%;

    p {
      padding-top: $base-padding;
      margin-left: -43%;
      text-align: left;
      font-weight: $font-weight-regular;
      color: $color-gray-dark;
      font-size: $font-size-11;
    }
  }

  dl + dl:not(:empty) {
    border-top: 1px solid rgba($color-line, 0.3);
  }
}

.rowsContainer {
  @extend %status;
}

.name {
  display: flex;
  align-items: center;

  &.clickableRow {
    line-height: 20px;
  }
}

.name,
.value {
  padding: $base-padding 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba($color-line, 0.3);
  }

  &.mergeNext {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.buttons:not(:empty) {
  border-top: 1px solid rgba($color-line, 0.3);
  padding: 21px 0;
  text-align: center;

  > button {
    margin: 0 $button-spacing;

    &:disabled {
      background: $color-white;
      color: $color-gray-lighter;
    }
  }
}

.buttons + dl:not(:empty) {
  border-top: 1px solid rgba($color-line, 0.3);
}

.clickable {
  align-items: center;
  color: $color-gray-darkest;
  display: flex;
  font-weight: $font-weight-semibold;
  gap: $spacing;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  > span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
