@import 'styles/variables';

.isActive {
  background-color: $color-green;

  &:hover {
    background-color: $color-green;
  }
}

.sprite {
  --icon-size: 16px;
}
