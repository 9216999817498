@import 'styles/variables';

.blackBookCopyright {
  align-items: center;
  color: $color-gray-dark;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: $line-height-medium;
}

.blackBookLogo {
  margin-right: 10px;
}
