@import 'styles/variables';

$base-padding: 12px;

.dialog {
  width: 450px;
}

.title {
  color: $color-gray-darkest;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  line-height: 16px;
  width: 100%;

  .subTitle {
    color: $color-gray-light;
  }
}

.mileage {
  color: $color-gray-light;
  font-weight: $font-weight-semibold;
  padding-bottom: 10px;
}

.instructions {
  padding-bottom: $base-padding;
  font-size: $font-size-12;
}

.errorMessage {
  color: $color-red;
  padding-top: $base-padding;
}

.consigners,
.users {
  margin: 0 0 10px;
  font-size: $font-size-11;
}

.amount {
  z-index: auto;
}

.valuesInfo {
  line-height: initial;
  padding-bottom: $spacing;
  margin-bottom: $spacing;
}

.valuesInfoHeader {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  padding-bottom: $spacing;
}
