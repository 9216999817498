@import 'styles/variables';

.modalCustom {
  font-size: $font-size-11;
  padding: 0;
  height: calc(100% - #{$full-dialog-header-height} - #{$full-dialog-footer-height});
  overflow: auto;

  p {
    margin-bottom: 12px;
  }
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  background: $color-white;
  box-shadow: 0 3px 2px -2px $color-gray-lighter;
  z-index: $zIndex2;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border-top: 1px solid $color-surface-lighter;
  bottom: 0;
  width: 100%;
  background: $color-white;
  height: 85px;
  z-index: $zIndex2;
}

.footerContainer {
  display: flex;
  flex-direction: row;
}

.progressText {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
  font-weight: $font-weight-bold;
  font-size: $font-size-12;
  color: $color-gray-lighter;
}

.smallButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: $spacing-half-3x;
  min-width: 40px;
  padding: 0;
}

.backButton {
  display: flex;
  align-items: center;
  min-width: 0;
  padding: 0 15px;
  background: $color-gray-lighter;

  &:hover {
    background: $color-gray-light;
  }
}

.arrowSprite {
  width: 12px;
  height: 12px;
  fill: $color-white;
  margin-right: 15px;
}

.errorMessageContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: $color-red-lightest;
  border: 1px solid $color-red;
  color: $color-red;
  border-radius: $border-radius;
  margin-bottom: 20px;
  padding: 20px;
}

.spinnerStyle {
  &::before {
    background: $color-white;
  }
}

.prevNextContainer {
  display: flex;
}

.sprite {
  height: 14px;
}
