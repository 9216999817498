@import 'styles/variables';

$invoice-row-padding: 7px;

.container {
  width: 100%;
}

.invoiceSection {
  &:not(:last-of-type) {
    margin-bottom: 18px;
  }

  .title {
    padding-left: $invoice-row-padding;
    margin-bottom: 3px;
  }
}

.invoiceRow {
  display: flex;
  justify-content: space-around;
  padding: $invoice-row-padding;

  &:nth-child(odd) {
    background: $color-surface-lightest;
  }

  > div:last-child {
    /* The value */
    flex-shrink: 0;
    margin-left: 15px;
    text-align: right;
    width: auto;
  }
}

.hr {
  margin: 15px 0 5px;
}

.invoiceTotalValue {
  font-size: 1.375rem; // 22px
}
