@import 'styles/variables';

.empty {
  display: flex;
  flex-direction: column;
  min-width: 385px;
  max-width: 750px;
  padding: 0 $spacing-3x;
}

.header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: $search-bar-height + 1;
  width: 100%;
  min-width: calc(275px - (6 * #{$spacing}));
  background-color: $color-surface-lightest;
  border-bottom: 1px solid $color-input-border;
  padding: 0 1.5rem;
}

.content {
  min-width: 350px;
}

.photoContainer {
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 400px;
  position: relative;
}

.photoContainerInner {
  display: flex;
  height: 0;
  padding-bottom: 75%; // height ratio
}

.loading {
  position: absolute;
  top: 45%;
  left: 45%;
}

.status {
  margin-top: 1rem;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid $color-input-border;
  padding: $spacing 0;

  > div {
    width: 50%;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  flex-flow: row wrap;

  button {
    min-width: 160px;
  }

  button:disabled,
  button:disabled:active,
  button:disabled:hover {
    cursor: default;
    background-color: $color-surface-lighter;
    border: none;
    margin: $spacing;
  }
}
