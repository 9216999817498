@import 'styles/variables';

.runAgainButton {
  display: flex;
  justify-content: center;
  letter-spacing: $letter-spacing-big;
  max-width: 160px;
  min-height: 32px;
  min-width: 100px;
  width: 100%;
}
