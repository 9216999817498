@import 'styles/variables';

.chooseFieldHeader {
  font-size: $font-size-12;
  margin-bottom: 25px;
}

.chooseFieldRows {
  display: block;
  width: 100%;
}

.chooseFieldRow {
  font-size: $font-size-12;
}

.expiryContainer {
  width: 273px;
}
