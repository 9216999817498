@import 'styles/variables';

.container {
  align-items: center;
  background-color: $color-surface-lightest;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.containerInner {
  text-align: center;
}

.spinner {
  margin-bottom: $spacing-4x;
}

.textPrimary {
  color: $color-blue;
  font-size: $font-size-20;
}

.textSecondary {
  color: $color-blue;
  font-size: $font-size-14;
  line-height: $line-height-medium;
  margin: $spacing 0 $spacing-4x;
  white-space: normal;
  width: 330px; // Force text wrap
}
