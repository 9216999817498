@import 'styles/variables';

$spacing: 30px;

.mobileGate {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-black;
  height: 100vh;
  overflow: auto;

  .phone {
    margin-top: 70px;
    width: 141px;
    height: 138px;
  }

  h1 {
    font-size: 24px;
    font-family: $font-weight-extrabold;
    text-transform: uppercase;
    color: $color-black;
    letter-spacing: 1px;
    margin: 40px 10px 20px;
  }

  p {
    font-size: 16px;
    line-height: 32px;
    color: $color-gray-darker;
  }

  .links {
    display: flex;
    flex-direction: row;
    margin: 20px 0 40px;

    @media screen and (max-width: 330px) {
      flex-direction: column;
    }
  }

  .linkImage {
    height: 45px;
    width: 145px;
    margin: $spacing;
  }
}
