@import 'styles/variables';

.sidePanel {
  background-color: $color-gray-darker;
  border-radius: $border-radius 0 0 $border-radius;
  height: 100%;
  overflow-y: auto;
  position: relative;
  z-index: $zIndex2;

  &.noButtons {
    height: 100%;
  }
}

.container {
  padding-top: $spacing-2x;

  &.unreadMessagesNotVisibleTop {
    margin-top: -26px; // Offset by the size of the unreadMessagesButton
  }

  &.unreadMessagesNotVisibleBottom {
    margin-bottom: -26px; // Offset by the size of the unreadMessagesButton
  }
}

.section {
  &:not(:last-child) {
    margin-bottom: $spacing-2x;
  }
}

.sectionTitle {
  color: $color-white;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-big;
  padding: 0 $spacing * 1.5;
  text-transform: uppercase;
}

.unreadMessagesButtonContainer {
  padding: 0 $spacing-half;
  position: sticky;
  top: $spacing-2x;
  z-index: $zIndex1;

  &.down {
    top: unset;
    bottom: $spacing-2x;
  }
}

.unreadMessagesButton {
  left: 40px; // (Width - 119px) / 2
  min-height: 26px;
  min-width: 100%;
  width: 100%;

  .chevronGlyph {
    height: 8px;
    margin-right: $spacing;
    overflow: visible;
    transform: rotate(-90deg);
    width: 8px;

    &.down {
      transform: rotate(90deg);
    }
  }
}

.refreshButton {
  color: $color-white;
  display: flex;
  flex-direction: row;
  padding: $spacing;
  position: absolute;
  right: 0;
  top: 0;

  svg {
    height: 14px;
    width: 14px;

    &:hover {
      color: $color-gray-lightest;
      cursor: pointer;
    }
  }
}
