@import 'styles/variables';

.crRow {
  border-bottom: solid 1px rgba($color-line, 0.3);
  padding: 15px 0 10px;

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.crRowCell {
  display: flex;
  flex-flow: row nowrap;

  @media screen and (max-width: 1340px) {
    flex-flow: column nowrap;
  }
}

.crRowCellInner {
  font-size: $font-size-12;
  margin-right: 20px;

  @media screen and (max-width: 1340px) {
    width: 100%;

    &:last-child {
      margin-top: 10px;
    }
  }
}

.crRowTitle {
  color: $color-gray-dark;
  display: flex;

  > div {
    text-align: left;
    width: 50%;
  }
}

.crRowContent {
  display: flex;
  margin-top: 5px;
}

.crRowList {
  display: flex;
  flex-flow: row wrap;
  line-height: 1.6;

  > li:not(:last-child) {
    position: relative;
    margin-right: $spacing;

    &::after {
      content: '•';
      width: 10px;
      height: 10px;
      color: $color-gray-lighter;
      margin-left: $spacing;
    }
  }
}

.crScore {
  color: $color-text;
  font-weight: $font-weight-regular;
}

.buyerShieldScore {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  transform: translateY(1px);
}
