@import 'styles/variables';

.detailsLoading {
  margin: 10px auto 0;
}

.location {
  align-items: flex-start;
  display: flex;
  font-size: $font-size-12;
  line-height: 1.6;
  margin-bottom: 20px;
}

.map {
  flex-shrink: 0;
  height: 100px;
  margin-right: 20px;
  width: 100px;
}

.detailsButton {
  align-items: center;
  display: flex;
  font-size: $font-size-12;
  justify-content: space-between;
  line-height: 1.6;
  text-align: left;
  width: 100%;

  .companyName {
    font-size: $font-size-14;
    font-weight: $font-weight-bold;
  }

  .companyPreferredLocation {
    font-weight: $font-weight-regular;
  }
}
