@import 'styles/variables';
@import './vehicleDetails';

$col-spacing: 20px / 3;

.container {
  padding-top: 42px;
}

.tireConditionSegment {
  padding: 0;
}
