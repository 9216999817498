@import 'styles/variables';

.heading {
  display: flex;
  justify-content: space-between;
  color: $color-gray-light;
  font-size: $font-size-11;
  letter-spacing: 1px;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  margin: 25px 0 0;

  &:first-of-type {
    margin-top: 0;
  }
}

.rangeFacetGroup {
  margin: 25px 0;

  > .heading {
    margin-bottom: 10px;
  }

  .valueLabel {
    text-align: right;
  }
}
