.map {
  display: block;
  height: 150px;
  width: 300px;
  cursor: pointer;
}

a[href^='http://maps.google.com/maps'],
a[href^='https://maps.google.com/maps'],
a[href^='https://www.google.com/maps']
{
  display: none !important; // stylelint-disable-line
}

:global {
  .gm-style-cc {
    display: none;
  }
}
