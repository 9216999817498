@import 'styles/variables';

.circle {
  width: 25px;
  height: 25px;
  background-color: $color-black;
  border-radius: 50%;
}

.dot {
  background: transparent;
  border-radius: 50%;
  border: 1px solid $color-gray-lighter;
  flex-shrink: 0;
  height: 12px;
  margin-left: 15px;
  width: 12px;
}

.dotSelected {
  background: $color-green;
  border: none;
}

.rectangle {
  width: 25px;
  height: 5px;
  background-color: $color-black;
}
