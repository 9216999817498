@import 'styles/variables';

.container {
  background-color: $color-white;
  border-bottom: 1px solid $color-gray-lighter;
  position: sticky;
  top: $anchor-tabs-height;
  width: 100%;
  z-index: $zIndex1;

  &.compactHeader {
    top: calc(#{$header-compact-height} + #{$anchor-tabs-height});
  }

  &.fullHeader {
    top: calc(#{$header-full-height} + #{$anchor-tabs-height});
  }

  &.liveLanesHeader {
    top: calc(#{$header-live-lanes-height} + #{$anchor-tabs-height});
  }
}

.containerInner {
  display: flex;
  flex-flow: row wrap;
  gap: $spacing;
  max-width: 800px;
  min-width: $details-min-width;
  padding: $spacing-2x $spacing-3x;
  position: relative;

  & > div {
    margin: 0;
  }

  & > button {
    margin: 0;
  }

  &:empty {
    display: none;
  }
}
