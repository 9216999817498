@import 'styles/variables';

.addRemoveButton {
  position: absolute;
  top: 36px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  svg {
    width: 15px;
    height: 15px;
    fill: $color-green;
    flex-shrink: 0;
  }
}
