@import 'styles/variables';

.confirmMessage {
  padding-bottom: $spacing-2x;
}

.instructions {
  color: $color-gray-dark;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  line-height: $line-height-atom;
  margin-bottom: $spacing-2x;
}
