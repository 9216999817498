@import 'styles/variables';
@import './statusRows/statusRows';

.sold {
  @extend %status;
}

.total {
  color: $color-green;
}
