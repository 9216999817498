@import 'styles/variables';

.inputOption {
  display: none;
}

.form {
  margin-top: 10px;

  .label {
    display: flex;
    justify-content: space-between;
    border-color: $color-gray-lighter;
    border-style: solid;
    border-width: 1px 1px 0;
    padding: 15px 20px;
    cursor: pointer;

    &:first-child {
      border-radius: $block-border-radius $block-border-radius 0 0;
    }

    &:last-child {
      border-bottom: 1px solid $color-gray-lighter;
      border-radius: 0 0 $block-border-radius $block-border-radius;
      margin-right: 0;
    }

    &:hover {
      background: $color-surface-lightest;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .optionButton {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: 1px solid $color-gray-lighter;
  }

  .selected {
    background: $color-gray-darker;
    border-color: $color-gray-darker;
    color: $color-white;

    &:hover {
      background: $color-gray-darker;
    }
  }

  .infoGlyph {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background: $color-gray-lighter;
    padding: 2px;
    margin: 0 15px -2px 0;
  }

  .infoGlyph-orange {
    background: $color-yellow;
  }

  .infoGlyph-green {
    background: $color-green;
  }

  .subtitle {
    color: $color-gray-lighter;
    margin-top: 10px;
  }

  .orange {
    color: $color-yellow;
  }

  .green {
    color: $color-green;
  }
}

.theme-button {
  display: flex;
  margin-top: -1px;
  padding-right: 13px;

  .label {
    align-self: center;
    border-radius: $block-border-radius;
    border: 1px solid $color-black;
    color: $color-black;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    height: 48px;
    justify-content: center;
    letter-spacing: $letter-spacing-medium;
    margin-right: $spacing;
    min-width: 140px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child,
    &:last-child {
      border-radius: $block-border-radius;
      border: 1px solid $color-black;
    }
  }

  .selected {
    border: 1px solid $color-gray-darker;
    color: $color-white;

    &:first-child,
    &:last-child {
      border-bottom: 1px solid $color-gray-darker;
    }
  }
}

.theme-columnGap {
  .label {
    align-items: center;
    background-color: $color-white;
    border-radius: $border-radius;
    border: none;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    height: 56px;
    margin-bottom: $spacing;

    .content {
      align-items: center;
      flex-direction: row;
    }

    .sprite {
      margin-right: $spacing-2x;
    }

    &:first-child,
    &:last-child {
      border-radius: $border-radius;
    }

    &:hover {
      background-color: $color-surface-lightest;
    }

    &.selected {
      background-color: $color-green-lighter;
      color: $color-gray-darker;
    }

    .optionButton {
      border-radius: 50%;
      border: 1px solid $color-gray-lighter;
      height: 15px;
      width: 15px;
    }

    .selected {
      background-color: $color-green;
      border-color: $color-green;
      box-shadow:
        0 0 0 2px $color-green-lighter,
        0 0 0 3px $color-green;
      height: 10px;
      margin-right: 2px;
      width: 10px;
    }
  }
}

.theme-green,
.theme-blue {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1px;
  width: 100%;

  .label {
    border-radius: $block-border-radius;
    font-size: $font-size-11;
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing-medium;
    padding: 7.5px 14px;
    margin: 0 10px 10px 0;
    color: $color-white;
    background: $color-gray-light;
    border: 1px solid $color-gray-light;
    white-space: nowrap;
    text-transform: uppercase;

    &:hover {
      background: $color-gray-light;
    }

    &:first-child,
    &:last-child {
      border-radius: $block-border-radius;
      border-bottom: 1px solid $color-gray-light;
    }
  }

  .capitalizedText {
    text-transform: capitalize;
    font-weight: $font-weight-regular;
    font-size: $font-size-12;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

.theme-green {
  .selected {
    background: $color-green;
    border-color: $color-green;

    &:hover {
      background: $color-green;
    }

    &:first-child,
    &:last-child {
      border-color: $color-green;
    }
  }
}

.theme-blue {
  .selected {
    background: $color-blue;
    border-color: $color-blue;

    &:hover {
      background: $color-blue;
    }

    &:first-child,
    &:last-child {
      border-color: $color-blue;
    }
  }
}

.theme-red {
  .label {
    background: $color-white;
  }

  .selected {
    background: $color-white;
    border: 1px solid $color-red;
    color: $color-red;

    &:first-child,
    &:last-child {
      border-bottom: 1px solid $color-red;
    }

    &:hover {
      background: $color-white;
    }

    .optionButton {
      position: relative;
      border-color: $color-red;

      &::after {
        content: '';
        background-color: $color-red;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
      }
    }
  }
}

.innerButtons {
  width: auto;
  flex-wrap: nowrap;
  padding: 5px 0;

  .label {
    padding: $spacing-half 5px 3px;
    margin: 0 5px 0 0;
  }
}

.theme-colorful {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1px;
  width: 100%;

  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-half 5px 3px;
    margin-right: 10px;
    background: $color-gray-light;
    border: 1px solid $color-gray-light;
    border-radius: $block-border-radius;
    font-size: $font-size-11;
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing-medium;
    text-transform: uppercase;
    color: $color-white;
    cursor: pointer;

    &:hover {
      background: $color-gray-light;
      border-color: $color-gray-light;
    }
  }

  .capitalizedText {
    text-transform: capitalize;
    font-weight: $font-weight-regular;
    font-size: $font-size-12;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .selectedText {
    text-transform: capitalize;
    font-weight: $font-weight-semibold;
    font-size: $font-size-8;
    margin-top: 5px;
  }

  .black {
    background: $color-black;
    border-color: $color-black;

    &:hover {
      background: $color-black;
      border-color: $color-black;
    }
  }

  .blue {
    background: $color-blue;
    border-color: $color-blue;

    &:hover {
      background: $color-blue;
      border-color: $color-blue;
    }
  }

  .brown {
    background: $color-swatch-brown;
    border-color: $color-swatch-brown;

    &:hover {
      background: $color-swatch-brown;
      border-color: $color-swatch-brown;
    }
  }

  .gold {
    background: $color-yellow;
    border-color: $color-yellow;

    &:hover {
      background: $color-yellow;
      border-color: $color-yellow;
    }
  }

  .gray {
    background: $color-gray-light;
    border-color: $color-gray-light;

    &:hover {
      background: $color-gray-light;
      border-color: $color-gray-light;
    }
  }

  .green {
    background: $color-swatch-green;
    border-color: $color-swatch-green;

    &:hover {
      background: $color-swatch-green;
      border-color: $color-swatch-green;
    }
  }

  .orange {
    background: $color-yellow;
    border-color: $color-yellow;

    &:hover {
      background: $color-yellow;
      border-color: $color-yellow;
    }
  }

  .purple {
    background: $color-swatch-purple;
    border-color: $color-swatch-purple;

    &:hover {
      background: $color-swatch-purple;
      border-color: $color-swatch-purple;
    }
  }

  .red {
    background: $color-swatch-red;
    border-color: $color-swatch-red;

    &:hover {
      background: $color-swatch-red;
      border-color: $color-swatch-red;
    }
  }

  .silver {
    background: $color-gray-light;
    border-color: $color-gray-light;

    &:hover {
      background: $color-gray-light;
      border-color: $color-gray-light;
    }
  }

  .tan {
    background: $color-swatch-tan;
    border-color: $color-swatch-tan;

    &:hover {
      background: $color-swatch-tan;
      border-color: $color-swatch-tan;
    }
  }

  .teal {
    background: $color-swatch-teal;
    border-color: $color-swatch-teal;

    &:hover {
      background: $color-swatch-teal;
      border-color: $color-swatch-teal;
    }
  }

  .white {
    background: $color-white;
    border-color: $color-gray-light;
    color: $color-gray-light;

    &:hover {
      background: $color-white;
      border-color: $color-gray-light;
      color: $color-gray-light;
    }
  }

  .yellow {
    background: $color-swatch-yellow;
    border-color: $color-swatch-yellow;

    &:hover {
      background: $color-swatch-yellow;
      border-color: $color-swatch-yellow;
    }
  }

  .unselected {
    background: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;
  }
}

.squareLabels {
  .label {
    width: calc(100% / 9 - 10px);
    height: 70px;
    margin: 0 10px 10px 0;
    padding: 0;
  }

  .content {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .sprite {
    width: calc(100% - 15px);
    padding-bottom: 10px;
  }
}

.subTitleClass {
  display: flex;
  flex-direction: column;
}

.theme-button,
.theme-green,
.theme-blue,
.theme-colorful {
  .optionButton {
    display: none;
  }
}
