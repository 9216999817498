@import 'styles/variables';

.navigation {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;

  svg {
    margin-right: 1rem;
    margin-top: -1px;
    fill: $color-gray-light;
  }

  li {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

// TODO: Create common nav link component for this
.actingAsUser {
  .link {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  svg {
    fill: $color-white;
  }
}

.icon {
  width: 15px;
  height: 15px;
}

.arbitrationIcon {
  width: 19px;
  height: 15px;
}

.marketGuideIcon {
  width: 20px;
  height: 20px;
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  margin: 0 $container-padding;
  color: $color-gray-light;
  user-select: none;

  > span {
    position: relative;
  }

  &:hover {
    text-decoration: none;
    color: $color-white;

    > svg {
      fill: $color-white;
    }
  }
}

.active {
  color: $color-white;
  border-bottom: 3px solid $color-white;
  padding-top: 3px;
  pointer-events: none;

  > svg {
    fill: $color-white;
  }
}
