@import 'styles/variables';
@import 'styles/typography';

.container {
  padding: $spacing-2x;
}

.body {
  column-gap: $spacing-2x;
  display: grid;
  grid-template-columns: 80px auto;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  gap: $spacing;
  justify-content: flex-end;
  margin-top: $spacing;

  &:empty {
    display: none;
  }

  .button {
    min-height: 40px;
    min-width: 200px;
    border-radius: $border-radius-small;
    display: flex;
    font-size: $font-size-14;
    align-items: center;
    justify-content: center;
    text-transform: none;

    > svg {
      margin: 0 4px;
    }
  }

  .pseudoButton {
    align-items: center;
    background-color: $color-green-darker;
    border-radius: $border-radius-small;
    color: $color-white;
    display: flex;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    justify-content: center;
    height: 40px;
    width: 200px;
    padding: 0 20px;
    text-transform: uppercase;
    grid-column: span 1 / -1;

    > svg {
      margin: 0 4px;
    }

    &.fill {
      svg {
        fill: $color-green-dark;
      }
    }
  }
}

.thumbnail {
  position: relative;
  width: 80px;
  min-width: 80px;
  height: 80px;
  background-color: $color-surface-lighter;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  > div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px;
    text-transform: uppercase;
    font-size: $font-size-9;
    font-weight: $font-weight-semibold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    color: $color-gray-dark;
  }

  .gradientBg {
    color: $color-white;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }

  .archived {
    background-color: $color-red;
    border-radius: 50%;
    fill: $color-white;
    height: 13px;
    left: 5px;
    margin-top: 2px;
    padding: 1px 2.5px 2px;
    position: absolute;
    top: 5px;
    width: 13px;
    z-index: $zIndex1;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
  }
}

.noThumbnail {
  width: 45%;
  height: 50%;
  fill: $color-gray-light;
}

.details {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  .titleSection {
    display: grid;
    grid-template-columns: 1fr;

    &.shouldDisplayBidAmount {
      grid-template-columns: minmax(0, 75%) auto;
    }
  }

  .title {
    line-height: 20px;

    &.highlighted {
      color: $color-green;
    }
  }

  .runNumber {
    color: $color-green;
  }

  h2 {
    color: $color-gray-darkest;
    font-weight: $font-weight-semibold;
    font-size: $font-size-12;
    line-height: 20px;
    margin: 0;

    @extend %ellipsis;
  }

  .mileage {
    color: $color-gray-dark;
    font-weight: $font-weight-semibold;
    font-size: $font-size-13;
    line-height: 20px;
    white-space: nowrap;
  }
}

.badgesRow {
  align-items: center;
  display: flex;
}

.badges {
  --badge-size: 24px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2px;
}

@keyframes bulge {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.bid {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 90px;

  .amount {
    color: $color-green;
    font-weight: $font-weight-bold;
    font-size: $font-size-20;

    &.animate {
      animation-name: bulge;
      animation-duration: 0.3s;
      animation-timing-function: ease;
      transform-origin: center;
      will-change: transform;
    }
  }
}

.red {
  .title.highlighted {
    color: $color-red;
  }

  .pseudoButton {
    background-color: $color-red;
    border-color: $color-red;

    &.fill {
      svg {
        fill: $color-red;
      }
    }
  }

  .amount {
    color: $color-red;
  }
}

.autobidButton {
  justify-content: center;
  margin: 0;
  padding-left: 18px;
  padding-right: 18px;
}

.watchButton {
  justify-content: center;
  margin: 0 0 0 auto;
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  padding: 0;

  svg {
    margin-right: 0;
    padding: 0;
  }
}

.vinContainer {
  margin-top: $spacing;
  position: relative;
}

.vin {
  background-color: $color-gray-lighter;
  border-radius: $border-radius;
  color: $color-gray-darker;
  display: inline-block;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  padding: $spacing-half;
}
