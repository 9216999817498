@import 'styles/variables';

.tabs {
  background: $color-white;
  display: flex;
  height: 45px;
}

.tab {
  border: none;
  flex: 1 0;
  font-size: $font-size-11;
  height: 100%;
  position: relative;
}

.tabButton {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;

  &::after {
    border-bottom: 1px solid $color-surface-lighter;
    bottom: 0;
    content: '';
    position: absolute;
    width: 100%;
  }
}

.activeTab {
  button {
    color: $color-blue;
  }

  &::after {
    border-bottom: 1px solid $color-blue;
    bottom: 0;
    content: '';
    position: absolute;
    width: 100%;
  }
}

.slideOut {
  .slideOutInner {
    height: 100%;
  }
}

.slideOutInner {
  background-color: $color-surface-lightest;
}

.slideOutHeader {
  padding: 0 15px;
}
