@import 'styles/variables';

.summarySection {
  margin-bottom: 10px;
  margin-left: -$spacing-3x;
  margin-right: -$spacing-3x;
  margin-top: -$spacing-3x;
  padding-bottom: 5px;
}

.listItem {
  border-bottom: 1px solid $color-surface-lighter;
  color: $color-gray-dark;
  font-size: $font-size-12;
  width: 100%;
  margin-bottom: 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}

.body {
  align-items: center;
  display: grid;
  grid-template-columns: 10% minmax(0, 1fr) 23%;
  grid-gap: 5px;
  justify-content: space-between;
  margin: 0;
  padding: $spacing-2x $spacing-2x;
  white-space: break-spaces;
}

.details {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.active {
  color: $color-green;
}

.inactive {
  color: $color-red;
}

.createdDate {
  color: $color-gray-light;
  font-size: $font-size-10;
}

.price {
  color: $color-text;
  font-size: $font-size-14;
  font-weight: $font-weight-bold;
  padding-bottom: 5px;
}
