@import 'styles/variables';

.row {
  display: flex;
  flex-flow: row wrap;
  row-gap: $spacing-2x;

  &:nth-child(2) {
    border-top: 1px solid $color-surface-lighter;
    margin-top: $spacing-2x;
    padding-top: $spacing-2x;
  }
}

.listItem {
  font-size: $font-size-12;
  width: 50%;

  &:nth-child(odd) {
    padding-right: 15px;
  }

  &:nth-child(even) {
    padding-left: 15px;
  }
}

.listItemInner {
  & > div {
    margin-bottom: 5px;
  }
}

.listItemCost {
  display: flex;
  font-weight: $font-weight-semibold;
  position: relative;

  > *:first-child {
    margin-right: auto;
  }
}

.editButton {
  position: absolute;
  right: -20px;
  top: -4px;
}

.lighterText {
  color: $color-gray-dark;
}
