@import 'styles/variables';

.item {
  background-color: $color-white;
  cursor: pointer;
  display: block;
  position: relative;

  > *:last-child {
    padding-bottom: 0;
  }
}

.isButton {
  &:hover {
    background-color: $color-surface-lightest;
  }

  &:active {
    background-color: $color-green-lightest;
  }
}

.footer {
  background-color: $color-green;
  color: $color-white;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  line-height: 18px;
  height: 18px;
  width: 100%;
  padding: 0 15px;
  overflow: hidden;
  white-space: nowrap;
}
