@import 'styles/variables';

$button-height: 40px;

// Not restricted to just <button>s
.button {
  cursor: pointer;
  padding: 0;
  border: 0;
  background: transparent;
  font-weight: $font-weight-semibold;

  &.active,
  &:active {
    outline: 0;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    cursor: not-allowed;
  }
}

.theme-base {
  // All the common rules for buttons that _look_ like buttons.
  // Making this a modifier class means that the default Button can be easily styled without needing to reset the theme rules.
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background-color: $color-white;
  border-radius: $border-radius-small;
  border: 1px solid $color-green;
  box-shadow: none;
  color: $color-green;
  display: flex;
  fill: $color-green;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  justify-content: center;
  letter-spacing: $letter-spacing-medium;
  min-height: var(--button-height, $button-height);
  min-width: 96px;
  padding: 0 $spacing-2x;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  &.hover,
  &:hover {
    background-color: $color-green;
    border-color: transparent;
    color: $color-white;
    fill: $color-white;
  }

  &.active,
  &:active {
    background-color: $color-green-dark;
    border-color: transparent;
    color: $color-white;
    fill: $color-white;
  }

  &:disabled,
  &:disabled.hover,
  &:disabled:hover,
  &:disabled.active,
  &:disabled:active {
    cursor: not-allowed;
    color: $color-gray-dark;
    fill: $color-gray-dark;
    background-color: $color-gray-lighter;
    border-color: $color-gray-lighter;
    opacity: 0.5;
  }
}

.attached {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.outline {
  background-color: $color-white;

  &:disabled,
  &:disabled.hover,
  &:disabled:hover,
  &:disabled.active,
  &:disabled:active {
    cursor: not-allowed;
    color: $color-gray-light;
    fill: $color-gray-light;
    background-color: $color-white;
    border-color: $color-gray-light;
  }
}

.theme-black,
.theme-black-attached {
  background-color: $color-gray-darker;
  border-color: transparent;
  color: $color-white;

  &.hover,
  &:hover {
    background-color: $color-gray-darkest;
  }

  &.active,
  &:active {
    background-color: $color-black;
  }
}

.theme-blue,
.theme-blue-attached {
  background-color: $color-blue;
  border-color: transparent;
  color: $color-white;

  &.hover,
  &:hover {
    background-color: $color-blue-dark;
  }

  &.active,
  &:active {
    background-color: $color-blue-darker;
  }
}

.theme-blue-outline {
  background-color: $color-white;
  border-color: $color-blue;
  color: $color-blue;
  fill: $color-blue;

  &.hover,
  &:hover {
    background-color: $color-blue-lightest;
    border-color: $color-blue;
    color: $color-blue;
  }

  &.active,
  &:active {
    background-color: $color-blue-lighter;
    border-color: $color-blue;
    color: $color-blue;
  }
}

.theme-green,
.theme-green-attached {
  background-color: $color-green;
  border-color: transparent;
  color: $color-white;

  &.hover,
  &:hover {
    background: $color-green-dark;
  }

  &.active,
  &:active {
    background: $color-green-darker;
  }
}

.theme-green-outline {
  background-color: $color-white;
  border-color: $color-green;
  color: $color-green;
  fill: $color-green;

  &.hover,
  &:hover {
    background-color: $color-green-lightest;
    border-color: $color-green;
    color: $color-green;
  }

  &.active,
  &:active {
    background-color: $color-green-lighter;
    border-color: $color-green;
    color: $color-green;
  }
}

.theme-red,
.theme-red-attached {
  background-color: $color-red;
  border-color: transparent;
  color: $color-white;
  fill: $color-white;

  &.hover,
  &:hover {
    background-color: $color-red-dark;
  }

  &.active,
  &:active {
    background-color: $color-red-darker;
  }
}

.theme-red-outline {
  background-color: $color-white;
  border-color: $color-red;
  color: $color-red;
  fill: $color-red;

  &.hover,
  &:hover {
    background-color: $color-red-lightest;
    border-color: $color-red;
    color: $color-red;
  }

  &.active,
  &:active {
    background-color: $color-red-lighter;
    border-color: $color-red;
    color: $color-red;
  }
}

.theme-gray-outline {
  background-color: $color-white;
  border-color: $color-gray-lighter;
  color: $color-gray-darkest;
  fill: $color-gray-darkest;

  &.hover,
  &:hover {
    background-color: $color-gray-lightest;
    border-color: $color-gray-lighter;
    color: $color-gray-darkest;
    fill: $color-gray-darkest;
  }

  &.active,
  &:active {
    background-color: $color-gray-lighter;
    border-color: $color-gray-light;
    color: $color-gray-darkest;
    fill: $color-gray-darkest;
  }
}

.theme-gray-attached {
  @extend .theme-gray-outline;
  background-color: $color-surface-lightest;
  border-color: transparent;
  border-top: 1px solid $color-gray-lighter;

  &.hover,
  &:hover {
    background-color: $color-gray-lightest;
    border-color: transparent;
    border-top: 1px solid $color-gray-lighter;
  }

  &.active,
  &:active {
    background-color: $color-gray-light;
    border-color: transparent;
    border-top: 1px solid $color-gray-light;
  }

  &:disabled,
  &:disabled.hover,
  &:disabled:hover,
  &:disabled.active,
  &:disabled:active {
    background-color: $color-surface-lightest;
    border-color: transparent;
    border-top: 1px solid $color-gray-light;
    color: $color-gray-light;
  }
}

.theme-yellow,
.theme-yellow-attached {
  background-color: $color-yellow;
  border-color: transparent;
  color: $color-white;

  &.hover,
  &:hover {
    background-color: $color-yellow-dark;
  }

  &.active,
  &:active {
    background-color: $color-yellow-darker;
  }
}

.theme-yellow-outline {
  background-color: $color-white;
  border-color: $color-yellow;
  color: $color-yellow;
  fill: $color-yellow;

  &.hover,
  &:hover {
    background-color: $color-yellow-lightest;
    border-color: $color-yellow;
    color: $color-yellow;
  }

  &.active,
  &:active {
    background-color: $color-yellow-lighter;
    border-color: $color-yellow;
    color: $color-yellow;
  }
}

.theme-white {
  border-color: transparent;
  color: $color-gray-darker;
  fill: $color-gray-darker;

  &.hover,
  &:hover {
    background-color: $color-gray-lightest;
    border-color: transparent;
    color: $color-gray-darker;
    fill: $color-gray-darker;
  }

  &.active,
  &:active {
    background-color: $color-gray-lighter;
    border-color: transparent;
    color: $color-gray-darker;
    fill: $color-gray-darker;
  }
}
