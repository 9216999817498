@import 'styles/variables';

.glyph {
  border-radius: 20px;
  color: $color-gray-dark;
  cursor: pointer;
  fill: $color-white;
  flex-shrink: 0;
  height: 12px;
  margin: 3px 0 0 auto;
  width: 12px;
}

.tooltipText {
  font-size: $font-size-12;
}
