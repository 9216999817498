@import 'styles/variables';

.accept {
  letter-spacing: $letter-spacing-big;
  margin: 0;
  max-width: 160px;
  min-height: 32px;
  min-width: 100px;
  width: 100%;
}

.counter {
  letter-spacing: $letter-spacing-big;
  max-width: 160px;
  min-height: 32px;
  min-width: 100px;
  width: 100%;
}

.statusMessage {
  align-items: center;
  border-radius: $border-radius;
  background-color: $color-surface-lightest;
  border: 1px solid $color-gray-dark;
  color: $color-gray-dark;
  display: flex;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  justify-content: center;
  letter-spacing: 1px;
  padding: 5px $spacing;
  text-align: center;
  text-transform: uppercase;
  min-width: 160px;
}
