@import 'styles/variables';

.checkMd {
  width: 16px;
  height: 16px;
}

.checkSm {
  width: 12px;
  height: 12px;
}

.checkXs {
  width: 8px;
  height: 8px;
}

.checkRounded {
  background: $color-white;
  color: $color-green;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 4px;
}
