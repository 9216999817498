@import 'styles/variables';
@import 'components/sections/auth/auth';

.title {
  font-size: $font-size-22;
}

.supportText {
  margin-top: $spacing-4x;
}

.panel {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;

  .label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    margin-bottom: $spacing-half;
  }

  .subLabel {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
  }

  .panelInput {
    height: 40px;
    margin: $spacing-half 0 $spacing;
    width: 100%;
  }

  & + .panelRadio {
    margin-top: $spacing-3x;
  }

  &:has(.panelInput) {
    margin-top: $spacing;
  }
}

.codeInput {
  margin: $spacing-2x 0;
  justify-content: center;
}

.linkButton {
  font-weight: $font-weight-semibold;
  margin: 0 auto;
}

.backButton {
  margin-top: -$spacing;
}
