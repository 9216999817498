@import 'styles/variables';

.statGroup {
  align-items: center;
  display: flex;
  gap: $spacing-5x;
}

.statTotal {
  align-items: flex-start;
}
