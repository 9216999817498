@import 'styles/variables';

.container {
  display: grid;
  gap: 5px;
}

.timestamp {
  color: $color-gray-dark;
  font-size: $font-size-10;
  padding-top: $spacing-half;
  white-space: nowrap;
}

.timestampMine {
  display: flex;
  justify-content: right;
  margin-right: 5px;
}

.timestampOther {
  display: flex;
  justify-content: left;
  margin-left: 5px;
}

.messageBubbleWrapper {
  &:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.message {
  display: block;
  background-color: $color-green-light;
  border-radius: 10px;
  color: $color-gray-darkest;
  font-size: $font-size-12;
  padding: $spacing * 1.5;
  position: relative;
  width: fit-content;
  max-width: 80%;
  word-wrap: break-word;

  &::before {
    // draw the message tail
    border-radius: 800% 0 0 50%;
    border: 0 solid $color-green-light;
    border-top-width: 10px;
    border-left-width: 2px;
    bottom: -1px;
    content: '';
    height: 6px;
    left: -1px;
    position: absolute;
    transform: rotate(10deg);
    width: 10px;
  }
}

.myMessage {
  .message {
    background-color: $color-gray-lightest;
    margin-left: auto;
    margin-right: 0;

    &::before {
      border-color: $color-gray-lightest;
      left: auto;
      right: -1px;
      transform: rotate(-10deg) scaleX(-1);
    }
  }

  .broadcastMessage {
    background-color: $color-blue-light;
    margin-left: auto;
    margin-right: 0;

    &::before {
      border-color: $color-blue-light;
      left: auto;
      right: -1px;
      transform: rotate(-10deg) scaleX(-1);
    }
  }
}

.broadcastIcon {
  position: relative;
  float: right;
  fill: $color-gray-light;
  top: -2px;
  height: 9px;
  width: 9px;
  min-width: 9px;
}
