@import 'styles/variables';
@import '../conditionReport';

.container {
  border-bottom: none;
  composes: crRow;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
}

.divider {
  border-right: 1px solid $color-surface-lighter;
  margin: 0 $spacing-2x;
}
