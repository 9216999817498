@import 'styles/variables';
@import './facetGroups';

.dateRangeFacetGroup {
  margin: 25px 0;

  > .heading {
    margin-bottom: 10px;
  }

  .valueLabel {
    text-align: right;
  }
}

.dateRanges {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-12;
  color: $color-gray-light;

  > span {
    padding: 0 10px;
  }

  > div:first-child {
    :global {
      // stylelint-disable
      .SingleDatePicker_picker {
        left: -15px !important;
      }
      // stylelint-enable
    }
  }

  > div:last-child {
    :global {
      // stylelint-disable
      .SingleDatePicker_picker {
        left: -125px !important;
      }
      // stylelint-enable
    }
  }
}
