@import 'styles/variables';

.innerContainer {
  position: relative;
}

.chevron {
  position: absolute;
  top: 0;
  right: 0;
}
