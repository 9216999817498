@import 'styles/variables';

.notificationText {
  display: flex;
}

.glyph {
  color: $color-gray-dark;
  height: 20px;
  margin: 3px 0 0 auto;
  width: 20px;
}

.content {
  color: $color-gray-light;
  font-size: $font-size-12;
  margin-left: 13px;
  line-height: $line-height;
}
