@import 'styles/variables';

.container {
  display: flex;
}

.steps {
  display: inline-flex;
  margin: 0 auto;
  position: relative;
  width: min-content;

  &::before {
    background-color: $color-surface-lighter;
    content: '';
    height: 3px;
    left: $spacing;
    position: absolute;
    top: 50%;
    transform: translateY(-1.5px);
    width: calc(100% - #{$spacing-2x});
  }
}

.step {
  align-items: center;
  background-color: $color-surface-lighter;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  font-size: $font-size-12;
  height: 24px;
  justify-content: center;
  margin: 0 $spacing;
  position: relative;
  width: 24px;
}

.step-theme-green {
  background-color: $color-green;
}

.step-theme-blue {
  background-color: $color-blue;
}

.activeStep {
  pointer-events: none;
}
