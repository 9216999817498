@import 'styles/variables';

.row {
  align-items: center;
  border-bottom: 1px solid $color-gray-lighter;
  display: grid;
  grid-template-columns: 40% 60%;
  height: 33px;
  padding: 0 $spacing-2x;
  min-width: 240px;
}

.title {
  color: $color-gray-dark;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
}

.subtitle {
  color: $color-gray-darker;
  font-size: 16px;
  font-weight: $font-weight-bold;
}

.editButton {
  background-color: $color-blue;
  border-radius: 50%;
  color: $color-white;
  height: 16px;
  margin-left: $spacing;
  width: 16px;
}

.inputContainer {
  height: 20px;
  transform: translateY(-7px);
}

.input {
  > input[type='number'] {
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    text-align: right;
    width: 75px;
  }
}
