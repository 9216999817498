@import 'styles/variables';

.input {
  align-items: center;
  border-radius: $border-radius-small;
  border: 1px solid $color-gray-lighter;
  display: flex;
  font-size: $font-size-14;
  height: 40px;
  justify-content: center;
  user-select: none;
  // `!important` required to overwrite the library's inline styles
  width: 40px !important; // stylelint-disable-line

  &::selection {
    // Disable weird text selection from react-otc-input
    background: transparent;
  }

  &:focus {
    background: $color-gray-lighter;
  }

  &:not(:last-child) {
    margin-right: $spacing;
  }
}
