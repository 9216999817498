@import 'styles/variables';

$activeBorderHeight: 2px;

.anchor {
  color: $color-gray-dark;
  font-size: $font-size-12;
  line-height: initial;
  margin: $activeBorderHeight 0;
}

.active {
  border-bottom: $activeBorderHeight solid $color-blue;

  .anchor {
    color: $color-blue;
    margin-bottom: 0;
  }
}

.isSticky {
  position: sticky;
  top: 0;
  z-index: 1;

  &.compactHeader {
    top: $header-compact-height;
  }

  &.fullHeader {
    top: $header-full-height;
  }

  &.liveLanesHeader {
    top: $header-live-lanes-height;
  }
}

.tab {
  align-items: center;
  display: flex;
  flex: none;
  justify-content: center;
  margin: 0 $spacing;
}

.tabs {
  display: flex;
  height: 40px;
  overflow: hidden;
  overflow-x: auto;
  padding: 0 $spacing-2x;
  position: relative;
}

.tabsContainer {
  background: $color-white;
  border-bottom: 1px solid rgba($color-line, 0.3);
  border-top: 1px solid rgba($color-line, 0.3);
}

.tabsInnerContainer {
  position: relative;

  &::before {
    background: linear-gradient(to right, $color-white 30%, transparent);
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: $spacing-3x;
    z-index: 1;
  }

  &::after {
    background: linear-gradient(to left, $color-white 30%, transparent);
    content: ' ';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: $spacing-3x;
    z-index: 1;
  }
}
