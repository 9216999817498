@import 'styles/variables';

.topOfferIcon {
  position: relative;
  margin: 0 10px;
  height: 16px;
  width: 16px;
  min-width: 16px;
}

.bids,
.ifBids {
  &.green {
    .topOfferIcon {
      color: $color-green;
    }

    .topOfferAmount {
      color: $color-green;
    }
  }

  &.red {
    .topOfferIcon {
      color: $color-red;
    }

    .topOfferAmount {
      color: $color-red;
    }
  }
}
