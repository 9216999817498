@import 'styles/variables';

.buttonContainer {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 5px;
  border-radius: $border-radius-small;
  border: 1px solid $color-gray-lighter;
  cursor: pointer;

  &:hover {
    background: $color-surface-lighter;
  }
}

.noEditContainer {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 5px 5px 5px 0;
}

.saleLight {
  align-items: center;
  background-color: $color-gray-lighter;
  border-radius: 50%;
  color: $color-gray-dark;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
  height: 20px;
  justify-content: center;
  margin: 2px;
  padding-top: 1px;
  width: 20px;
}

.red {
  background-color: $color-red;
  color: $color-white;
}

.green {
  background-color: $color-green;
  color: $color-white;
}

.yellow {
  background-color: $color-yellow;
  color: $color-white;
}
