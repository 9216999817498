@import 'styles/variables';

.inputContainer {
  position: relative;
}

.dollar,
.percent {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 3px;
  min-width: 28px;
  min-height: 24px;
  padding: 0;
  font-size: 13px;
  font-weight: $font-weight-extrabold;

  &.hasLabel {
    top: 29px;
  }
}

.dollar {
  right: 38px;
}
