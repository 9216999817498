@import 'styles/variables';

:global {
  #portal-root {
    left: 0;
    position: absolute;
    top: 0;
    z-index: $zIndexOverDialog;
  }
}
