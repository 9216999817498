@import './variables';
@import './typography';

:root {
  --system-navigation-height-single: 45px;
  --system-navigation-height-full: 90px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  flex-shrink: 1; // https://github.com/luisrudge/postcss-flexbugs-fixes#bug-6
}

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family, sans-serif; /* 1 */
  font-weight: $font-weight-regular;
  font-size: $base-font-size;
  letter-spacing: $letter-spacing-small;
  color: $color-text;
}

/* remove scroll bounce */
html,
body {
  height: 100%;
  overflow: hidden;
  background-color: $color-surface-lightest;
}

a,
a:active,
a:hover {
  text-decoration: none;
  color: $color-blue;
}

/* Forms
   ========================================================================== */

button,
input,
optgroup,
select,
textarea,
a {
  // TODO: Let's come back to this; it's AODA no-no.
  outline: none;
  font-family: $font-family, sans-serif;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

// Remove arrows on on number inputs in Chrome
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

// Remove arrows on on number inputs in Firefox
input[type='number'] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}

/**
 * Reset default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

figure {
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
}

/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  list-style: none;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;

  > div {
    width: 100%;
  }
}

/* Text
   ========================================================================== */

:global {
  .ellipsis {
    @extend %ellipsis;
  }
}
