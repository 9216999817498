@import 'styles/variables';

.statuses {
  width: 242px;

  & > div {
    border-bottom: 1px solid $color-gray-lighter;
  }
}

.row {
  display: flex;
  height: 33px;
  justify-content: space-between;
  padding: $spacing * 1.5;
}

.label {
  color: $color-gray-dark;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
}

.value {
  color: $color-gray-darker;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;

  &.black {
    color: $color-gray-darker;
  }

  &.green {
    color: $color-green;
  }

  &.red {
    color: $color-red-dark;
  }

  &.yellow {
    color: $color-yellow-dark;
  }
}
