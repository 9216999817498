@import 'styles/variables';

.auctionReps {
  font-size: $font-size-12;

  span {
    color: $color-gray-dark;
  }

  &:last-child {
    margin-bottom: -$spacing;
  }
}
