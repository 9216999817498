@import 'styles/variables';
@import './facetGroups';

.buttonsFacetGroup {
  margin: 25px 0;

  > .heading {
    margin-bottom: 10px;
  }

  .valueLabel {
    text-align: right;
  }

  .buttons {
    display: flex;
    flex-direction: row;

    .button {
      flex: 1;
      height: 40px;
      background-color: $color-white;
      border: 1px solid #efefef;
      border-radius: 5px;
      font-weight: $font-weight-semibold;
      color: $color-gray-dark;
      font-size: 11px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;
      }

      &.selected {
        background-color: $color-blue;
        color: $color-white;
      }
    }
  }
}
