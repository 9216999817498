@import 'styles/variables';

.container {
  height: 100%;
  overflow-y: auto;
  padding: $spacing;
  display: grid;

  .innerContainer {
    margin-top: auto;
  }
}
