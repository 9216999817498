@import 'styles/variables';

$input-border: 1px solid $color-gray-lighter;

.inputGroup {
  &:only-child {
    flex: 1 1 auto;
  }

  &.checkbox:last-child {
    .checkbox {
      margin-bottom: 0;
    }
  }

  &.multiCheckbox {
    display: flex;
    flex-flow: row wrap;
  }

  label {
    padding-top: 0;
  }
}

.hasMargin {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.checkbox {
  margin-top: 0;
}

.textarea {
  margin-top: 0;
  min-height: 162px;
}

.inputText,
.calendarGroup,
.dropdown,
.dropdownCustom,
.inputGroupSelect {
  /* Max width of 33.33% (rounded down to px int) */
  width: 273px;
}

.inputText {
  border: $input-border;

  input::placeholder {
    color: $color-black-50-percent;
  }
}

.dropdown {
  margin-top: 0;
}

.inputGroupSelect {
  height: $input-height;
  font-size: $font-size-11;
  margin-top: 0;
}

.dropdownPlaceholder {
  color: $color-black-50-percent;
}

.dropdownMenu {
  max-height: 124px;
}

.dropdownBase {
  padding: 10px $spacing-3x 10px 12px;
  height: $input-height;
}

.dropdownBase,
.dropdownMenu,
.dropdownPlaceholder {
  font-size: $font-size-11;
}

.calendarIcon {
  width: 12px;
  height: 12px;
  fill: $color-gray-light;
  pointer-events: none;
  top: calc(50% - 6px);
  z-index: $zIndex1;
}

.radioGroup {
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 15px;
  }
}

.radioGroupText {
  font-size: $font-size-9;
}

%date-picker-input {
  border: $input-border;
  border-radius: 3px;
  height: $input-height;
  font-size: $font-size-11;

  &::placeholder {
    color: $color-black-50-percent;
  }
}

:global {
  .datePickerContainer {
    .DateInput {
      width: 100%;
    }

    .SingleDatePicker,
    .SingleDatePickerInput {
      width: 100%;
    }

    .DateInput_input {
      @extend %date-picker-input;
    }
  }

  .dateRangePickerContainer {
    .DateRangePickerInput {
      @extend %date-picker-input;
    }
  }
}
