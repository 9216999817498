@import 'styles/variables';

.chevron {
  height: 8px;
  width: 5px;
  fill: $color-gray-darkest;
}

.chevronFilled {
  position: relative;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-top: $triangle-width solid transparent;
    border-bottom: $triangle-width solid transparent;
    border-left: $triangle-width solid $color-black;
    position: absolute;
    top: calc(50% - #{$triangle-width});
    right: calc(-#{$triangle-width} / 2);
  }
}
