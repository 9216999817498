@import 'styles/variables';

.button {
  margin: 0;
  pointer-events: none;
}

.buttonContainer {
  cursor: not-allowed;
}
