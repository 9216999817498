@import 'styles/variables';
@import 'styles/typography';

$item-height: 80px;

.vehicleDetails {
  display: flex;
  gap: $spacing-2x;
  padding: $spacing-2x 0;
}

.image {
  height: 100%;
  width: 100%;
}

.imageContainer {
  height: $item-height;
  position: relative;
  width: $item-height;
}

.vehicleDetailsContent {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: $spacing;
}

.title {
  overflow: auto;
}

.yearMake {
  color: $color-gray-darkest;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  @extend %ellipsis;
}

.modelTrim {
  color: $color-gray-darkest;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  @extend %ellipsis;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: $spacing;
  padding: $spacing 0;
}

.mileage {
  color: $color-gray-darker;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
}

.badges {
  --badge-size: 24px;
}

.vin {
  background-color: $color-gray-lighter;
  color: $color-gray-darker;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: 14px;
  width: fit-content;
}

.statusLabel {
  color: $color-white;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: 14px;
  width: fit-content;

  &.AWAITING_CHECKOUT {
    background-color: $color-blue;
  }

  &.IN_IF_BID {
    background-color: $color-yellow-darkest;
  }

  &.NO_SALE {
    background-color: $color-red-dark;
  }

  &.SOLD {
    background-color: $color-green-dark;
  }

  &.UPCOMING {
    background-color: $color-gray-darker;
  }
}
