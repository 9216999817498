@import 'styles/variables';

$base-padding: 12px;

.currentOfferCounter {
  color: $color-blue;
  font-size: $font-size-32;
  padding-bottom: 17px;
}

.amount {
  border-color: $color-gray-lighter;
}

.instructions {
  padding-bottom: $base-padding;
}

.yourAutobid {
  color: $color-text;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  padding-bottom: $base-padding;

  .autoBidAmount {
    color: $color-blue;
    padding-left: 3px;
  }
}

.selectContainer {
  margin-top: $base-padding;

  .select {
    margin-bottom: 0;
  }
}

.errorMessage {
  color: $color-red;
}
