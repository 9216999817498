@import 'styles/variables';

.container {
  border-bottom: 1px solid $color-gray-lighter;
  border-top: 1px solid $color-gray-lighter;
  color: $color-gray-darkest;
  margin: $spacing-2x 0;
  padding: $spacing-2x 0;

  &:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

.header {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  padding-bottom: $spacing-2x;
}

.values {
  display: flex;
  flex-flow: row wrap;
}

.scrollable {
  max-height: 172px;
  padding: $spacing-2x 0;
}
