@import 'styles/variables';

.listItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-12;
  color: $color-gray-dark;
  padding: 12px 0;
  border-bottom: 1px solid $color-surface-lighter;

  &:last-child {
    border-bottom: none;
  }

  .price {
    text-align: right;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    color: $color-text;

    &.green {
      color: $color-green;
    }
  }

  .accentuate {
    color: $color-text;
  }
}

.summarySection {
  margin-bottom: 10px;
  padding-bottom: 5px;
}
