@import 'styles/variables';

.container {
  background: $color-surface-lightest;
  border-radius: $border-radius;
  display: flex;
  padding: 15px;
  text-align: left;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.containerDisabled {
  opacity: 0.4;
}

.containerSelected {
  background: $color-green-lightest;
}

.content {
  flex: 1;
  font-size: $font-size-12;
}

.dot {
  background: transparent;
  border-radius: 50%;
  border: 1px solid $color-gray-lighter;
  flex-shrink: 0;
  height: 12px;
  margin-left: 15px;
  width: 12px;
}

.dotSelected {
  background: $color-green;
  border: none;
}

.checkbox {
  background: transparent;
  border-radius: 4px;
  border: 1px solid $color-gray-lighter;
  flex-shrink: 0;
  height: 12px;
  margin-left: 15px;
  width: 12px;
}

.checkboxSelected {
  background: $color-green;
  border: none;
}

.description {
  color: $color-gray-light;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  margin-top: 10px;
}
