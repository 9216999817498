@import 'styles/variables';

.sectionWindow {
  background-color: $color-surface-lighter;
  display: grid;
  width: 100%;
}

.buttonContainer {
  background-color: $color-white;
  display: flex;
  height: 3px;
  justify-content: center;
  position: absolute;
  top: -3px;
  width: 100%;
}

.bottomContainer {
  height: 100%;
  margin-bottom: -20px;
  position: relative;

  & > div:first-child::before {
    background: linear-gradient($color-surface-lightest, transparent 50%);
    content: '';
    height: $spacing-3x;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.arrowButton {
  color: $color-white;
  pointer-events: all;
  overflow: visible;
  height: 24px;
  width: 24px;
  margin-top: -10px;

  .arrowGlyph {
    fill: $color-gray-dark;
    height: 9px;
    overflow: visible;
    transform: rotate(90deg);
    width: 9px;

    &.down {
      transform: rotate(-90deg);
    }
  }
}

.circle {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  margin-bottom: -10px;
  margin-top: -10px;
  margin-left: 2px;
  padding-top: 1px;
}
