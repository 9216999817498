@import 'styles/variables';

.listContainer {
  flex: 1;
}

.noResults {
  background-color: $color-white;
}

.column {
  height: 100%;
}
