@import 'styles/variables';
@import 'styles/typography';

:global {
  // Remove X button from search inputs on Chrome
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

.searchInput {
  position: relative;
  display: flex;
  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-input-border;
}

.input {
  @extend %ellipsis;
  background: transparent;
  border: none;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: $font-size-13;
  line-height: 12px;
  padding: $input-vertical-padding #{2 * $input-horizontal-padding} $input-vertical-padding $input-horizontal-padding;
  resize: none;
  width: 100%;

  &::placeholder {
    color: $color-gray-dark;
  }
}

.auxContainer {
  display: flex;
  align-items: center;
  padding-right: $spacing * 1.5;
  position: relative;
}

.sprite {
  fill: $color-gray-dark;
  height: 13px;
  pointer-events: none;
  width: 13px;
}

.cancelButton {
  font-size: $font-size-12;
  height: 100%;

  &:hover {
    opacity: 0.6;
  }
}

.cancelButtonIcon {
  align-items: center;
  color: $color-gray-darkest;
  display: flex;
  height: 15px;
  justify-content: center;
  width: 15px;

  svg {
    width: 15px;
    height: 15px;
    fill: $color-gray-darkest;
  }
}

.searchIcon {
  display: flex;
  align-items: center;
  padding-left: $spacing-2x;
}

.loading {
  width: 18px;
  height: 18px;
}

.submitButton {
  margin-left: $spacing;
  min-height: unset;
  min-width: unset;
  padding: 0 $spacing;
}
