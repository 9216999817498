@import 'styles/variables';
@import 'styles/typography';

.button {
  align-items: center;
  color: inherit;
  display: flex;
  font-weight: inherit;
  margin-top: $spacing-half;
  padding-right: 10px;
  position: relative;
  max-width: 300px;
}

.text {
  @extend %ellipsis;
}

.glyph {
  height: 14px;
  margin-left: $spacing;
  width: 14px;
  flex-shrink: 0;
}
