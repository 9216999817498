@import 'styles/variables';

.button {
  align-items: center;
  color: $color-blue;
  display: flex;
  fill: $color-blue;
  flex-direction: column;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  padding-top: 5px;
  position: relative;
  width: 100%;
}

.buttonContent {
  align-items: center;
  display: flex;

  svg {
    margin-left: 2px;
  }
}

.link {
  height: 7px;
  width: 7px;
}
