@import 'styles/variables';

$base-padding: 12px;

.instructions {
  padding: 0 0 $base-padding;
  line-height: 1.4;
}

.inputGroup {
  padding: 0;

  .amount {
    font-size: $font-size-11;
    border-color: $color-gray-lighter;

    &:not(:last-child) {
      margin-bottom: $base-padding;
    }
  }

  .inputError {
    border-color: $color-red;
  }
}

.select {
  margin: 0;
  font-size: $font-size-11;
}
