@import 'styles/variables';
@import 'styles/typography';

$defaultBadgeSize: 13px;
$defaultFontSize: 0.5625em; // $font-size-9, relative to component root for scalability
$defaultGap: 5px;

.badges {
  align-items: center;
  display: flex;
  font-size: var(--badge-size, $defaultBadgeSize);
  gap: var(--badge-gap, $defaultGap);
  line-height: 1;
}

.badge {
  user-select: none;

  svg {
    height: 100%;
    width: 100%;
  }
}

.outlinedBadge {
  align-items: center;
  background: $color-white;
  border-radius: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.1666);
  border: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.0833) solid $color-gray-darkest;
  display: inline-flex;
  flex-shrink: 0;
  font-size: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.5);
  font-weight: $font-weight-semibold;
  height: var(--badge-size, $defaultBadgeSize);
  justify-content: center;
  padding: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.3333);
}

.archivedOutline {
  border-color: $color-red;
  padding: 0;
  width: var(--badge-size, $defaultBadgeSize);
}

.archived {
  color: $color-red;
  height: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.583);
  width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.583);
}

.asIs {
  align-items: center;
  border-radius: 17px;
  display: flex;
  font-family: $font-proxima-nova;
  font-size: var(--badge-font-size, $defaultFontSize);
  font-weight: $font-weight-semibold;
  padding: $spacing-half 10px;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;

  &.red {
    background: $color-red;
    color: $color-white;
  }

  &.white {
    background: $color-white;
    color: $color-black;
    mix-blend-mode: screen;
  }
}

.autobidOutlined {
  background-color: $color-white;
  border-color: $color-blue;
  color: $color-blue;
}

.autobid {
  height: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.8333);
  min-width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.8333);
  width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.8333);
}

.autobidA {
  font-size: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.5);
  font-weight: $font-weight-bold;
  margin-right: $spacing-half;
}

.autogradeScore {
  color: $color-text;

  svg {
    height: 9px;
    width: 9px;
  }

  & > div {
    margin-left: $spacing-half;
  }
}

.buyerShield {
  height: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.75);
  min-width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.7029);
  width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.7029);
}

.holdback {
  color: $color-gray-darkest;

  svg {
    height: calc(var(--badge-size, #{$defaultBadgeSize}) / 2);
    width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.4354);
  }
}

// @deprecated
.holdbackActive {
  align-items: center;
  background-color: $color-green;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  font-size: var(--badge-font-size, $defaultFontSize);
  font-weight: $font-weight-bold;
  justify-content: center;
  padding-top: 1px;
}

.reranOutlined {
  background-color: $color-white;
  border-color: $color-red;
  color: $color-red;

  .reranGlyph {
    height: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.4);
    margin-right: $spacing-half;
    width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.4);
  }
}

.reserve {
  background: $color-gray-lightest;
  border-color: $color-gray-darkest;
  color: $color-gray-darkest;
}

.reserveMet {
  background: $color-green-lightest;
  border-color: $color-green;
  color: $color-gray-darkest;
}

.reserveMetText {
  font-size: var(--badge-font-size, $defaultFontSize);
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-medium;
  line-height: 1;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
}

.runNumber {
  background-color: $color-white;
  border-color: $color-gray-darkest;
  color: $color-gray-darkest;
}

.unreserved {
  background-color: $color-green-lightest;
  border-color: $color-green;
  color: $color-gray-darkest;
}

.verified {
  color: $color-green;
  fill: $color-green;
  height: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.8333);
  min-width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.7975);
  width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.7975);
}

.warning {
  fill: $color-red;
}

.watchingOutlined {
  background-color: $color-white;
  border-color: $color-yellow;
  color: $color-yellow;
}

.watchingGlyph {
  height: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.352);
  margin-right: $spacing-half;
  width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.352);
}

.notesOutline {
  padding: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.1666);
}

.note {
  color: $color-gray-darkest;
  height: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.6375);
  width: calc(var(--badge-size, #{$defaultBadgeSize}) * 0.5125);
}

.labelBadgeContainer {
  align-items: center;
  display: flex;
  font-size: var(--badge-size, $defaultBadgeSize);
  height: var(--badge-size, $defaultBadgeSize);
}

.labelBadge {
  font-size: var(--badge-size, $defaultBadgeSize);
  height: var(--badge-size, $defaultBadgeSize);
}
