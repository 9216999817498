@import 'styles/variables';
@import 'styles/typography';

.listContainer {
  display: flex;
  flex-flow: row nowrap;
  height: calc(100vh - var(--system-navigation-height-full));
}

.detailsContainer {
  background: $color-white;
  flex: 1;
  min-width: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.detailsSection {
  text-align: left;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($color-line, 0.3);
  }
}

.detailsSectionContainer {
  padding: $spacing-3x;
}

.detailsSectionInnerContainer {
  min-width: 275px;
  max-width: 750px;
}

.detailsSectionUpdatingSpinner {
  margin: 20px auto 0;
}

.detailsSectionHeader {
  display: flex;
  position: relative;
  width: 100%;

  & + .detailsSectionContent:not(:empty) {
    margin-top: 5px;
    position: relative;

    &.hasContentPadding {
      padding-top: 13px;
    }
  }
}

.detailsSectionAuxButton {
  font-size: $font-size-14;
  margin-left: auto;
  padding: 0;
  width: auto;

  > svg {
    top: 3px;
  }
}

.detailsSectionEmpty {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-weight: $font-weight-bold;
  font-size: $font-size-18;
  color: $color-gray-light;
}

.detailsSectionTitle {
  @extend .type-section-title;

  @extend %ellipsis;
}

.detailsSectionSubButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: $spacing-2x;

  .sprite {
    color: $color-blue;
    height: 12px;
    width: 12px;
  }
}
