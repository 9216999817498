@import 'styles/typography';
@import 'styles/variables';

.container {
  padding: 15px 25px;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid $color-input-border;
  }
}

.section {
  margin: 10px 0;
}

.title {
  margin-bottom: 10px;
}

.addOnItem {
  background: $color-surface-lightest;
  border-radius: $border-radius;
  padding: 12px;
  width: 100%;

  .addOnItemRow {
    display: flex;
    justify-content: space-between;

    > div {
      color: $color-gray-darkest;
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.addOnItemPickupLocation {
  margin-top: 5px;
}

.comments {
  @extend .type-body-2;
}
