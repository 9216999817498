@import 'styles/variables';

$base-padding: 12px;

.instructions {
  padding-bottom: $base-padding;
}

.inputGroup {
  padding: 0;
}

.select {
  margin: 0 0 10px;
  font-size: $font-size-11;

  &:last-child {
    margin-bottom: 0;
  }
}

.errorMessage {
  color: $color-red;
  padding-top: 2 * $base-padding;
}
