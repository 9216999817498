@import 'styles/variables';

.container {
  display: flex;
}

.hasMarginTop {
  // TODO: This is legacy and should eventually be removed
  margin-top: 18px;
}

input[type='checkbox'] {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  user-select: none;
  padding-right: 25px;
  cursor: pointer;
  flex-grow: 1;
}

.outerCheckbox {
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid $color-gray-lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $block-border-radius;
  flex-shrink: 0;
}

.innerCheckbox {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.check {
    display: none;
    fill: $color-white;
    width: 8px;
  }

  &.indeterminate::after {
    content: ' ';
    position: relative;
    display: block;
    width: 8px;
    height: 2px;
    background: $color-white;
  }
}

.text {
  display: block;
  font-size: $font-size-11;
  line-height: initial;
  color: $color-black-50-percent;
  padding-left: 10px;
}

.button {
  border-radius: $block-border-radius;
  font-size: $font-size-8;
  font-weight: $font-weight-extrabold;
  letter-spacing: $letter-spacing-medium;
  padding: 7.5px 14px;
  margin: 0 10px 10px 0;
  color: $color-white;
  background: $color-gray-light;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

.buttonContainer {
  margin-top: 0;
}

input[type='checkbox']:checked + label {
  .outerCheckbox {
    border-color: $color-green;

    &.blue {
      border-color: $color-blue;
    }
  }

  .innerCheckbox {
    background: $color-green;

    &.blue {
      background-color: $color-blue;
    }

    &.check {
      display: block;
    }
  }

  .text {
    color: $color-black;
  }

  .button {
    background: $color-green;
    border-color: $color-green;
  }

  .red {
    background: $color-red;
    border-color: $color-red;
  }

  .check {
    background: $color-blue;
    border-color: $color-blue;
  }

  .disabled {
    border-color: rgba($color-green, 0.5);
  }

  .disabledInnerCheckbox {
    background: rgba($color-green, 0.5);
  }

  .disabledLabel {
    color: $color-surface-lighter;
  }
}

.disabled {
  border-color: $color-surface-lighter;
}

.defaultCursor {
  cursor: default;
}

.disabledLabel {
  color: $color-surface-lighter;
}

// =====================================
// DISPLAY SPECIFIC --------------------

.block {
  margin-bottom: 20px;
}
