@import 'styles/variables';

.button {
  border: 1px solid $color-gray-dark;
  border-radius: 50%;
  height: 30px;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
  padding: 0;
  width: 30px;

  &:hover {
    background: $color-surface-lighter;
  }
}

.glyph {
  height: 12px;
  margin: 0;
  padding: 0;
  width: 12px;
}

.errorTitle {
  color: $color-red;
}
