@import 'styles/variables';

.formBody {
  height: 100%;
}

.formSection {
  text-align: center;
}

.headerText {
  color: $color-gray-darker;
  margin-bottom: 25px;
}

.auctionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 0;
  border: 1px solid $color-gray-lighter;
  padding: 15px 20px;
  font-weight: normal;
  color: $color-gray-darkest;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:first-child {
    border-radius: $block-border-radius $block-border-radius 0 0;
  }

  &:last-child {
    border-radius: 0 0 $block-border-radius $block-border-radius;
  }

  &:hover {
    background: $color-surface-lightest;
  }
}

.radioButtons {
  margin-top: 0;
  margin-bottom: 25px;
  width: 100%;

  label {
    height: 48px;
  }
}
