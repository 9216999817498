@import 'styles/variables';
@import 'styles/typography';

.logEntriesLoading {
  margin: 10px auto 0;
}

.noLogsPlaceholder {
  padding: $spacing-3x;
}

.logGroup {
  padding: 0 $spacing-3x $spacing-3x;
  border-bottom: 1px solid $color-surface-lighter;

  &:last-child {
    border-bottom: none;
  }
}

.title {
  @extend %ellipsis;
  color: $color-gray-darkest;
  font-weight: $font-weight-bold;
  font-size: $font-size-18;
  line-height: 20px;
  margin: 0;
  padding: $spacing-3x 0;
}

.logEntries {
  color: $color-gray-dark;
  font-size: $font-size-14;
}

.logEntry {
  position: relative;
  margin-left: 10px;
  padding-left: $spacing-3x;

  &:not(:last-child) {
    border-left: 1px solid $color-surface-lighter;
    padding-bottom: $spacing-3x;
  }

  & > div:not(.colorBadge) {
    margin-bottom: 5px;
  }
}

.type {
  color: $color-gray-darkest;
  font-weight: $font-weight-bold;

  span {
    color: $color-gray-dark;
    font-weight: $font-weight-regular;

    &::before {
      content: '•';
      padding: 0 5px;
    }
  }
}

.message {
  white-space: pre-wrap;
}

.note {
  color: $color-gray-darkest;
}

.colorBadge {
  position: absolute;
  top: -2px;
  left: -10px;
  width: 20px;
  height: 20px;
  border: 4px solid $color-white;
  border-radius: 50%;

  &.black {
    background-color: $color-black;
  }

  &.blue {
    background-color: $color-blue;
  }

  &.green {
    background-color: $color-green;
  }

  &.red {
    background-color: $color-red;
  }

  &.yellow {
    background-color: $color-yellow;
  }
}
