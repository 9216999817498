@import 'styles/variables';

.notAvailable {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  h3 {
    text-transform: uppercase;
    color: $color-black-60-percent;
    font-size: $font-size-13;
    line-height: $font-size-13;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 20px 0;
    color: $color-gray-light;
    font-size: $font-size-11;
  }
}
