@import 'styles/variables';
@import './statusRows/statusRows';

.upcomingAuction {
  @extend %status;
}

.autobidAmount {
  color: $color-blue;
}
