@import 'styles/variables';

.container {
  width: 330px;
}

.dialogBody {
  min-height: 325px;
}

.stepsProgress {
  pointer-events: none;
  margin-bottom: $spacing-3x;
}

.title {
  font-size: 1rem;
  font-weight: $font-weight-semibold;
  margin-bottom: $spacing-2x;
}

.formErrors {
  margin: $spacing-2x 0 0;
}

.notificationBanner {
  margin: $spacing 0 0;
}

.input {
  margin-top: $spacing-2x;
  height: 43px;
}

.codeInput {
  margin-top: $spacing-2x;
}

.actionButtons {
  margin: $spacing 0 #{-$spacing-3x};
  min-height: 65px; // Assume height of 2 buttons

  // A little crude due to a specificity issue using `LinkButton.className`
  // TODO: Investigate why passing className to `LinkButton` isn't overwriting the default styles.
  button {
    padding: #{$spacing / 2 * 1.5};
    margin: 0 auto;
  }
}

.exitAccountButtons {
  margin-bottom: -$spacing;
  min-height: unset;
}
