@import 'styles/variables';

.confirmOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 45px;
  bottom: 0;
  width: 100%;
  background: $color-white;
  z-index: $zIndex3;

  h4 {
    margin-bottom: 30px !important; // stylelint-disable-line
    font-size: $font-size-14;
  }

  p {
    font-size: $font-size-12;
    margin-bottom: 10px !important; // stylelint-disable-line
  }
}

.buttonContainer {
  display: flex;
  margin-top: 30px;
  gap: $spacing-half-3x;
}
