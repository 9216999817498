@import 'styles/variables';

$icon-size: 14px;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: $spacing;
}

.value {
  font-size: $font-size-32;
  font-weight: $font-weight-bold;
  line-height: 1;

  &.yellow-dark {
    color: $color-yellow-dark;
  }

  &.green-dark {
    color: $color-green-dark;
  }

  &.gray-lighter,
  &.gray-darker {
    color: $color-gray-darker;
  }
}

.chipContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: $spacing;
}

.tooltip {
  height: $icon-size;
  width: $icon-size;
}

.tooltipGlyph {
  color: $color-blue;
  height: 100%;
  margin: 0;
  width: 100%;
}
