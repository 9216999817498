@import 'styles/variables';

.container {
  font-size: 0.75rem;
  padding: 0 $spacing-3x $spacing-3x;

  &:not(:last-child) {
    border-bottom: solid 1px rgba($color-line, 0.3);
  }
}

.header {
  display: flex;
  border-top: solid 1px rgba($color-line, 0.3);
  margin-left: $spacing * -3;
  margin-right: $spacing * -3;
  padding: 18px $spacing-3x 0;
}

.button {
  border: 1px solid $color-black;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;

  svg {
    padding: $spacing-half 0 6px;
    width: 20px;
    height: 20px;
  }
}

.title {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  color: $color-gray-darkest;
  font-weight: $font-weight-bold;
  font-size: $font-size-18;
  line-height: 20px;
}

.content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.details {
  width: 100%;
  display: flex;
  align-items: center;
}

.detailsText {
  line-height: 1.6;
  color: $color-gray-dark;

  .name {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $color-gray-darkest;
  }
}

.map {
  height: 150px;
  width: 250px;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 40px;
}

.mapBtn {
  border-radius: $border-radius-small;
  margin: 7px;
  min-height: 42px;
  min-width: auto;
  color: $color-gray-darkest;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 14px;
  padding-top: 2px;

  > svg {
    color: $color-gray-darkest;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
