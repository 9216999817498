@import 'styles/variables';
@import 'styles/typography';

.container {
  align-items: center;
  background-color: $color-white;
  border-radius: $block-border-radius;
  border: 1px solid $color-gray-lighter;
  display: flex;
  font-size: $font-size-10;
  height: 31px;
  justify-content: space-evenly;
  padding: $spacing;
  text-transform: uppercase;
}

.title {
  font-weight: $font-weight-semibold;
  letter-spacing: 1px;
  white-space: nowrap;
}

.infoBox {
  align-items: center;
  background-color: $color-green;
  border-radius: $block-border-radius;
  color: $color-white;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  height: 18px;
  margin: 0 $spacing-half;
  padding: 0 $spacing;
  white-space: nowrap;
}

.subtitle {
  @extend %ellipsis;
  max-width: 140px;
}
