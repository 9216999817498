@import 'styles/variables';

.listItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: $font-size-12;
  color: $color-gray-dark;
  margin: 15px 0;

  & > div:nth-child(2) {
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    text-align: right;
  }

  &.accentuated {
    color: $color-text;
  }

  &.repaintedPanels {
    & > div:nth-child(2) {
      text-transform: none;
    }
  }

  &.red {
    color: $color-red;
  }
}

.summarySection {
  border-bottom: 1px solid $color-surface-lighter;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
