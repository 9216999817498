@import 'styles/variables';

.labelContainer {
  --badge-size: 24px;
  display: flex;
  align-items: center;
}

.runNumber {
  margin-right: $spacing-half;
}
