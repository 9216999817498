@import 'styles/variables';

.listItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: $font-size-12;
  color: $color-gray-dark;
  margin: 15px 0;

  & > div:nth-child(2) {
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    text-align: right;
    color: $color-text;

    /* stylelint-disable selector-no-qualifying-type */
    &.red {
      color: $color-red;
    }

    &.yellow {
      color: $color-red;
    }

    &.green {
      color: $color-red;
    }
    /* stylelint-enable selector-no-qualifying-type */
  }

  &.accentuated {
    color: $color-text;
  }

  &.repaintedPanels {
    & > div:nth-child(2) {
      text-transform: none;
    }
  }

  &.red {
    color: $color-red;
  }
}

.secondSetsOfTires {
  align-items: center;
  display: flex;
  gap: 10px;

  & > .tireRims {
    text-transform: none;
  }
}

.separator {
  background-color: $color-gray-darkest;
  height: 3px;
  width: 3px;
}

.photoContainer {
  position: relative;
  height: 0;
  padding-bottom: 64%; // height ratio
  margin: 3px 0 15px;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 10px;
  }
}

.photoButton {
  display: block;
}

.photo {
  display: block;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.summarySection {
  border-bottom: 1px solid $color-surface-lighter;
  margin-bottom: 10px;
  padding-bottom: 5px;

  &:last-child {
    border-bottom: none;
  }
}
