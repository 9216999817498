@import 'styles/variables';

.feeButtons {
  display: flex;
  justify-content: space-between;
}

.feeButton {
  align-items: center;
  color: $color-gray-darkest;
  display: flex;
  font-size: $font-size-14;
  justify-content: space-between;
  text-align: left;
  width: 50%;

  &:nth-child(odd) {
    border-right: 1px solid $color-surface-lighter;
    padding-right: 15px;
  }

  &:nth-child(even) {
    padding-left: 15px;
  }
}
