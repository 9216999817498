@import 'styles/variables';

$radius: 23px;
$diameter: 40px;

.buttonContainer {
  display: flex;
  margin: 0 $spacing-half 10px;

  button {
    border-radius: $border-radius-small;
    font-size: $font-size-14;
    text-transform: none;
  }
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.isWinning {
  cursor: not-allowed;

  button {
    pointer-events: none;
  }
}

.customBid {
  margin-left: $spacing-half;
  width: $diameter;
  min-width: $diameter;
  max-width: $diameter;
  padding: 0;
}

.plus {
  position: relative;
  width: 16px;
  height: 16px;
}

@keyframes bulge {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.bulge {
  animation-name: bulge;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  transform-origin: center;
  animation-fill-mode: forwards;
  will-change: transform;
}
