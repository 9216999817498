.digits {
  display: flex;
  max-height: 23px;
  overflow: hidden;
}

.digit {
  text-align: center;
  transition-property: transform;
  transition-duration: 0.3s;
  animation-timing-function: ease;
}

.char {
  width: 13px;
  height: 23px;
}

.emptyChar {
  width: 6px;
  height: 23px;
}
