@import 'styles/variables';

$base-padding: 12px;

.currentOfferAccept {
  color: $color-green;
  font-size: $font-size-32;
  padding-bottom: 17px;
}

.instructions {
  padding-bottom: $base-padding;
}

.selectContainer {
  margin-bottom: $base-padding;
}

.message {
  margin-top: 0;

  &::placeholder {
    color: $color-black-50-percent;
  }
}

.errorMessage {
  color: $color-red;
  padding-top: 2 * $base-padding;
}
