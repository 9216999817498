@import 'styles/variables';

.modalCustom {
  padding: 45px 145px;
  overflow: auto;
}

.subHeader {
  width: 45%;
  line-height: 2;
  margin: 0 auto 20px;
}

.auctionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 0;
  border: 1px solid $color-surface-lighter;
  padding: 15px;
  font-weight: normal;
  color: $color-gray-darkest;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:first-child {
    border-radius: $border-radius $border-radius 0 0;
  }

  &:last-child {
    border-radius: 0 0 $border-radius $border-radius;
  }

  &:only-child {
    border-radius: $border-radius;
  }

  &:hover {
    background: $color-surface-lightest;
  }

  img,
  .defaultVehicle {
    width: 42.5px;
    height: 42.5px;
    border-radius: $border-radius;
    object-fit: cover;
  }

  .defaultVehicle {
    padding: 5px;
    fill: $color-gray-light;
    background: $color-surface-lighter;
  }

  div {
    margin-right: auto;
    margin-left: 25px;

    span {
      width: 100%;
      display: flex;

      &:first-child {
        font-weight: $font-weight-semibold;
        padding-bottom: 9px;
      }
    }
  }

  svg:not(.defaultVehicle) {
    height: 8px;
  }
}

.duplicatesContainer {
  margin-bottom: 30px;
}

.spinnerStyle {
  &::before {
    background: white;
  }
}

.button {
  align-items: center;
  justify-content: center;
  font-size: $font-size-11;
  border-radius: $block-border-radius;
  letter-spacing: $letter-spacing-medium;
  font-weight: $font-weight-semibold;
  height: 35px;
  width: 192px;
  margin: auto;
  display: flex;
}

.marginCustom {
  margin-top: 40px;
}

.errorMessageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 25px;
  padding: 15px 35px;
  background: $color-red-lightest;
  border: 1px solid $color-red;
  color: $color-red;
  border-radius: $border-radius;
  text-align: center;
  font-size: $font-size-12;
  line-height: $line-height;

  & + .vinOverlayForm {
    padding-top: 0;
  }
}

.closeErrors {
  position: absolute;
  top: calc(50% - 15px); // subtract header padding
  right: 5px;
  width: 30px;
  height: 30px;
  font-size: $font-size-20;
  color: $color-red;
}
