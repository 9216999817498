@import 'styles/variables';

.details {
  width: 100%;
  display: flex;
  align-items: center;
}

.detailsButton {
  color: $color-gray-dark;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  justify-content: space-between;
  line-height: 1.6;
  text-align: left;
  width: 100%;

  .chevron {
    align-self: center;
  }
}

.companyName {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  color: $color-gray-darkest;
}
