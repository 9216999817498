@import 'styles/variables';

.button {
  height: 40px;
  width: 100px;

  &.deselected {
    border-color: $color-gray-darker;
  }
}

.disabled {
  cursor: not-allowed;

  > * {
    pointer-events: none;
  }
}
