@import 'styles/variables';
@import 'styles/typography';

.header {
  align-items: center;
  color: $color-gray-dark;
  font-size: $font-size-12;
  display: flex;
  padding: 7px 0;
  margin: 0 15px;
  border-bottom: 1px solid $color-gray-lighter;

  & > *:last-child {
    @extend %ellipsis;
    flex: 1;
    text-align: right;
    padding-left: 15px;
  }

  .labelComp {
    &:not(:first-child)::before {
      content: '•';
      padding: 0 5px;
    }
  }

  .button {
    color: $color-red;
    font-weight: $font-weight-bold;

    &:hover {
      color: $color-red-dark;
    }
  }
}
