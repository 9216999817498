@import 'styles/variables';

.ended {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-white, 0.9);
  padding-top: 100px;
  z-index: $zIndex2;
}

.dialog {
  display: flex;
  flex-direction: column;
  width: 280px;
  background-color: $color-white;
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba($color-black, 0.2);
}

.body {
  padding: $spacing-4x;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gavelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: $color-surface-lightest;
  border-radius: 50%;
}

.gavel {
  width: 70px;
  height: 70px;

  path {
    fill: $color-gray-light;
    stroke: $color-gray-light;
  }
}

.status {
  text-transform: uppercase;
  font-size: $font-size-13;
  font-weight: $font-weight-semibold;
  color: $color-gray-darkest;
  margin-top: 30px;

  .highBid {
    color: $color-gray-dark;

    &.isWinning {
      color: $color-green;
    }

    &.isLosing {
      color: $color-red;
    }
  }
}

.description {
  font-size: $font-size-11;
  color: $color-gray-dark;
  margin-top: 30px;
  text-align: center;
  line-height: 1.5;

  em {
    font-style: normal;
    color: $color-gray-darkest;
  }
}

.button,
.spinnerContainer {
  background-color: $color-surface-lightest;
  height: 50px;
  text-transform: uppercase;
  font-size: $font-size-13;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-small;
  color: $color-gray-darkest;
  border-top: 1px solid $color-surface-lighter;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
