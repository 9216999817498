@import 'styles/variables';

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 0;
  border: 1px solid $color-gray-lighter;
  padding: 15px 20px;
  font-weight: normal;
  color: $color-gray-darkest;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:first-child {
    border-radius: $block-border-radius $block-border-radius 0 0;
  }

  &:last-child {
    border-radius: 0 0 $block-border-radius $block-border-radius;
  }

  &:hover {
    background: $color-surface-lightest;
  }
}

.successContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 105px 0 45px;

  h4 {
    color: $color-green;
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing-big;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
}

.spriteCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  border: 4px solid $color-green;
  border-radius: 50%;
  margin-bottom: 50px;
}

.sprite {
  display: flex;
  align-self: center;
  width: 43px;
  height: 32px;
}
