@import 'styles/variables';

.banner {
  --badge-size: 20px;
  align-items: center;
  background-color: $color-white;
  border-radius: $block-border-radius;
  color: $color-gray-darkest;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  margin-bottom: 10px;
  min-height: 36px;
  padding: 0 12px;
}

.content {
  padding: 0 10px;
}

.secondaryIconLink {
  margin-left: auto;
}

.secondaryIcon {
  height: 20px;
  margin-left: auto;
  width: 20px;
}

.iconWarning {
  color: $color-red;
  height: 20px;
  width: 20px;
}

.iconInfo {
  border-radius: 14px;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
}

.theme-black {
  border: 1px solid $color-black;

  .secondaryIcon {
    color: $color-black;
  }
}

.theme-green {
  border: 1px solid $color-green;

  .secondaryIcon {
    color: $color-green;
  }
}

.theme-green-solid {
  background-color: $color-green-darkest;
  color: $color-white;

  .secondaryIcon {
    color: $color-white;
  }
}

.theme-gold {
  border: 1px solid $color-yellow;

  .secondaryIcon {
    color: $color-yellow;
  }
}

.theme-blue {
  border: 1px solid $color-blue;

  .iconInfo {
    color: $color-blue;
  }

  .secondaryIcon {
    color: $color-blue;
  }
}

.theme-red {
  border: 1px solid $color-red;
}
