@import 'styles/variables';

.notes {
  position: absolute;
  top: 0;
  right: 0;
  width: 43%;
  height: 100%;
  background: $color-black-50-percent;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  min-width: 250px;
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
  z-index: $zIndex1;
}

.header {
  align-self: stretch;
  background: $color-black;
  color: $color-white;
  display: flex;
  font-size: 0.8rem;
  font-weight: $font-weight-semibold;
  justify-content: space-between;
  line-height: 25px;
  padding: 0 $spacing;
}

.closeButton {
  align-items: center;
  display: flex;
  padding: 0 $spacing;

  .sprite {
    fill: $color-white;
    height: 9px;
    width: 9px;
  }
}

.body {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.noteList {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  padding: $spacing;
  list-style: none;
}

.noteListItem {
  width: 100%;
  flex: 0 0 auto;
  align-self: stretch;
}

.noteListItemContent {
  font-size: $font-size-12;
  padding: $input-horizontal-padding;
  background: $color-gray-darkest;
  border-radius: $border-radius;
  color: $color-white;
  line-height: $line-height-medium;
}

.noteListItemAuthor {
  line-height: 1.3rem;
  font-size: $font-size-10;
  text-align: right;
  color: rgba($color-white, 0.5);
  display: block;
}

.emptyNoteList {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $color-white;
  height: 100%;

  h4 {
    font-size: $font-size-13;
    font-weight: normal;
    line-height: 20px;
  }
}

.emptyNoteListInner {
  padding: 0 $spacing-4x;

  .sprite {
    width: 50px;
    height: 50px;
    fill: $color-white;
    margin-bottom: $spacing-2x;
  }
}

.footer {
  flex: 0 0 auto;
  align-self: stretch;
  width: 100%;
  background: rgba($color-black, 0.5);
}

.form {
  padding: $spacing;
}

.selectCompanyContainer {
  font-size: $font-size-11;
  margin-bottom: $spacing;
}

.formRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.formInput {
  border: 1px solid $color-input-border;
  border-radius: $border-radius;
  font-size: $font-size-11;
  background-color: $color-white;
  padding: $input-padding;
  resize: none;
  box-sizing: border-box;
  line-height: 12px;
  flex: 1 0 auto;
  margin-right: $spacing;

  &::placeholder {
    color: $color-black-50-percent;
  }
}

.formSubmit {
  flex: 0 0 auto;
  width: 38px;
  height: 34px;
  background: $color-red;
  border: none;
  border-radius: $border-radius;

  &:not(:disabled):hover {
    background: $color-red-dark;
  }

  svg {
    width: 17px;
    height: 17px;
    fill: $color-white;
    margin-top: 3px;
  }
}
