@import 'styles/variables';
@import './statusRows/statusRows';

.cancelled {
  @extend %status;
}

.cancelledRows {
  position: relative;

  dt,
  dd {
    opacity: 0.3;
  }

  &::after {
    top: calc(50% - 20px);
    left: 0;
    width: 100%;
    height: 45px;
    position: absolute;
    text-align: center;
    content: attr(data-after);
    color: $color-gray-lighter;
    font-size: 45px;
    font-weight: $font-weight-extrabold;
    transform-origin: center;
    transform: rotate(14deg);
  }
}

.buttons:not(:empty) {
  border-color: rgba($color-line, 0.1);
}
