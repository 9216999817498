@import 'styles/variables';
@import './facetGroups';

.saveSearchForm {
  .saveSearchHeading {
    margin-top: 25px;
  }

  .searchName {
    margin: 7px 0;

    &:not([class*='theme-error']) {
      background-color: $color-white;
      border-color: $color-gray-lightest;
    }

    .searchNameText {
      &:placeholder-shown {
        color: $color-gray-light;
        text-overflow: ellipsis;
      }
    }
  }

  .defaultSearch {
    margin: 7px 0;
  }

  .saveSearch {
    border-radius: 5px;
    margin: 7px 0;
    white-space: normal;
    width: 100%;
  }

  .errorMessage {
    font-size: $font-size-11;
    color: $color-red;
    margin: $spacing 0;
  }
}
