@import 'styles/variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: $spacing-2x;

  svg {
    color: $color-blue;
    width: 12px;
    height: 12px;
  }
}

.dialog {
  width: 360px;
}

.errorMessage {
  color: $color-red;
  padding-top: $spacing-3x;
}
