@import 'styles/variables';

.description {
  padding-top: 42px;
}

.segment {
  position: relative;
  padding: 25px;
  border-bottom: 1px solid $color-surface-lighter;

  &:last-child {
    border-bottom: none;
  }
}

.section {
  width: 100%;
  margin: 0;
}

.sectionContainer {
  width: 100%;
}

.section,
.title {
  color: $color-black-50-percent;
}

.overallConditionRatingContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 5px;
}

.sliderContainer {
  width: 185px;
  margin-bottom: 10px;
}

.overallCondition,
.overallConditionText {
  color: $color-gray-darkest;
}

.overallCondition {
  display: flex;
  justify-content: flex-end;
  width: 27px;
  font-weight: $font-weight-semibold;
  margin-right: 5px;
}

.banner {
  margin-bottom: 12px;
}

.carfaxRow {
  align-items: center;
  display: grid;
  grid-template-columns: 185px 90px 215px;
  width: 100%;

  &.bottomGap {
    margin-bottom: 12px;
  }
}

.label {
  color: $color-gray-darkest;
  font-size: $font-size-12;
}

.inputSwitch {
  align-items: center;
  display: flex;
  min-height: $input-height;
}

.viewReportLink {
  align-items: center;
  background-color: $color-green;
  border-radius: $block-border-radius;
  color: $color-white;
  cursor: pointer;
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  justify-content: center;
  min-height: $input-height;
  min-width: 158px;
  text-decoration: none;
  user-select: none;

  &:hover {
    background-color: $color-green-dark;
    color: $color-white;
  }
}

.additionClaimContainer {
  align-items: center;
  background: $color-white;
  border-radius: $block-border-radius;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}

.additionalClaimInput {
  border-color: $color-gray-lighter;
  height: $input-height;
  width: 100%;
}

.message {
  height: 157px;
  border: 1px solid $color-gray-lighter;
  border-radius: $block-border-radius;
}

.tag {
  padding: 3px 5px;
  color: white;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  border-radius: $block-border-radius;
  margin-left: 5px;
}

.green {
  background: $color-green;
}

.orange {
  background: $color-yellow;
}

.red {
  background: $color-red;
}

.spinnerStyle {
  &::before {
    background: $color-white;
  }
}

.loading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 159px;
  color: $color-black-50-percent;

  span {
    padding-left: 15px;
  }
}

.errorMessageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 25px;
  padding: 15px 35px;
  background: $color-red-lightest;
  border: 1px solid $color-red;
  color: $color-red;
  border-radius: $border-radius;
  text-align: center;
  font-size: $font-size-12;
  line-height: $line-height;
}

.closeErrors {
  height: 30px;
  position: absolute;
  right: $spacing;
  top: calc(50% - 16px);
  width: 30px;

  .sprite {
    fill: $color-red;
    height: 8px;
    width: 8px;
  }
}

.redText {
  color: $color-red;
}

.highlighted {
  background: $color-blue-lighter;
}
