@import 'styles/variables';

.modalCustom {
  padding: 0 30px;
  height: calc(100vh - #{$full-dialog-header-height} - #{$full-dialog-large-footer-height});
  overflow: auto;
}

.instruction {
  padding-bottom: 20px;
}

.inputContainer {
  padding: 0 35px;
  margin-top: 15px;
  position: relative;

  label {
    display: flex;
    justify-content: left;
    font-size: $font-size-12;
    padding: 15px 0 10px;
  }
}

.switchContainer {
  align-items: center;
  display: flex;
  margin: 5px 0 -5px;
}

.inputSwitch {
  display: inline-block;
  margin-left: $spacing;
  margin-top: $spacing-quarter;

  // stylelint-disable-next-line selector-max-compound-selectors
  input[type='checkbox']:checked + label svg {
    fill: $color-green;
  }

  label {
    padding: 0;
  }
}

.vinOverlayForm {
  padding: 45px 115px;

  p {
    margin-bottom: 0;
  }

  .label,
  .instruction {
    color: $color-black-50-percent;
  }
}

.marginCustom {
  margin-top: 40px;
}

.line {
  display: inline-block;
  width: 140px;
  height: 1px;
  background: $color-gray-lightest;
  margin-top: 20px;
}

.companies {
  width: 100%;

  > div {
    text-align: left;
    width: 100%;
  }
}

.radioButtons {
  margin-top: 0;
}

.input {
  font-size: $font-size-14;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  border-top: 1px solid $color-gray-lighter;
  bottom: 0;
  width: 100%;
  background: $color-white;
  height: $full-dialog-large-footer-height;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-11;
  border-radius: $block-border-radius;
  letter-spacing: $letter-spacing-medium;
  font-weight: $font-weight-semibold;
  height: 35px;
  min-width: 105px;
}

.backButton {
  display: flex;
  align-items: center;
  min-width: 0;
  padding: 0 15px;
  background: $color-gray-lighter;

  &:hover {
    background: $color-gray-light;
  }
}

.arrowSprite {
  width: 12px;
  height: 12px;
  fill: white;
  margin-right: 15px;
}

.errorMessageContainer {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0;
  padding: 15px;
  background: $color-red-lightest;
  border: 1px solid $color-red;
  color: $color-red;
  border-radius: $border-radius;
  text-align: center;
  font-size: $font-size-12;
  line-height: $line-height;

  & + .vinOverlayForm {
    padding-top: 0;
  }
}

.spinnerStyle {
  margin: 0 auto;

  &::before {
    background: $color-white;
  }
}

.link {
  padding: 10px 0;
  margin-right: 50px;
  color: $color-gray-darkest;
  font-size: $font-size-11;
  font-weight: $font-weight-regular;
  text-decoration: underline;
  text-decoration-skip-ink: auto;

  &:hover {
    color: $color-gray-dark;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
}
