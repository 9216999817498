@import 'styles/variables';

$base-padding: 12px;

.currentOfferCounter {
  color: $color-blue;
  font-size: $font-size-32;
  padding: $base-padding 0;
}

.instructions {
  padding-bottom: $base-padding;
}

.consigners {
  height: $input-height;
  font-size: $font-size-11;
}

.errorMessage {
  color: $color-red;
}
