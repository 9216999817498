@import 'styles/variables';

.section {
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: $spacing;
  padding: $spacing-2x;
  width: 100%;
}

.title {
  font-size: $font-size-14;
  margin-bottom: $spacing-2x;
}

.infoMessage {
  color: $color-gray-dark;
  display: flex;
  gap: $spacing;
  margin-bottom: $spacing-2x;

  .iconInfo {
    border-radius: 14px;
    height: 14px;
    width: 14px;
  }
}

.buttonContainer {
  display: flex;
  gap: $spacing;
  margin-bottom: $spacing-2x;
}

.overriddenBanner {
  align-items: center;
  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-gray-dark;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
  height: 32px;
  justify-content: space-between;
  margin-bottom: $spacing-2x;
  padding: $spacing $spacing-2x;
  width: 100%;
}
