@import 'styles/variables';

.authBanner {
  background-color: $color-white;
  border-radius: $border-radius;
  border: 1.5px solid $color-gray-darkest;
  color: $color-gray-darkest;
  font-size: $font-size-14;
  line-height: $line-height;
  margin-bottom: $spacing-3x;
  padding: $spacing-2x;
  text-align: left;
  white-space: pre-wrap;
}

.interactiveLink {
  color: $color-red;

  &:active,
  &:hover {
    color: $color-red;
  }
}
