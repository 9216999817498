@import '../../../../../styles/variables';

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > *:not(:last-child) {
    margin: 0 7px 0 0;
  }
}

.button {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-gray-lighter;
  border-radius: $border-radius;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 0;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
}

.notesButton {
  position: relative;

  .hasNotes {
    background-color: $color-blue;
    border-radius: 50%;
    height: 12px;
    position: absolute;
    right: -3px;
    top: -3px;
    width: 12px;
  }

  svg {
    fill: $color-black;
    margin-left: 3px;
    width: 18px;
    height: 18px;
  }

  &:hover {
    background: $color-surface-lighter;
  }
}

.holdbackButton {
  svg {
    fill: $color-black;
    width: 12px;
    height: 12px;
  }

  &:hover {
    background: $color-surface-lighter;
  }
}

.printButton {
  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: $color-surface-lighter;
  }
}

.shareButton {
  svg {
    width: 16px;
    height: 16px;
  }
}

.watchButton {
  background-color: $color-gray-light;
  border-color: $color-gray-light;

  svg {
    fill: $color-black;
    margin-right: 0;
    padding: 0;
  }
}

.archiveButton {
  background-color: $color-red;
  border-color: $color-red;

  svg {
    fill: $color-white;
    margin-right: 0;
    padding: 0;
  }
}
