@import 'styles/variables';

.footer {
  position: relative;
}

.timerBarContainer {
  height: 18px;
  width: 100%;
  background-color: $color-green-dark;
  overflow: hidden;
}

.timerBar {
  background-color: $color-green;
}

.timerText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-white;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: 18px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  > div {
    display: flex;
    max-width: calc(100% - 18px);

    > * {
      &:last-child {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:not(:first-child)::before {
        content: '•';
        padding: 0 5px;
      }
    }
  }
}

.clockGlyph {
  fill: $color-white;
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin-right: $spacing;
}

.red {
  .timerBarContainer {
    background-color: $color-red-dark;
  }

  .timerBar {
    background-color: $color-red;
  }
}

.yellow {
  .timerBarContainer {
    background-color: $color-yellow-darkest;
  }

  .timerBar {
    background-color: $color-yellow;
  }
}

.blue {
  .timerBarContainer {
    background-color: $color-blue;
  }

  .timerBar {
    background-color: $color-blue;
  }
}
