@import './variables';

$font-proxima-nova: $font-family;

/*
 * Common font styles throughout the application. Extend or inherit accordingly.
 * Extend them in your scss files like so: @extend .type-h1;
 */

.type {
  &-body-1 {
    color: $color-text;
    font-size: $font-size-12;
    line-height: 2.1; // 25px
  }

  &-body-2 {
    line-height: $line-height;
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
  }

  &-body-3 {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    color: $color-gray-light;
  }

  &-body-4 {
    font-size: $font-size-12;
    line-height: 2.4; // 29px
    font-weight: $font-weight-regular;
    color: $color-gray-dark;
  }

  &-section-title {
    color: $color-text;
    font-weight: $font-weight-bold;
    font-size: $font-size-18;
    line-height: 1.5; // 24px
  }

  &-notes {
    font-size: $font-size-12;
    color: $color-gray-darkest;
    line-height: $line-height-large;
    font-weight: $font-weight-regular;
  }

  &-item-title {
    font-size: $font-size-14;
    color: $color-black;
  }

  &-item-subtitle {
    line-height: $line-height-medium;
    font-size: $font-size-12;
    color: $color-gray-light;
  }

  &-subtitle {
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    color: $color-gray-light;
  }

  &-title {
    color: $color-black-50-percent;
    font-weight: $font-weight-regular;
  }
}

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
