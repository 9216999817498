@import 'styles/variables';

.dialogHeader {
  align-items: center;
  background: $color-gray-darkest;
  display: flex;
  height: var(--system-navigation-height-single);
  justify-content: space-between;
  padding: 0 25px;
}

.closeButton {
  align-items: center;
  display: flex;
  margin-right: -$spacing;
  padding: $spacing;
}

.closeIcon {
  fill: $color-white;
  height: 13px;
  width: 13px;
}

.headerTitle {
  color: $color-white;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-medium;
  line-height: initial;
  text-transform: uppercase;
}
