@import 'styles/typography';
@import 'styles/variables';

.spinner {
  margin-top: $spacing-3x;
}

.container {
  top: 0;

  // Match specificity
  .contentClassName {
    width: 470px; // Required to fit all distance options on one line.
  }
}

.containerContentInner {
  display: unset; // Required for sticky anchor tabs
}

.anchorTabs {
  position: sticky;
  top: 0;
  z-index: $zIndex1;
}

.tabs {
  padding: 0 $spacing-3x;
}

.tab {
  margin-left: 0;
  margin-right: $spacing-3x;

  button {
    padding: 0;
  }
}

.section {
  padding: $spacing-2x;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($color-line, 0.3);
  }
}

.sectionTitle {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
}

.sectionSubtitle {
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  margin-top: $spacing;
}

.makeModelFilterPlaceholder {
  background: $color-gray-lightest;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  margin-top: $spacing;
  padding: $spacing-3x $spacing;
}

.makeModelFilters {
  margin-top: $spacing;
}

.makeModelFilter {
  background: $color-surface-lightest;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  position: relative;

  &:not(:last-child) {
    margin-bottom: $spacing-3x;
  }

  > div {
    border-bottom: 1px solid $color-gray-lightest;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 $spacing;
    width: 90%;
  }

  > div > div {
    // Ellipsis require block-level element
    @extend %ellipsis;
  }
}

.makeModelFilterYear,
.makeModelFilterMake {
  font-weight: $font-weight-semibold;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  grid-gap: $spacing;
  margin-top: $spacing-2x;

  button {
    font-size: $font-size-12;
    min-height: 32px;
    min-width: auto;
  }
}

.addButton {
  width: 100%;
}

.removeButton {
  position: absolute;
  right: $spacing-half-3x;
  bottom: $spacing-half;

  svg {
    fill: $color-blue;
    width: 17px;
    height: 17px;

    &:hover {
      fill: $color-blue-dark;
    }
  }
}
