@import 'styles/variables';

.content {
  padding: 0 $spacing-3x $spacing-3x;
}

.thumbnailsContainer {
  margin-bottom: $spacing;
}

.thumbnailList {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  &.justifyThumbnails {
    justify-content: space-between;
  }
}

.thumbnailListItem {
  width: 60px;
  height: 60px;
  padding-top: calc(10% - 15px);
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: $spacing;
  }

  margin-bottom: $spacing;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-surface-lighter;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 3px solid $color-red;
}

.section {
  background: $color-surface-lighter;
  font-size: $font-size-10;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-big;
  line-height: $line-height-large;
  margin-bottom: $spacing;
  margin-left: -$spacing-3x;
  margin-right: -$spacing-3x;
  padding-bottom: $spacing-half;
  padding-top: $spacing-half;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: $spacing-2x;
  }
}

.sectionName {
  margin-left: $spacing-3x;
  margin-right: $spacing-3x;
}

.damagesContent {
  padding-top: $spacing;
  padding-bottom: $spacing;
}

.damagesContainer {
  &:not(:last-child) {
    border-bottom: 1px solid $color-surface-lighter;
  }
}

.description {
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-big;
  line-height: $line-height-large;
  text-transform: uppercase;
}

.textContent {
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  letter-spacing: 0;
  line-height: $line-height-large;
}
