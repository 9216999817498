@import 'styles/variables';
@import 'styles/typography';

.editFacetGroup {
  padding: 6px 12px 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    color: $color-gray-light;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  .facets {
    flex: 1;
    margin-top: 3px;
    margin-bottom: 15px;
    overflow-y: auto;
  }

  .facet {
    width: 100%;
    height: 40px;
    margin: 5px 0;
    padding: 0 15px;
    background-color: $color-white;
    border: 1px solid #efefef;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      color: $color-black;
      font-size: 12px;
      flex: 1;
      text-transform: capitalize;
      padding-right: 5px;
      @extend %ellipsis;
    }

    .count {
      font-size: $font-size-9;
      min-width: 18px;
      height: 18px;
      padding: $spacing-half;
      border-radius: 5px;
      color: $color-white;
      text-align: center;
      font-weight: $font-weight-semibold;
      background-color: $color-blue;
    }

    &.selected {
      background-color: $color-blue;
      border-color: $color-blue;

      .name {
        color: $color-white;
      }

      .count {
        background-color: $color-white;
        color: $color-blue;
      }
    }
  }

  .done {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 14px;
  }
}
