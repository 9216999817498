@import 'styles/variables';
@import './statusRows/statusRows';

.ifBid {
  @extend %status;
}

.autobidAmount {
  color: $color-blue;
}

.waitingForMe {
  color: $color-red;
  font-style: normal;

  > p {
    line-height: $line-height-medium;
  }
}

.waitingForThem {
  color: $color-green;
  font-style: normal;

  > p {
    line-height: $line-height-medium;
  }
}
