@import 'styles/variables';

$base-padding: 12px;

.instructions {
  padding: 0 0 $base-padding;
  line-height: 1.4;
}

.amount {
  margin: 0 0 10px;
  font-size: $font-size-11;
  border-color: $color-gray-lighter;
}

.selectInput {
  height: $input-height;
  font-size: $font-size-11;
  margin-bottom: 10px;
}

.inputGroup {
  padding: 0;

  .amount {
    font-size: $font-size-11;
    margin-bottom: 0;
  }
}

.errorMessage {
  color: $color-red;
  padding-top: 2 * $base-padding;
}
