@import 'styles/variables';

.button {
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-small;
  display: flex;
  font-size: $font-size-14;
  flex-shrink: 0;
  margin: 0 $spacing-half 10px;
  min-height: 40px;
  min-width: 77px;
  padding: 1px $spacing-2x 0;
  text-transform: none;
}

.badge {
  height: 17px;
  margin-right: $spacing;
  flex-shrink: 0;
  font-size: $font-size-9;
}

.glyph {
  height: 17px;
  width: 17px;
  margin-right: 12px;
  flex-shrink: 0;
}

.operationsContainer {
  display: flex;
  flex-flow: row wrap;
}
