@import 'styles/variables';

$modal-width: 910px;

.overlayClass {
  z-index: $zIndexDialog !important; // stylelint-disable-line
}

.loadingContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 45px;
  bottom: 0;
  width: 100%;
  background: $color-white;
  z-index: $zIndex1;
}

.headerText {
  color: $color-white;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-spacing-medium;
  text-transform: uppercase;
  line-height: initial;
}

.confirmTitleClassName {
  color: $color-red;
}
